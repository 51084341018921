package xim.poc.game

import xim.math.Vector3f
import xim.poc.Actor
import xim.poc.ActorManager
import xim.poc.audio.AudioManager
import xim.poc.audio.SystemSound
import xim.poc.tools.ZoneNpcTool

fun interface TargetFilter {
    fun isValidTarget(source: Actor, target: Actor): Boolean
}

object PlayerTargetSelector {

    private val targetStack = ArrayList<Actor>()
    private val subTargetStack = ArrayList<Actor>()
    private val tabTargetFilter = TargetFilter { a, b -> tabTargetFilter(a, b) }

    fun updateTarget() {
        val playerActor = ActorManager.player()
        val targetId = playerActor.target ?: return

        val targetActor = ActorManager[targetId]
        val visible = ActorManager.getVisibleActors().contains(targetActor)

        if (visible && targetActor != null && steadyStateFilter(playerActor, targetActor)) {
            return
        }

        if (!playerActor.isFullyOutOfCombat()) {
            BattleEngine.onPlayerDisengage()
        } else {
            playerActor.target = null
            targetStack.clear()
        }
    }

    fun clearTarget() {
        val playerActor = ActorManager.player()
        if (playerActor.targetLocked) { return }

        playerActor.target = null
        targetStack.clear()
    }

    fun targetPartyMember(index: Int) {
        val playerActor = ActorManager.player()
        val party = PartyManager[playerActor]
        val target = party.getByIndex(index) ?: return

        val subTargetMode = UiStateHelper.isSubTargetMode()
        if (playerActor.targetLocked && !subTargetMode) { return }

        val pendingFilter = UiStateHelper.getPendingActionTargetFilter()
        if (pendingFilter != null && !pendingFilter.isValidTarget(playerActor, target)) { return }

        if (subTargetMode) {
            playerActor.subTarget = target.id
            subTargetStack.clear()
        } else {
            playerActor.target = target.id
            targetStack.clear()
        }

        AudioManager.playSystemSoundEffect(SystemSound.TargetCycle)
    }

    fun targetCycle(playSound: Boolean = true, subTarget: Boolean = false, targetFilter: TargetFilter = tabTargetFilter) {
        val playerActor = ActorManager.player()
        if (playerActor.targetLocked && !subTarget) { return }

        val stack = if (subTarget) { subTargetStack } else { targetStack }
        var nextTarget: Actor

        while (true) {
            if (stack.isEmpty()) {
                val allActors = getTargetableActors(targetFilter)
                if (allActors.isEmpty()) { return }
                stack.addAll(allActors)
            }

            nextTarget = stack.removeFirst()
            if (targetFilter.isValidTarget(playerActor, nextTarget)) { break }
        }

        if (subTarget) {
            playerActor.subTarget = nextTarget.id
        } else {
            playerActor.target = nextTarget.id
            ZoneNpcTool.printTargetInfo()
        }

        if (playSound) { AudioManager.playSystemSoundEffect(SystemSound.TargetCycle) }
    }

    fun onStartSelectingSubTarget(targetFilter: TargetFilter): Actor? {
        val player = ActorManager.player()

        val allTargets = getTargetableActors(targetFilter)
        if (allTargets.isEmpty()) { return null }

        val target = ActorManager[player.target]

        val shouldPreferPlayer = target != null && target.enemy && targetFilter.isValidTarget(player, player)
        val preferredTarget = if (shouldPreferPlayer) { player } else { target }

        if (preferredTarget != null && targetFilter.isValidTarget(player, preferredTarget)) {
            val headList = allTargets.takeWhile { it.id != preferredTarget.id }
            allTargets.removeAll(headList)
        }

        player.subTarget = allTargets.removeFirst().id
        subTargetStack.addAll(allTargets)

        return ActorManager[player.subTarget]
    }

    fun updateSubTarget(targetFilter: TargetFilter): Boolean {
        val player = ActorManager.player()

        val currentTarget = ActorManager[player.subTarget]
        if (currentTarget == null || !targetFilter.isValidTarget(player, currentTarget)) {
            player.subTarget = null
            return false
        }

        return true
    }

    fun onFinishedSelectingSubTarget() {
        subTargetStack.clear()
    }

    private fun getTargetableActors(targetFilter: TargetFilter) : MutableList<Actor> {
        val player = ActorManager.player()
        val playerPosition = player.position
        val party = PartyManager[player]

        return ActorManager.getVisibleActors().asSequence()
            .filter { targetFilter.isValidTarget(player, it) }
            .filter { !ZoneNpcTool.isForceHidden(it.id) }
            .sortedBy { Vector3f.distance(playerPosition, it.position) }
            .sortedBy { if (party.contains(it) || it.owner == player.id) { 1 } else { 0 } }
            .toMutableList()
    }

    private fun tabTargetFilter(source: Actor, target: Actor): Boolean  {
        if (source.id == target.id) {
            return false
        }

        return steadyStateFilter(source, target)
    }

    private fun steadyStateFilter(source: Actor, target: Actor): Boolean {
        if (target.isMount) {
            return false
        }

        if (target.isDead() && (target.enemy || target.isDependent())) {
            return false
        }

        if (Vector3f.distance(source.position, target.position) > 50f) {
            return false
        }

        return true
    }

    fun tryDirectlyTarget(actor: Actor?): Boolean {
        if (UiStateHelper.hasActiveUi()) { return false }

        val playerActor = ActorManager.player()
        if (playerActor.targetLocked) {
            return false
        }

        if (actor == null) {
            if (playerActor.target != null) {
                playerActor.target = null
                return true
            }
            return false
        }

        if (playerActor.target == actor.id) {
            UiStateHelper.handleTargetConfirm()
        } else {
            playerActor.target = actor.id
            targetStack.clear()
        }

        AudioManager.playSystemSoundEffect(SystemSound.TargetCycle)
        return true
    }

}