package xim.poc.gl

import kotlinx.browser.document
import org.w3c.dom.HTMLElement
import web.gl.GLenum
import web.gl.GLint
import web.gl.WebGL2RenderingContext
import web.gl.WebGLQuery

object GpuTimer {

    private const val extensionName = "EXT_disjoint_timer_query_webgl2"
    private val extensionValue = 0x88BF as GLenum

    private val webgl by lazy { GlDisplay.getContext() }
    private val valid by lazy { webgl.getExtension(extensionName) != null }
    private val element by lazy { document.getElementById("gpuElapsed") as HTMLElement }

    private var query: WebGLQuery? = null

    private var samples = 0
    private var cumulativeResult = 0

    fun start() {
        if (!valid) { return }
        if (query != null) { return }

        query = webgl.createQuery()!!
        webgl.beginQuery(extensionValue, query!!)
    }

    fun end() {
        if (!valid) { return }
        query ?: return
        webgl.endQuery(extensionValue)
    }

    // Must only be called after requestAnimationFrame
    fun report() {
        if (!valid) { return }
        val currentQuery = query ?: return

        val available = webgl.getQueryParameter(currentQuery, WebGL2RenderingContext.QUERY_RESULT_AVAILABLE) as Boolean?
        if (available != true) { return }

        val result = webgl.getQueryParameter(currentQuery, WebGL2RenderingContext.QUERY_RESULT) as GLint
        webgl.deleteQuery(query)
        query = null

        samples += 1
        cumulativeResult += result

        if (samples < 60) { return }

        val averageResult = cumulativeResult / 60.0
        element.innerText = (averageResult / 1000000.0).toString().substring(0..4)

        samples = 0
        cumulativeResult = 0
    }

}