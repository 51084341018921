package xim.util

class Stack<T> {

    private val elements = mutableListOf<T>()

    fun push(t: T) {
        elements.add(t)
    }

    fun pop(): T {
        return elements.removeLastOrNull() ?: throw IllegalStateException("Popping on empty")
    }

    fun peek(): T? {
        return elements.lastOrNull()
    }

    fun isEmpty(): Boolean {
        return elements.isEmpty()
    }

    fun clear() {
        elements.clear()
    }

}