package xim.poc.tools

import xim.math.Matrix4f
import xim.math.Vector3f
import xim.poc.gl.*
import xim.resource.MeshType
import kotlin.math.PI

object SphereDrawingTool {

    private class DrawSphereCommand(val center: Vector3f, val radius: Float, val color: ByteColor)

    private val commands = ArrayList<DrawSphereCommand>()

    fun drawSphere(center: Vector3f, radius: Float, color: ByteColor) {
        commands += DrawSphereCommand(center, radius, color)
    }

    fun render(drawer: Drawer) {
        commands.forEach { render(drawer, it) }
        commands.clear()
    }

    private fun render(drawer: Drawer, command: DrawSphereCommand) {
        val rotationY = ArrayList<Matrix4f>()
        val spokes = 16

        val thetaStep = (2 * PI / spokes).toFloat()
        for (i in 0 until spokes) {
            val theta = thetaStep * i
            rotationY.add(Matrix4f().rotateYInPlace(theta))
        }

        val layers = ArrayList<ArrayList<Vector3f>>()
        val elevations = listOf(-1f, -0.8f, -0.6f, -0.4f, -0.2f, 0f, 0.2f, 0.4f, 0.6f, 0.8f, 1f)

        for (i in elevations.indices) {
            val layer = ArrayList<Vector3f>(spokes)
            layers.add(layer)

            // 0 -> 0; 1 -> 0.5 PI
            val phi = -0.5f * PI.toFloat() * elevations[i]
            val rotationZ = Matrix4f().rotateZInPlace(phi)

            for (j in 0 until spokes) {
                val position = Vector3f(command.radius, 0f, 0f)
                rotationZ.transformInPlace(position)
                rotationY[j].transformInPlace(position)
                layer.add(position + command.center)
            }
        }

        val meshes = ArrayList<MeshBuffer>()
        for (i in 0 until elevations.size - 1) {
            val bufferBuilder = GlBufferBuilder((spokes + 1) * 2)

            for (j in 0 until spokes) {
                bufferBuilder.appendColoredPosition(layers[i][j], command.color)
                bufferBuilder.appendColoredPosition(layers[i + 1][j], command.color)
            }

            bufferBuilder.appendColoredPosition(layers[i][0], command.color)
            bufferBuilder.appendColoredPosition(layers[i + 1][0], command.color)

            val buffer = bufferBuilder.build()
            meshes += MeshBuffer(
                numVertices = (spokes + 1) * 2,
                meshType = MeshType.TriStrip,
                renderState = RenderState(blendEnabled = true),
                glBuffer = buffer,
            )
        }

        drawer.drawXim(DrawXimCommand(meshes = meshes))
        meshes.forEach { it.release() }
    }

}