package xim.util

import kotlin.math.PI
import kotlin.math.floor
import kotlin.math.pow
import kotlin.math.roundToInt
import kotlin.random.Random

const val PI_f = PI.toFloat()

object Utils {

    fun sometimes(probability: Double, runnable: () -> Unit) {
        if (Random.nextDouble(100.0) > 100 * probability) {
            runnable()
        }
    }

}

fun Int.interpolate (other: Int, t: Float): Int {
    return ((1.0f - t) * this + other * t).toInt()
}

fun Float.interpolate (other: Float, t: Float): Float {
    return (1.0f - t) * this + other * t
}

fun Float.fallOff(near: Float, far: Float): Float {
    return if (this < near) {
        1f
    } else if (this < far) {
        1f - (this - near) / (far - near)
    } else {
        0f
    }
}

fun Float.toRads(): Float {
    return this * PI_f / 180f
}

fun Float.toDegrees(): Float {
    return this * 180f / PI_f
}

fun Float.toTruncatedString(decimals: Int): String {
    val wholePart = floor(this).roundToInt().toString()
    val decimalPart = floor(this.mod(1f) * 10f.pow(decimals)).roundToInt().toString()
    return "$wholePart.$decimalPart"
}
