package xim.poc.audio

import xim.poc.FadeParameters
import xim.poc.browser.SoundPlayer
import xim.util.Fps.secondsToFrames


object BgmManager {

    private val previousPosition = HashMap<Int, Double>()
    private val fading = ArrayList<SoundPlayer>()

    private var current: SoundPlayer? = null

    fun update(elapsedFrames: Float) {
        current?.update(elapsedFrames)

        fading.forEach {
            previousPosition[it.id] = it.currentPosition()
            it.update(elapsedFrames)
        }

        fading.removeAll { it.isEnded() }
    }

    fun playBgm(musicId: Int?, volume: Float, resume: Boolean) {
        val playing = current

        if (playing != null && playing.id == musicId) {
            return
        }

        if (playing != null) {
            playing.applyFade(FadeParameters.fadeOut(secondsToFrames(1f)))
            fading += playing
        }

        current = null
        if (musicId == null) { return }

        val musicIdStr = musicId.toString().padStart(3, '0')
        val resourceName = "sound/win/music/data/music${musicIdStr}.ogg"
        val startingPosition = if (resume) { previousPosition[musicId] ?: 0.0 } else { 0.0 }

        current = SoundPlayer(resourceName = resourceName, volume = volume, id = musicId, loop = true).also { it.play(startingPosition) }
        current?.applyFade(FadeParameters.fadeIn(secondsToFrames(1f)))
    }

    fun adjustVolume(volume: Float) {
        current?.adjustVolume(volume)
    }

}