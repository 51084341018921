package xim.poc.tools

import kotlinx.browser.document
import org.w3c.dom.HTMLOptionElement
import org.w3c.dom.HTMLSelectElement
import xim.poc.*
import xim.poc.browser.LocalStorage

object PlayerCustomizer {

    private var customizeSetup = false

    fun setup() {
        if (customizeSetup) { return }
        customizeSetup = true

        val localConfiguration = LocalStorage.getConfiguration()

        val raceGenderSelect = (document.getElementById("RaceGenderSelect") as HTMLSelectElement)
        for (config in RaceGenderConfig.values()) {
            val option = document.createElement("option") as HTMLOptionElement
            option.text = config.name
            option.value = config.index.toString()
            raceGenderSelect.add(option)
        }
        raceGenderSelect.value = localConfiguration.playerRace.index.toString()

        val faceSelect = (document.getElementById("FaceSelect") as HTMLSelectElement)
        for (i in 0 until 0x20) {
            val option = document.createElement("option") as HTMLOptionElement
            option.text = i.toString()
            option.value = i.toString()
            faceSelect.add(option)
        }
        faceSelect.value = localConfiguration.playerFace.toString()

        raceGenderSelect.onchange = { updatePlayerActor(raceGenderSelect, faceSelect) }
        faceSelect.onchange = { updatePlayerActor(raceGenderSelect, faceSelect) }
    }

    private fun updatePlayerActor(raceGenderSelect: HTMLSelectElement, faceSelect: HTMLSelectElement) {
        val config = RaceGenderConfig.values().first { it.index == raceGenderSelect.value.toInt() }
        val face = faceSelect.value.toInt()

        LocalStorage.changeConfiguration {
            it.playerRace = config
            it.playerFace = face
        }

        val playerActor = ActorManager.player()

        playerActor.actorModel = ActorModel(PcModel(raceGenderConfig = config, actor = playerActor, modelLook = ModelLook(faceIndex = face)))
        playerActor.refreshModelEquipment()
    }

}