package xim.resource.table

import xim.resource.SpellInfo

object TrustTable {

    fun getModelFileTableIndex(spellInfo: SpellInfo): Int? {
        val offsetId = spellInfo.index - 896

        return when {
            offsetId <= 51 -> 0x18643 + offsetId
            offsetId == 52 -> 0x18678
            offsetId == 53 -> 0x18679
            offsetId == 54 -> 0x1867a
            offsetId == 55 -> 0x1867b
            offsetId == 56 -> 0x1867c
            offsetId == 57 -> 0x1867d
            offsetId == 58 -> 0x1867e
            offsetId == 59 -> 0x18680
            offsetId == 60 -> 0x18681
            offsetId == 61 -> 0x1867e
            offsetId == 62 -> 0x18686
            offsetId == 63 -> 0x18687
            offsetId == 64 -> 0x18688
            offsetId == 65 -> 0x18689
            offsetId == 66 -> 0x1868a
            offsetId == 67 -> 0x1868b
            offsetId == 68 -> 0x1868c
            offsetId == 69 -> 0x1868d
            offsetId == 70 -> 0x1868e
            offsetId == 71 -> 0x1868f
            offsetId == 72 -> 0x18690
            offsetId == 73 -> 0x18691
            offsetId == 74 -> 0x18692
            offsetId == 75 -> 0x18693
            offsetId == 76 -> 0x1869a
            offsetId == 77 -> 0x1869b
            offsetId == 78 -> 0x1869c
            offsetId == 79 -> 0x1869d
            offsetId == 80 -> 0x1869e
            offsetId == 81 -> 0x1869f
            offsetId == 82 -> 0x186a0
            offsetId == 83 -> 0x186a1
            offsetId == 84 -> 0x186a2
            offsetId == 85 -> 0x186a3
            offsetId == 86 -> 0x186a5
            offsetId == 87 -> 0x186a6
            offsetId == 88 -> 0x186a7
            offsetId == 89 -> 0x186a8
            offsetId == 90 -> 0x186aa
            offsetId == 91 -> 0x186ac
            offsetId == 92 -> 0x186ad
            offsetId == 93 -> 0x186ae
            offsetId == 94 -> 0x186af
            offsetId == 95 -> 0x186b0
            offsetId == 96 -> 0x186b4
            offsetId == 97 -> 0x186b5
            offsetId == 98 -> 0x186b6
            offsetId == 99 -> 0x186b7
            offsetId == 100 -> 0x186b8
            offsetId == 101 -> 0x186b2
            offsetId == 102 -> 0x186b9
            offsetId == 103 -> 0x186bb
            offsetId == 106 -> 0x186ba
            offsetId == 107 -> 0x186bc
            offsetId == 108 -> 0x18677
            offsetId == 109 -> 0x18682
            offsetId == 110 -> 0x18683
            offsetId == 111 -> 0x18684
            offsetId == 112 -> 0x18685
            offsetId == 113 -> 0x18694
            offsetId == 114 -> 0x18699
            offsetId == 115 -> 0x18695
            offsetId == 116 -> 0x18696
            offsetId == 117 -> 0x18697
            offsetId == 118 -> 0x186a4
            offsetId == 119 -> 0x186ab
            offsetId == 120 -> 0x186a9
            offsetId == 121 -> 0x18698
            offsetId == 122 -> 0x186b3
            offsetId == 123 -> 0x186b1
            else -> null
        }
    }
}