package xim.poc

import xim.math.Vector2f
import xim.math.Vector3f
import xim.poc.audio.AudioManager
import xim.poc.browser.DatLoader
import xim.poc.browser.Keyboard
import xim.poc.browser.LocalStorage
import xim.poc.browser.PlatformDependencies
import xim.poc.camera.CameraReference
import xim.poc.camera.PolarCamera
import xim.poc.game.*
import xim.poc.gl.Drawer
import xim.poc.gl.FrameBufferManager
import xim.poc.tools.*
import xim.poc.ui.*
import xim.resource.DatId
import xim.resource.DirectoryResource
import xim.resource.InventoryItems
import xim.resource.TextureLink
import xim.resource.table.*
import xim.util.Fps.millisToFrames
import xim.util.OncePerFrame
import xim.util.Timer
import kotlin.math.min

object GlobalDirectory {
    lateinit var directoryResource: DirectoryResource
    lateinit var shadowResource: DirectoryResource
    val shadowTexture by lazy { TextureLink("system  kage_tex", shadowResource) }
}

object MainTool {

    var zoneConfig = CustomZoneConfig.Sarutabartua_East.config

    var setupZone = false
    var welcomed = false

    lateinit var platformDependencies: PlatformDependencies
    lateinit var drawer: Drawer

    private lateinit var playerActor: Actor
    private lateinit var zoneInstance: ZoneInstance

    fun run(platformDependencies: PlatformDependencies) {
        this.platformDependencies = platformDependencies
        playerActor = Actor(ActorId(0), name = "Player", position = Vector3f(), actorController = KeyboardActorController(platformDependencies.keyboard))

        val startingZoneOverride = StartupTool.getStartingZoneId()
        if (startingZoneOverride != null) {
            zoneConfig = ZoneConfig(zoneId = startingZoneOverride, startPosition = StartupTool.getStartingPositionOverride())
        }

        CameraReference.setInstance(PolarCamera(playerActor.position, Vector3f(0f, -1.5f, 0f), platformDependencies.screenSettingsSupplier))

        val viewExecutor = platformDependencies.viewExecutor
        drawer = platformDependencies.drawer

        viewExecutor.beginDrawing {
            try {
                Timer.time("Loop") { loop(it) }
                Timer.report()
            } catch (t: Throwable) {
                t.printStackTrace()
                throw t
            }
        }
    }

    private fun loop(elapsedTimeInMs: Double) {
        platformDependencies.keyboard.poll()

        val currentLogicalFrameIncrement = millisToFrames(elapsedTimeInMs)
        val throttledFrameIncrement = min(3f, currentLogicalFrameIncrement.toFloat())

        val pausedFrameIncrement = if (isCheckBox("pause")) {
            0f
        } else {
            if (!isCheckBox("todOverride")) { EnvironmentManager.advanceTime(currentLogicalFrameIncrement) }
            throttledFrameIncrement
        }

        OncePerFrame.onNewFrame()
        internalLoop(pausedFrameIncrement, throttledFrameIncrement)
    }

    fun internalLoop(elapsedFrames: Float, trueElapsedFrames: Float) {
        ZoneChanger.update(elapsedFrames)

        if (!isReady()) {
            drawer.setupXimUi()
            UiElementHelper.drawBlackScreenCover(opacity = 1f)
            UiElementHelper.update(elapsedFrames)
            UiElementHelper.drawDownloadingDataElement(Vector2f(620f, 550f).scale(UiElementHelper.offsetScaling))
            return
        }

        // Environment
        if (!setupZone) {
            emitWelcomeMessage()
            setupZone()
        }

        // Update entities
        SceneManager.getCurrentScene().update(elapsedFrames)

        if (!ZoneChanger.isChangingZones()) {
            val zoneInteraction = Timer.time("checkInteractions") { SceneManager.getCurrentScene().checkInteractions() }
            if (zoneInteraction != null) { ZoneChanger.beginChangeZone(zoneInteraction) }
        }

        handleKeyEvents()
        ClickHandler.handleClickEvents()
        PlayerTargetSelector.updateTarget()

        ActorManager.updateAll(elapsedFrames)
        CameraReference.getInstance().update(platformDependencies.keyboard, trueElapsedFrames)

        val playerActorCollision = playerActor.lastCollisionResult
        val zoneObjectsByArea = Timer.time("cullZoneObjects") { SceneManager.getCurrentScene().getVisibleZoneObjects(CameraReference.getInstance(), CullContext(shadowMode = false), playerActor) }

        PlayerPositionTracker.update()
        PlayerAnimationTrackerTool.update()

        Timer.time("updateEffects") { EffectManager.update(elapsedFrames) }
        val lightingParticles = EffectManager.getLightingParticles()

        EnvironmentManager.update(elapsedFrames)
        EnvironmentTool.update()

        val (playerEnvironmentArea, playerEnvironmentId) = playerActor.lastCollisionResult.getEnvironment()
        EnvironmentManager.updateWeatherAudio(SceneManager.getCurrentScene().getMainArea(), playerEnvironmentId)
        val drawDistance = EnvironmentManager.getDrawDistance(playerEnvironmentArea, playerEnvironmentId)

        Timer.time("updateAudio") { AudioManager.update(elapsedFrames) }

        // Begin 3D effects
        FrameBufferManager.bindAndClearScreenBuffer()

        // The sky
        drawer.setupXim(CameraReference.getInstance())
        EnvironmentManager.drawSkyBox(drawer)

        // The zone
        Timer.time("drawZone") { zoneObjectsByArea.forEach { ZoneDrawer.drawZoneObjects(it.key, it.value, drawDistance, CameraReference.getInstance(), lightingParticles) } }

        // Actor models
        ActorDrawer.update(elapsedFrames)

        drawer.setupXimSkinned(CameraReference.getInstance())
        for (actor in ActorManager.getVisibleActors()) {
            val hasDebugOverride = ZoneObjectTool.drawActor(actor)
            if (hasDebugOverride) { continue }

            Timer.time("drawActor") { ActorDrawer.drawActor(actor, lightingParticles) }
            Timer.time("emitFootSteps") { ZoneDrawer.emitFootSteps(actor, GlobalDirectory.directoryResource, SceneManager.getCurrentScene().getMainAreaRootDirectory()) }
        }

        // Particles & Effects
        val postEffects = Timer.time("drawParticles") { ParticleDrawer.drawAllParticlesAndGetDecals(lightingParticles) }

        // Post effects
        Timer.time("drawDecal") { ZoneDrawer.drawDecalEffects(postEffects.decalEffects, drawDistance) }
        Timer.time("drawLensFlare") { ZoneDrawer.drawLensFlares(postEffects.lensFlareEffects) }

        // 3D Debug tool
        Timer.time("debugTools3d") {
            LineDrawingTool.renderLines(drawer)
            BoxDrawingTool.render(drawer)
            SphereDrawingTool.render(drawer)
        }

        // 3D effects are done, so output the screen-buffer
        FrameBufferManager.unbind()
        drawer.drawScreenBuffer(FrameBufferManager.getCurrentScreenBuffer())

        // UI
        drawer.setupXimUi()
        ScreenFlasher.draw()

        Timer.time("updateUi") { UiElementHelper.update(elapsedFrames) }

        MapDrawer.draw(playerActorCollision)

        if (!isCheckBox("UiDisabled")) {
            Timer.time("drawUi") {
                StatusEffectUi.draw()
                ChatLog.draw()
                PartyUi.draw()

                UiElementTool.drawPreviewElement()

                UiStateHelper.update(elapsedFrames)
                UiStateHelper.draw()
                CastTimeUi.draw()
            }
        }

        // Used for fade-in / fade-out - needs to be last
        ZoneChanger.drawScreenFade()

        // "Game" logic
        Timer.time("gameLogic") {
            BattleEngine.tick(elapsedFrames)
            zoneInstance.update(elapsedFrames)
        }

        // 2D & misc Debug tools
        Timer.time("debugTools") {
            AudioVolumeTool.update()
            RoutineViewer.update()
            TexturePreviewer.draw()
            ParticleGenTool.update()
            PlayerLookTool.update()
        }

        drawer.finish()
    }

    private fun isReady(): Boolean {
        if (!DatLoader.isReady()) { return false }

        val systemEffects = DatLoader.load("ROM/0/0.DAT").onReady { GlobalDirectory.directoryResource = it.getAsResource() }
        DatLoader.load("ROM/27/81.DAT").onReady { GlobalDirectory.shadowResource = it.getAsResource() }
        FontMojiHelper.register()

        val tables = listOf(MainDll, FileTableManager, ItemModelTable, SpellAnimationTable, SpellNameTable, SpellInfoTable, ZoneNameTable, ZoneSettingsTable, AbilityInfoTable, AbilityNameTable, AbilityTable, StatusEffectNameTable, StatusEffectIcons, NpcTable, MountNameTable, ItemAnimationTable, MonsterAbilityNameTable, MobSkillInfoTable)
        tables.forEach { it.preload() }

        if (tables.any { !it.isFullyLoaded() }) { return false }

        val restorePreviousZone = ZoneChanger.setup(zoneConfig)
        if (restorePreviousZone != null) { zoneConfig = restorePreviousZone }

        SceneManager.loadScene(zoneConfig)
        UiResourceManager.prefetch()
        InventoryItems.preload()
        PcModelLoader.preload()

        FrameBufferManager.setup()
        FrameBufferManager.clear()

        if (!FrameBufferManager.isReady() || !systemEffects.isReady() || !SceneManager.isFullyLoaded() || !PcModelLoader.isFullyLoaded() || !InventoryItems.isFullyLoaded()) {
            return false
        }

        return true
    }

    private fun setupZone() {
        setupZone = true

        val config = LocalStorage.getConfiguration()
        val pcModel = PcModel(raceGenderConfig = config.playerRace, actor = playerActor, modelLook = ModelLook(faceIndex = config.playerFace))
        playerActor.actorModel = ActorModel(model = pcModel)

        playerActor.equip(config.playerEquipment)
        playerActor.refreshModelEquipment()

        ActorManager.add(playerActor)
        playerActor.enqueueModelRoutine(DatId.pop, options = RoutineOptions(blocking = false))

        ZoneChanger.onZoneIn()

        EnvironmentManager.setScene(SceneManager.getCurrentScene())

        val zoneGameDefinition = ZoneDefinitionManager[zoneConfig.zoneId] ?: ZoneDefinition(emptyList())
        zoneInstance = ZoneInstance(zoneGameDefinition)

        val zoneName = ZoneNameTable.first(zoneConfig.zoneId)
        ChatLog.addLine("=== Area: $zoneName ===")
        ChatLog.addLine("The maximum number of people are already participating in Unity chat. Please try again later.", chatLogColor = ChatLogColor.Info)

        FrameTool.setup()
        ScreenSettingsTool.setup()
        AudioVolumeTool.setup()
        EnvironmentTool.setup()
        ZoneObjectTool.setup(SceneManager.getCurrentScene().getMainAreaRootDirectory())
        ZoneNpcTool.setup()
        NpcSpawningTool.setup()
        FurnitureSpawningTool.setup()
        RoutineViewer.setup(SceneManager.getCurrentScene().getMainArea())
        SpellHelper.setup()
        PlayerCustomizer.setup()
        InventoryTool.setup()
        UiElementTool.setup()
        ZoneSearchTool.setup()
    }

    private fun emitWelcomeMessage() {
        if (welcomed) { return }
        welcomed = true
        ChatLog.addLine("<<< Welcome to Xim! >>>", ChatLogColor.SystemMessage)

        ChatLog.addLine("${ShiftJis.solidSquare} Assist Channel added for New Players and Returnees!", ChatLogColor.SystemMessage)
        ChatLog.addLine("${ShiftJis.solidSquare} Jeuno, Back in the Spotlight Yet Again ${ShiftJis.leftBracket}until February 12 at 6:59 (PST) / 14:59 (GMT)${ShiftJis.rightBracket}", ChatLogColor.SystemMessage)
    }

    private fun handleKeyEvents() {
        if (ZoneChanger.isChangingZones()) { return }
        val player = ActorManager.player()

        if (platformDependencies.keyboard.isKeyPressed(Keyboard.Key.H)) {
            player.toggleResting()
        }
    }

}
