package xim.poc.tools

import kotlinx.browser.document
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLInputElement
import xim.math.Vector3f
import xim.poc.*
import xim.resource.DatId
import xim.resource.table.FileTableManager
import xim.resource.table.NpcTable

object NpcSpawningTool {

    private val input by lazy { document.getElementById("NpcSpawnId") as HTMLInputElement }
    private val button by lazy { document.getElementById("NpcSpawn") as HTMLButtonElement }
    private val next by lazy { document.getElementById("NpcSpawnNext") as HTMLButtonElement }

    private val spawned = ArrayList<ActorId>()

    fun setup() {
        button.onclick = { spawn() }
        next.onclick = {
            spawn()
            val currentValue = input.value.toIntOrNull(0x10) ?: 0x0
            input.value = (currentValue + 1).toString(0x10)
            Unit
        }
    }

    private fun spawn() {
        val rawInput = input.value
        val modelResourcePath = if (rawInput.startsWith("!")) {
            val id = rawInput.substring(1, rawInput.length).toIntOrNull(0x10) ?: return
            FileTableManager.getFilePath(id) ?: return
        } else {
            val id = rawInput.toIntOrNull(0x10) ?: return
            FileTableManager.getFilePath(NpcTable.getNpcModelId(id)) ?: return
        }

        val position = Vector3f().copyFrom(ActorManager.player().position)
        position.x -= 2f

        val actor = Actor(ActorManager.nextId(), name = modelResourcePath, position = position, actorController = DefaultEnemyController(), enemy = true)
        actor.setDestinationFacingDir(Vector3f(1f, 0f, 0f))

        actor.onReadyToDraw {
            it.transitionToIdle(0f)
            it.playRoutine(DatId("aper"))
            it.playRoutine(DatId("efon"))
            it.playRoutine(DatId.pop)
            it.loopRoutine(DatId("@scd"))
        }

        actor.actorModel = ActorModel(NpcModel(modelResourcePath))

        spawned.forEach { ActorManager.remove(it) }
        spawned.clear()
        spawned += actor.id

        ActorManager.add(actor)
    }

}