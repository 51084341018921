package xim.poc.ui

import xim.poc.ActorManager
import xim.poc.UiElementHelper
import xim.poc.UiResourceManager

object StatusEffectUi {

    private const val menuName = "menu    buff    "

    fun draw() {
        val menu = UiResourceManager.getMenu(menuName) ?: return
        val position = UiElementHelper.drawMenu(menuName = menuName, drawFrame = false) ?: return

        val player = ActorManager.player()
        val statuses = player.getStatusEffects()

        for (i in 0 until statuses.size) {
            val element = menu.uiMenu.elements[i]
            val status = statuses[i]

            status.info.icon.texture ?: continue
            UiElementHelper.drawStatusEffect(status, position + element.offset)
        }
    }

}