package xim.resource.table

import xim.poc.RaceGenderConfig
import xim.poc.browser.DatLoader
import xim.resource.AbilityInfo
import xim.resource.AbilityListResource
import xim.resource.AbilityType
import xim.resource.ByteReader
import xim.util.OnceLogger
import kotlin.random.Random

val AbilityNameTable = StringTable("ROM/181/72.DAT")

object AbilityInfoTable: TableResource {

    private lateinit var abilityListResource: AbilityListResource
    private var preloaded = false

    override fun preload() {
        if (preloaded) { return }
        preloaded = true
        loadTable()
    }

    override fun isFullyLoaded() : Boolean {
        return this::abilityListResource.isInitialized
    }

    fun hasInfo(id: Int): Boolean {
        return abilityListResource.abilities[id] != null
    }

    fun getAbilityInfo(id: Int) : AbilityInfo {
        return abilityListResource.abilities[id] ?: throw IllegalStateException("No info for: $id")
    }

    operator fun get(id: Int) = getAbilityInfo(id)

    private fun loadTable() {
        DatLoader.load("ROM/118/114.DAT").onReady {
            abilityListResource = it.getAsResource().getOnlyChildByType(AbilityListResource::class)
        }
    }

}

data class AbilityAnimInfo(val id: Int, val type: Int, val animationId: Int, val aoe: Boolean)

// https://github.com/LandSandBoat/server/blob/base/sql/abilities.sql
// https://github.com/LandSandBoat/server/blob/base/sql/weapon_skills.sql
object AbilityTable: TableResource {

    private lateinit var animInfos: Map<Int, AbilityAnimInfo>
    private var preloaded = false

    override fun preload() {
        if (preloaded) { return }
        preloaded = true
        loadTable()
    }

    override fun isFullyLoaded() : Boolean {
        return this::animInfos.isInitialized
    }

    fun getAnimationId(abilityInfo: AbilityInfo, raceGenderConfig: RaceGenderConfig): Int? {
        val animInfo = animInfos[abilityInfo.index] ?: return null

        val offset = Random.nextInt(0, abilityInfo.getNumVariants())

        return offset + if (abilityInfo.type == AbilityType.WeaponSkill || animInfo.type == 0x03) {
            MainDll.getBaseWeaponSkillAnimationIndex(raceGenderConfig) + animInfo.animationId
        } else if (animInfo.type == 0x06) {
            0x113C + animInfo.animationId
        } else if (animInfo.type == 0x0D) {
            // Wyvern skills? No corresponding actor ability, so just pick an arbitrary one
            0x113C + 94
        } else if (animInfo.type == 0x0E) {
            MainDll.getBaseDanceSkillAnimationIndex(raceGenderConfig) + animInfo.animationId
        } else {
            OnceLogger.warn("[${animInfo.id}] Unknown animation type: $abilityInfo | $animInfo")
            return null
        }
    }

    private fun loadTable() {
        DatLoader.load("landsandboat/AbilitiesTable-2024-06-30.DAT").onReady { parseTable(it.getAsBytes()) }
    }

    private fun parseTable(byteReader: ByteReader) {
        val skills = HashMap<Int, AbilityAnimInfo>()
        while (byteReader.hasMore()) {
            val skill = AbilityAnimInfo(id = byteReader.next32(), type = byteReader.next32(), animationId = byteReader.next32(), aoe = byteReader.next32() > 0)
            skills[skill.id] = skill
        }
        this.animInfos = skills
    }

}

object PetSkillTable {

    private const val baseFileTableIndex = 0xC0EF

    fun getAnimationId(playerSkill: AbilityInfo): Int? {
        // TODO - Use the mob-table from LandSandBoat.
        // This is a very rough approximation
        return baseFileTableIndex + (playerSkill.index - 1024)
    }

}