package xim.poc

import xim.math.Vector2f
import xim.poc.camera.CameraReference
import xim.poc.gl.*
import xim.poc.tools.ZoneNpcTool
import xim.resource.Blur
import xim.resource.BlurConfig
import xim.resource.LightingParams
import xim.resource.ZoneMeshResource
import xim.util.PI_f
import kotlin.math.cos
import kotlin.math.sin

object ActorDrawer {

    private var frameCounter = 0f

    fun update(elapsedFrames: Float) {
        frameCounter += elapsedFrames
        if (frameCounter > 360f) { frameCounter -= 360f }
    }

    fun drawActor(actor: Actor, effectLighting: EffectLighting) {
        val actorModel = actor.actorModel ?: return

        if (!actor.isReadyToDraw()) { return }
        if (ZoneNpcTool.isForceHidden(actor.id)) { return }

        val effectLights = ArrayList<PointLight>()
        var lightingParams: LightingParams = EnvironmentManager.getModelLighting(SceneManager.getCurrentScene().getMainAreaRootDirectory(), null)

        for (perAreaCollisionProperties in actor.lastCollisionResult.collisionsByArea) {
            val zoneResource = perAreaCollisionProperties.key.getZoneResource()

            val lightingEnvId = perAreaCollisionProperties.value.firstOrNull { it.environmentId != null }?.environmentId
            if (lightingEnvId != null) { lightingParams = EnvironmentManager.getModelLighting(perAreaCollisionProperties.key.root, lightingEnvId) }

            effectLights.addAll(perAreaCollisionProperties.value.asSequence()
                .flatMap { it.lightIndices }
                .map { zoneResource.pointLightLinks[it] }
                .flatMap { effectLighting.getPointLights(perAreaCollisionProperties.key, it, characterMode = true) })
        }

        val skeleton = actorModel.getSkeleton()
        if (skeleton == null) {
            drawFurniture(actor, actorModel, lightingParams, effectLights)
            return
        }

        val meshes = actorModel.getMeshes()

        val drawCommand = DrawXimCommand(
            meshes = meshes,
            lightingParams = lightingParams,
            skeleton = skeleton,
            translate = actor.position,
            pointLights = effectLights,
            effectColor = Color(actor.renderState.effectColor),
            wrapEffect = actor.renderState.wrapEffect,
        )

        val blurConfig = actorModel.getBlurConfig()
        if (blurConfig != null) { drawBlur(blurConfig, drawCommand) }

        MainTool.drawer.drawXimSkinned(drawCommand)
    }

    private fun drawBlur(blurConfig: BlurConfig, drawCommand: DrawXimCommand) {
        FrameBufferManager.bindBlurBuffer()
        MainTool.drawer.drawXimSkinned(drawCommand.copy(forceDisableDepthMask = true))

        FrameBufferManager.bindAndClearScreenHazeBuffer()
        MainTool.drawer.drawScreenBuffer(sourceBuffer = FrameBufferManager.getBlurBuffer(), destBuffer = FrameBufferManager.getHazeBuffer())

        FrameBufferManager.bindScreenBuffer()

        blurConfig.blurs.forEachIndexed { index, blur ->
            val color = Color(blur.color).withMultiplied(2f)
            val offset = getBlurOffset(index, blur, blurConfig)
            val options = DrawXimScreenOptions(position = offset, colorMask = color, blendEnabled = true)
            MainTool.drawer.drawScreenBuffer(FrameBufferManager.getHazeBuffer(), FrameBufferManager.getCurrentScreenBuffer(), options = options)
        }

        MainTool.drawer.setupXimSkinned(CameraReference.getInstance())
    }

    private fun getBlurOffset(index: Int, blur: Blur, blurConfig: BlurConfig): Vector2f {
        val angle = 2 * PI_f * ((frameCounter / 360f) + (index.toFloat() / blurConfig.blurs.size.toFloat()))
        return Vector2f(x = cos(angle), y = sin(angle)) * (blur.offset / 16f)
    }

    private fun drawFurniture(actor: Actor, actorModel: ActorModel, lightingParams: LightingParams, effectLights: ArrayList<PointLight>) {
        val model = actorModel.model
        if (model !is NpcModel) { return }

        val meshes = model.getMeshResources()
            .flatMap { it.collectByType(ZoneMeshResource::class) }
            .flatMap { it.meshes }

        val command = DrawXimCommand(
            meshes = meshes,
            translate = actor.position,
            lightingParams = lightingParams,
            pointLights = effectLights,
        )

        MainTool.drawer.setupXim(CameraReference.getInstance())
        MainTool.drawer.drawXim(command)
        MainTool.drawer.setupXimSkinned(CameraReference.getInstance())
    }

}