package xim.resource.table

import xim.poc.ItemModelSlot
import xim.poc.RaceGenderConfig
import xim.resource.InventoryItemInfo
import xim.util.OnceLogger

object EquipmentResources {

    fun get(raceGenderConfig: RaceGenderConfig, modelSlot: ItemModelSlot, inventoryItemInfo: InventoryItemInfo?): String {
        val modelId = ItemModelTable.getModelId(inventoryItemInfo)
        return get(raceGenderConfig, modelSlot, modelId)
    }

    fun get(raceGenderConfig: RaceGenderConfig, modelSlot: ItemModelSlot, modelId: Int) : String {
        val path = EquipmentModelTable.getItemModelPath(raceGenderConfig, modelSlot, modelId) ?: throw IllegalStateException("Failed to resolve DAT id for modelId: $modelId")
        OnceLogger.info("[${raceGenderConfig.name}][$modelSlot][$modelId] -> $path")
        return path
    }

}