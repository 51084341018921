package xim.poc.ui

import xim.math.Vector2f
import xim.poc.*
import xim.poc.game.AbilityIds
import xim.poc.game.BattleEngine
import xim.poc.game.UiState
import xim.resource.AbilityInfo
import xim.resource.AbilityType
import xim.resource.table.AbilityInfoTable
import xim.resource.table.AbilityNameTable

data class AbilityItem(val index: Int, val info: AbilityInfo) {
    fun getTargetFilter() : (Actor, Actor) -> Boolean {
        return ActionTargetFilter(info.targetFlags)::targetFilter
    }
}


object AbilitySelectUi {

    private const val iconSet1 = "menu    magiconw"
    private const val iconSet2 = "menu    magico2w"

    private val items: Map<AbilityType, List<AbilityItem>> by lazy {
        (1 until 0x1000)
            .filter { AbilityInfoTable.hasInfo(it) }
            .filter { AbilityNameTable.first(it) != "." }
            .map { AbilityItem(it, AbilityInfoTable[it]) }
            .groupBy { it.info.type }
    }

    fun getItems(type: AbilityType): List<AbilityItem> {
        return items[type]!!
    }

    fun draw(type: AbilityType, uiState: UiState) {
        val stackPos = uiState.latestPosition ?: return
        val offset = Vector2f(0f, 0f)

        val scrollSettings = uiState.scrollSettings!!
        val items = getItems(type)

        for (i in scrollSettings.lowestViewableItemIndex until scrollSettings.lowestViewableItemIndex + scrollSettings.numElementsInPage) {
            if (i >= items.size) { break }
            val ability = items[i]

            val name = AbilityNameTable.first(ability.index)
            val info = AbilityInfoTable[ability.index]

            val (iconSet, iconId) = getAbilityIcon(info)

            UiElementHelper.drawUiElement(lookup = iconSet, index = iconId, position = offset + stackPos + Vector2f(16f, 4f))
            UiElementHelper.drawString(text = name, offset = offset + stackPos + Vector2f(36f, 8f))
            offset.y += 16f
        }
    }

    fun getSelectedAbility(type: AbilityType, index: Int): AbilityItem {
        return getItems(type)[index]
    }

    fun getSubAbilityMenuType(type: AbilityType, index: Int): AbilityType? {
        val item = getItems(type).getOrNull(index) ?: return null

        return when (item.index) {
            AbilityIds.BloodPactRage.id -> AbilityType.PetAbility
            AbilityIds.BloodPactWard.id -> AbilityType.PetWard
            AbilityIds.PhantomRoll.id -> AbilityType.PhantomRoll
            AbilityIds.QuickDraw.id -> AbilityType.QuickDraw
            AbilityIds.Samba.id -> AbilityType.Samba
            AbilityIds.Waltz.id -> AbilityType.Waltz
            AbilityIds.Step.id -> AbilityType.Step
            AbilityIds.FlourishI.id -> AbilityType.FlourishI
            AbilityIds.Jig.id -> AbilityType.Jig
            AbilityIds.FlourishII.id -> AbilityType.FlourishII
            AbilityIds.FlourishIII.id -> AbilityType.FlourishIII

            else -> null
        }
    }

    fun useSelectedAbility(type: AbilityType, index: Int, targetId: ActorId) {
        val item = getItems(type).getOrNull(index) ?: return
        val source = ActorManager.player()
        BattleEngine.useAbility(item.info, sourceId = source.id, targetId = targetId)
    }


    private fun getAbilityIcon(abilityInfo: AbilityInfo): Pair<String, Int> {
        return if (abilityInfo.hiResIconId >= 1000) {
            Pair(iconSet2, abilityInfo.hiResIconId - 1000)
        } else {
            Pair(iconSet1, abilityInfo.hiResIconId)
        }
    }
}