package xim.poc.tools

import kotlinx.browser.document
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLOptionElement
import org.w3c.dom.HTMLSelectElement
import xim.math.Vector3f
import xim.poc.*
import xim.resource.DatId
import xim.resource.InventoryItemType
import xim.resource.InventoryItems
import xim.resource.ItemListType
import xim.resource.table.FileTableManager

object FurnitureSpawningTool {

    private val select by lazy { document.getElementById("FurnitureSpawnId") as HTMLSelectElement }
    private val button by lazy { document.getElementById("FurnitureSpawn") as HTMLButtonElement }
    private val next by lazy { document.getElementById("FurnitureSpawnNext") as HTMLButtonElement }

    private val spawned = ArrayList<ActorId>()
    private var setup = false

    fun setup() {
        if (setup) { return }
        setup = true

        InventoryItems.getAll().filter { it.itemType == InventoryItemType.Furnishing }
            .sortedBy { it.itemId }
            .filter { it.itemId > 0 }
            .forEach {
                val child = document.createElement("option") as HTMLOptionElement
                child.text = it.name
                child.value = it.itemId.toString()
                select.appendChild(child)
            }

        button.onclick = { spawn() }
        next.onclick = {
            spawn()
            select.selectedIndex += 1
            Unit
        }
    }

    private fun spawn() {
        val id = select.value.toIntOrNull() ?: return
        val item = InventoryItems[id]
        println("Spawning: $item")

        val modelId = InventoryItems.getFurnitureModelId(item) ?: return
        val modelResourcePath = FileTableManager.getFilePath(modelId) ?: return

        val position = Vector3f().copyFrom(ActorManager.player().position)
        position.x -= 2f

        val actor = Actor(ActorManager.nextId(), name = modelResourcePath, position = position, actorController = DefaultEnemyController(), enemy = true)
        actor.setDestinationFacingDir(Vector3f(1f, 0f, 0f))

        actor.onReadyToDraw {
            it.transitionToIdle(0f)
            it.playRoutine(DatId("aper"))
            it.playRoutine(DatId("efon"))
            it.playRoutine(DatId.pop)
            it.loopRoutine(DatId("@scd"))
        }

        actor.actorModel = ActorModel(NpcModel(modelResourcePath))

        spawned.forEach { ActorManager.remove(it) }
        spawned.clear()
        spawned += actor.id

        ActorManager.add(actor)
    }

}