package xim.poc.game.configuration.zones

import xim.math.Vector3f
import xim.poc.game.MonsterSpawnerDefinition
import xim.poc.game.MonsterTable
import xim.poc.game.StatusEffect.*
import xim.poc.game.WeightedMonsterDef
import xim.poc.game.ZoneDefinition
import xim.poc.game.configuration.MonsterDefinition
import xim.resource.table.NpcLook

object EastSarutabaruta {

    private val mandyDef = MonsterDefinition("Mandy", NpcLook.npc(0x012C))
    private val crawlyDef = MonsterDefinition("Crawler", NpcLook.npc(0x018C))
    private val gobbieDef = MonsterDefinition("Gobbie", NpcLook.npc(0x01E5))
    private val yaguDef = MonsterDefinition("Yagudo", NpcLook.npc(0x0246))
    private val birdDef = MonsterDefinition("Bird", NpcLook.npc(0x01BD))
    private val dhalDef = MonsterDefinition("Dhalmel", NpcLook.npc(0x014C))

    private val fireElem = MonsterDefinition("Fire", NpcLook.npc(0x0008))  { it.gainStatusEffect(BlazeSpikes.id); it.gainStatusEffect(Enfire.id) }
    private val iceElem = MonsterDefinition("Ice", NpcLook.npc(0x0009)) { it.gainStatusEffect(IceSpikes.id); it.gainStatusEffect(Enblizzard.id) }
    private val earthElem = MonsterDefinition("Earth", NpcLook.npc(0x000B))

    private val table = MonsterTable(listOf(
        WeightedMonsterDef(mandyDef, 1.0),
        WeightedMonsterDef(crawlyDef, 1.0),
        WeightedMonsterDef(gobbieDef, 1.0),
        WeightedMonsterDef(yaguDef, 1.0),
        WeightedMonsterDef(birdDef, 1.0),
        WeightedMonsterDef(dhalDef, 1.0),
    ))

    private val elemTable = MonsterTable(listOf(
        WeightedMonsterDef(fireElem, 1.0),
        WeightedMonsterDef(iceElem, 1.0),
        WeightedMonsterDef(earthElem, 1.0),
    ))

    const val zoneId = 116

    val zoneDefinition = ZoneDefinition(
        listOf(
            MonsterSpawnerDefinition(
                position = Vector3f(-76f, -6f, -520f),
                size = Vector3f(20f, 0f, 20f),
                maxMonsters = 10,
                spawnDelay = 60f,
                table = table
            ),
            MonsterSpawnerDefinition(
                position = Vector3f(-70f, -6f, -520f),
                size = Vector3f(0.1f, 0f, 0f),
                maxMonsters = 1,
                spawnDelay = 60f,
                table = elemTable
            )
        ),
    )

}