package xim.poc.tools

import web.dom.document
import web.html.HTMLInputElement
import web.html.HTMLSpanElement
import xim.poc.ActorManager
import xim.poc.ItemModelSlot
import xim.poc.PcModel
import xim.resource.table.EquipmentModelTable

object PlayerLookTool {

    private val enabled by lazy {
        ItemModelSlot.values().associateWith { "${it.name}OverrideEnabled" }
            .mapValues { document.getElementById(it.value) }
            .filterValues { it != null }
            .mapValues { (it.value as HTMLInputElement).also { c -> c.onchange = { internalUpdate() } } }
    }

    private val values by lazy {
        ItemModelSlot.values().associateWith { "${it.name}OverrideValue" }
            .mapValues { document.getElementById(it.value) }
            .filterValues { it != null }
            .mapValues { it.value as HTMLInputElement }
    }

    private val paths by lazy {
        ItemModelSlot.values().associateWith { "${it.name}Path" }
            .mapValues { document.getElementById(it.value) }
            .filterValues { it != null }
            .mapValues { it.value as HTMLSpanElement }
    }

    fun update() {
        if (enabled.none { it.value.checked }) { return }
        internalUpdate()
    }

    private fun internalUpdate() {
        val player = ActorManager.player()
        player.refreshModelEquipment()

        val playerModel = player.actorModel?.model ?: return
        playerModel as PcModel

        val look = playerModel.copyLook()

        for (itemSlot in ItemModelSlot.values()) {
            if (enabled[itemSlot]?.checked != true) { continue }
            val value = values[itemSlot]?.value?.toIntOrNull()?.coerceAtLeast(0) ?: 0

            val path = getPath(playerModel, itemSlot, value)
            paths[itemSlot]?.innerText = path ?: "Not found"

            if (path != null) { look[itemSlot] = value }
        }

        playerModel.updateLook(look)
    }

    private fun getPath(playerModel: PcModel, itemModelSlot: ItemModelSlot, itemModelId: Int): String? {
        return try {
            EquipmentModelTable.getItemModelPath(playerModel.raceGenderConfig, itemModelSlot, itemModelId)
        } catch (e: Exception) {
            null
        }
    }

}