package xim.poc.game

import xim.math.Vector2f
import xim.poc.*
import xim.poc.audio.AudioManager
import xim.poc.audio.SystemSound
import xim.poc.browser.Keyboard
import xim.poc.tools.ZoneChanger
import xim.poc.ui.*
import xim.resource.*
import xim.resource.table.SpellInfoTable
import xim.util.Fps.millisToFrames
import kotlin.math.max
import kotlin.math.min
import kotlin.time.Duration.Companion.seconds

enum class ParentRelative {
    RightOfParent,
    TopOfParent,
}

data class UiState(
    val additionalDraw: ((UiState) -> Unit)? = null,
    val focusMenu: String? = null,
    val dynamicFocusMenu: (() -> String?)? = null,
    val menuStacks: MenuStacks? = null,
    val appendType: AppendType = AppendType.Append,
    val drawParent: Boolean = false,
    val childStates: (() -> List<UiState>)? = null,
    val parentRelative: ParentRelative? = null,
    val subTargetSelect: Boolean = false,
    val onPushed: (() -> Boolean)? = null,
    val onPopped: (() -> Unit)? = null,
    val scrollSettings: ScrollSettings? = null,
    val resetCursorIndexOnPush: Boolean = true,
    val defaultCursorIndex: Int? = null,
    val handler: (cursorPos: Int) -> Boolean,
) {
    var cursorIndex: Int = 0
    var latestPosition: Vector2f? = null
    var latestMenu: UiMenu? = null
    var latestParent: UiState? = null

    fun resetCursorIndex() {
        cursorIndex = 0
        scrollSettings?.lowestViewableItemIndex = 0
    }

    fun applyCursorBounds() {
        val scrollSettings = this.scrollSettings ?: return

        val totalItems = scrollSettings.numElementsProvider()

        val maxItemIndex = (totalItems - 1).coerceAtLeast(0)
        val maxCursorIndex = scrollSettings.numElementsInPage - 1

        if (cursorIndex > min(maxItemIndex, maxCursorIndex)) {
            resetCursorIndex()
            return
        }

        val maxScrollPosition = max(0, totalItems - scrollSettings.numElementsInPage)
        if (scrollSettings.lowestViewableItemIndex > maxScrollPosition || cursorIndex >= totalItems ) { resetCursorIndex() }
    }

    fun getFocusMenu(): String? {
        return focusMenu ?: dynamicFocusMenu?.invoke()
    }

}

data class ScrollSettings(
    val numElementsInPage: Int,
    val numElementsProvider: () -> Int,
) {
    var lowestViewableItemIndex = 0
}

data class UiStateFrame(
    val state: UiState,
    val parent: UiStateFrame?,
)

class PendingAction(
    val targetFilter: TargetFilter,
    val execute: () -> Unit,
)

object UiStateHelper {

    var defaultContext: UiState
    lateinit var actionContext: UiState
    lateinit var magicTypeSelectContext: UiState
    lateinit var magicSelectContext: UiState
    lateinit var subTargetContext: UiState
    lateinit var menuWindowContext: UiState
    lateinit var inventoryContext: UiState
    lateinit var itemUseContext: UiState
    lateinit var itemSortContext: UiState
    lateinit var pendingYesNoContext: UiState
    lateinit var equipContext: UiState
    lateinit var equipSelectContext: UiState
    lateinit var returnToHomePointContext: UiState
    lateinit var statusWindowContext: UiState
    lateinit var mountSelectContext: UiState
    lateinit var targetAnimationContext: UiState

    lateinit var abilityTypeContext: UiState

    private var pendingAction: PendingAction? = null
    private var pendingYesNoCommand: (() -> Unit)? = null
    private var magicFilter: MagicType = MagicType.None
    private var itemFilter: ItemListType? = null

    private val jobAbilitySelectMenu = HashMap<AbilityType, UiState>()

    init {
        defaultContext = UiState(
            dynamicFocusMenu = { getDefaultContextMenu() },
            additionalDraw = { drawDefaultContextMenu(it) }
        ) { cursorPos -> handleDefaultContextMenu(cursorPos) }

        actionContext = UiState(
            dynamicFocusMenu = { getActionContextMenu() },
            menuStacks = MenuStacks.LogStack,
        ) { cursorPos -> handleActionContextMenu(cursorPos) }

        abilityTypeContext = UiState(
            focusMenu = "menu    abiselec",
            menuStacks = MenuStacks.LogStack,
        ) { cursorPos ->
            if (cursorPos == 0 && isEnterPressed()) {
                pushState(makeAbilityMenu(AbilityType.JobAbility), SystemSound.MenuSelect)
                true
            } else if (cursorPos == 1 && isEnterPressed()) {
                pushState(makeAbilityMenu(AbilityType.WeaponSkill), SystemSound.MenuSelect)
                true
            } else if (cursorPos == 2 && isEnterPressed()) {
                pendingAction = PendingAction(
                    targetFilter = { _, target -> target.enemy && !target.isDead() },
                    execute = { val player = ActorManager.player(); BattleEngine.startRangedAttack(player, player.subTarget!!) }
                )
                pushState(subTargetContext, SystemSound.MenuSelect)
                true
            } else if (cursorPos == 3 && isEnterPressed()) {
                pushState(makeAbilityMenu(AbilityType.PetCommand), SystemSound.MenuSelect)
                true
            } else if (cursorPos == 4 && isEnterPressed()) {
                pushState(mountSelectContext, SystemSound.MenuSelect)
                true
            } else if (keyPressed(Keyboard.Key.ESC)) {
                popState()
                true
            } else {
                false
            }
        }

        magicTypeSelectContext = UiState(
            focusMenu = "menu    magselec",
            menuStacks = MenuStacks.LogStack,
            drawParent = true,
        ) { cursorPos ->
            if (keyPressed(Keyboard.Key.LEFT) || keyPressed(Keyboard.Key.ESC)) {
                popState()
                true
            } else if (keyPressed(Keyboard.Key.ENTER)) {
                val newMagicFilter = when(cursorPos) {
                    0 -> MagicType.WhiteMagic
                    1 -> MagicType.BlackMagic
                    2 -> MagicType.Songs
                    3 -> MagicType.Ninjutsu
                    4 -> MagicType.Summoning
                    5 -> MagicType.BlueMagic
                    6 -> MagicType.Geomancy
                    else -> throw IllegalStateException()
                }

                if (newMagicFilter != magicFilter) {
                    magicFilter = newMagicFilter
                    magicSelectContext.resetCursorIndex()
                }

                pushState(magicSelectContext, SystemSound.MenuSelect)
                true
            } else {
                false
            }
        }

        magicSelectContext = UiState(
            additionalDraw = { SpellSelectUi.drawSpells(it, magicFilter); SpellSelectUi.drawRecast(currentItemIndex(), magicFilter) },
            focusMenu = "menu    magic   ",
            menuStacks = MenuStacks.LogStack,
            appendType = AppendType.StackAndAppend,
            resetCursorIndexOnPush = false,
            scrollSettings = ScrollSettings(numElementsInPage = 12) { SpellSelectUi.getSpellItems(magicFilter).size },
            onPopped = { magicFilter = MagicType.None }
        ) { cursorPos ->
            if (keyPressed(Keyboard.Key.ENTER)) {
                val player = ActorManager.player()
                if (player.isCasting()) { return@UiState true }

                val spellIndex = currentItemIndex()
                val selectedSpell = SpellSelectUi.getSelectedSpell(spellIndex, magicFilter)

                val spellInfo = SpellInfoTable[selectedSpell.spellIndex]
                if (!BattleEngine.canCastSpell(player, spellInfo)) {
                    AudioManager.playSystemSoundEffect(SystemSound.Invalid)
                    return@UiState true
                }

                pendingAction = PendingAction(
                    targetFilter = selectedSpell.getTargetFilter(),
                    execute = { SpellSelectUi.castSelectedSpell(spellIndex, ActorManager.player().subTarget!!, magicFilter) }
                )

                pushState(subTargetContext, SystemSound.MenuSelect)
                true
            } else if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

        subTargetContext = UiState(
            subTargetSelect = true,
            onPushed = {
                val selectedTarget = PlayerTargetSelector.onStartSelectingSubTarget(pendingAction!!.targetFilter)
                if (selectedTarget == null) {
                    popState(SystemSound.Invalid)
                    false
                } else {
                    true
                }
            },
            onPopped = {
                PlayerTargetSelector.onFinishedSelectingSubTarget()
            }
        ) { cursorPos ->
            val pending = pendingAction!!

            if (!PlayerTargetSelector.updateSubTarget(pending.targetFilter)) {
                popState(SystemSound.MenuClose)
                true
            } else if (keyPressed(Keyboard.Key.ENTER)) {
                pending.execute()
                popState(SystemSound.MenuClose)
                popState()
                true
            } else if (keyPressed(Keyboard.Key.TAB)) {
                PlayerTargetSelector.targetCycle(subTarget = true, targetFilter = pending.targetFilter)
                true
            } else if (targetDirectly()){
                true
            } else if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

        menuWindowContext = UiState(
            focusMenu = "menu    menuwind",
            resetCursorIndexOnPush = false,
            menuStacks = MenuStacks.PartyStack,
            appendType = AppendType.HorizontalOnly,
        ) { cursorPos ->
            if (cursorPos == 0 && keyPressed(Keyboard.Key.ENTER)) {
                pushState(statusWindowContext, SystemSound.MenuSelect)
                true
            } else if (cursorPos == 1 && keyPressed(Keyboard.Key.ENTER)) {
                pushState(equipContext, SystemSound.MenuSelect)
                true
            } else if (cursorPos == 3 && keyPressed(Keyboard.Key.ENTER)) {
                pushState(inventoryContext, SystemSound.MenuSelect)
                true
            } else if (cursorPos == 11 && keyPressed(Keyboard.Key.ENTER)) {
                MapDrawer.toggle()
                AudioManager.playSystemSoundEffect(SystemSound.MenuSelect)
                true
            } else if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

        inventoryContext = UiState(
            additionalDraw = { InventoryUi.drawInventoryItems(it, itemTypeFilter = itemFilter) },
            focusMenu = "menu    inventor",
            resetCursorIndexOnPush = false,
            scrollSettings = ScrollSettings(numElementsInPage = 10) { InventoryUi.getItems(itemTypeFilter = itemFilter).size },
            onPopped = { itemFilter = null },
        ) { cursorPos ->
            if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else if (isEnterPressed()) {
                pushState(itemUseContext, SystemSound.MenuSelect)
                true
            } else if (keyPressed(Keyboard.Key.F)) {
                pushState(itemSortContext, SystemSound.MenuSelect)
                true
            } else {
                false
            }
        }

        itemUseContext = UiState(
            focusMenu = "menu    iuse    ",
            drawParent = true,
            menuStacks = MenuStacks.PartyStack,
            appendType = AppendType.HorizontalOnly,
        ) { cursorPos ->
            if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else if (cursorPos == 0 && isEnterPressed()) {
                val currentItem = InventoryUi.getSelectedItem(inventoryContext, itemTypeFilter = itemFilter)!!
                val targetFilter = InventoryUi.getTargetFilter(currentItem)

                pendingAction = PendingAction(
                    targetFilter = targetFilter,
                    execute = { InventoryUi.useSelectedInventoryItem(currentItem, ActorManager.player().subTarget!!) }
                )

                pushState(subTargetContext, SystemSound.MenuSelect)
                true
            } else if (cursorPos == 1 && isEnterPressed()) {
                val currentItemIndex = InventoryUi.getSelectedItemIndex(inventoryContext)
                val currentItem = InventoryUi.getSelectedItem(inventoryContext, itemTypeFilter = itemFilter)!!

                pendingYesNoCommand = { PlayerInventory.discardItem(currentItemIndex, currentItem); popState() }
                pushState(pendingYesNoContext, SystemSound.MenuSelect)
                true
            } else {
                false
            }
        }

        itemSortContext = UiState(
            focusMenu = "menu    mgcsortw",
            drawParent = true,
            menuStacks = MenuStacks.PartyStack,
            appendType = AppendType.HorizontalOnly,
        ) { cursorPos ->
            if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else if (cursorPos == 0 && isEnterPressed()) {
                pendingYesNoCommand = { PlayerInventory.sort() }
                pushState(pendingYesNoContext, SystemSound.MenuSelect)
                AudioManager.playSystemSoundEffect(SystemSound.MenuSelect)
                true
            } else {
                false
            }
        }

        pendingYesNoContext = UiState(
            focusMenu = "menu    sortyn  ",
            drawParent = true,
            menuStacks = MenuStacks.PartyStack,
            appendType = AppendType.HorizontalOnly,
            defaultCursorIndex = 1,
        ) { cursorPos ->
            if (keyPressed(Keyboard.Key.ESC)) {
                pendingYesNoCommand = null
                popState(SystemSound.MenuClose)
                true
            } else if (cursorPos == 0 && isEnterPressed()) {
                pendingYesNoCommand?.invoke()
                pendingYesNoCommand = null
                popState(SystemSound.MenuSelect)
                true
            } else if (cursorPos == 1 && isEnterPressed()) {
                pendingYesNoCommand = null
                popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

        equipContext = UiState(
            additionalDraw = { EquipScreenUi.draw(it) },
            focusMenu = "menu    equip   ",
            childStates = { listOf(equipSelectContext) },
            resetCursorIndexOnPush = false,
        ) {
            if (keyPressed(Keyboard.Key.ENTER)) {
                val numItems = EquipScreenUi.getInventoryItemsByEquipSlot(equipContext.cursorIndex).size
                if (numItems == 0) {
                    AudioManager.playSystemSoundEffect(SystemSound.Invalid)
                } else {
                    pushState(equipSelectContext, SystemSound.MenuSelect)
                }
                true
            } else if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

        equipSelectContext = UiState(
            focusMenu = "menu    inventor",
            drawParent = true,
            parentRelative = ParentRelative.RightOfParent,
            additionalDraw = { InventoryUi.drawInventoryItems(it, equipSlotFilter = equipContext.cursorIndex) },
            scrollSettings = ScrollSettings(numElementsInPage = 10) { EquipScreenUi.getInventoryItemsByEquipSlot(equipContext.cursorIndex).size },
        ) {
            if (keyPressed(Keyboard.Key.ENTER)) {
                EquipScreenUi.equipHoveredItem(equipSelectContext)
                popState(SystemSound.MenuSelect)
                true
            } else if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

        mountSelectContext = UiState(
            additionalDraw = { MountSelectUi.draw(it) },
            focusMenu = "menu    magic   ",
            menuStacks = MenuStacks.LogStack,
            appendType = AppendType.StackAndAppend,
            resetCursorIndexOnPush = false,
            scrollSettings = ScrollSettings(numElementsInPage = 12) { MountSelectUi.getItems().size },
        ) { cursorPos ->
            if (keyPressed(Keyboard.Key.ENTER)) {
                val itemIndex = currentItemIndex()
                MountSelectUi.useSelectedAbility(itemIndex)
                true
            } else if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

        targetAnimationContext = UiState(
            additionalDraw = { TargetAnimationUi.draw(it) },
            focusMenu = "menu    magic   ",
            menuStacks = MenuStacks.LogStack,
            appendType = AppendType.StackAndAppend,
            resetCursorIndexOnPush = false,
            scrollSettings = ScrollSettings(numElementsInPage = 12) { TargetAnimationUi.getItems().size },
        ) { cursorPos ->
            if(ActorManager.player().target == null) {
                popState(SystemSound.MenuClose)
                true
            } else if (keyPressed(Keyboard.Key.ENTER)) {
                val itemIndex = currentItemIndex()
                TargetAnimationUi.selectedOption(itemIndex)
                popState(SystemSound.MenuClose)
                true
            } else if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

        returnToHomePointContext = yesNoMenu("Return to Home Point?",
            onYes = { ZoneChanger.returnToHomePoint(restore = true); popState() },
            onNo = { popState(SystemSound.MenuClose) }
        )

        statusWindowContext = UiState(
            focusMenu = "menu    statuswi",
            drawParent = true,
            additionalDraw = { StatusWindowUi.draw(it) },
        ) { cursorPos ->
            if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

    }

    private var currentState = UiStateFrame(defaultContext, null)
    private var inputThrottler = 0f

    fun update(elapsedFrames: Float) {
        inputThrottler -= elapsedFrames
        if (inputThrottler > 0f) {
            return
        }

        val current = current()
        if (current.state.handler.invoke(current.state.cursorIndex)) {
            inputThrottler = millisToFrames(100)
        } else if (moveCursor()) {
            inputThrottler = millisToFrames(100)
        }
    }

    fun draw() {
        drawFrame(current(), true)
    }

    fun isSubTargetMode(): Boolean {
        return current().state.subTargetSelect
    }

    private fun drawFrame(frame: UiStateFrame, focus: Boolean, skipParents: Boolean = false) {
        if (!skipParents && frame.state.drawParent && frame.parent != null) {
            frame.state.latestParent = frame.parent.state
            drawFrame(frame.parent, false)
        }

        if (focus && frame.state.subTargetSelect) {
            val subTarget = ActorManager.player().subTarget
            if (subTarget != null) {
                ActorManager[subTarget]?.also { PartyUi.drawTargetPointer(it, subTarget = true) }
            }
        }

        val cursorPos = if (focus) { frame.state.cursorIndex } else { -1 }

        val offsetOverride = if (frame.state.parentRelative != null) {
            val parent = frame.parent!!
            val parentLatestPos = parent.state.latestPosition!!
            val parentLatestSettings = parent.state.latestMenu!!

            when(frame.state.parentRelative) {
                ParentRelative.RightOfParent -> Vector2f(parentLatestPos.x + parentLatestSettings.frame.size.x + 2f, parentLatestPos.y)
                ParentRelative.TopOfParent -> Vector2f(parentLatestPos.x, parentLatestPos.y + parentLatestSettings.frame.size.y + 2f)
            }
        } else {
            null
        }

        frame.state.getFocusMenu()?.let {
            frame.state.latestMenu = UiResourceManager.getMenu(it)?.uiMenu
            frame.state.latestPosition = UiElementHelper.drawMenu(
                menuName = it,
                cursorIndex = cursorPos,
                offsetOverride = offsetOverride,
                menuStacks = frame.state.menuStacks,
                appendType = frame.state.appendType,
                scrollSettings = frame.state.scrollSettings,
            )
        }

        frame.state.additionalDraw?.invoke(frame.state)

        if (focus) {
            val children = frame.state.childStates?.invoke() ?: emptyList()
            for (child in children) { drawFrame(UiStateFrame(child, frame), focus = false, skipParents = true) }
        }
    }

    fun cursorLocked(): Boolean {
        return current().state.getFocusMenu() != null
    }

    fun clear(systemSound: SystemSound? = null) {
        while (currentState.parent != null) { popState() }
        if (systemSound != null) { AudioManager.playSystemSoundEffect(systemSound) }
    }

    fun isFocus(uiState: UiState) : Boolean {
        return current().state == uiState
    }

    private fun current(): UiStateFrame {
        return currentState
    }

    private fun pushState(newState: UiState, systemSound: SystemSound? = null) {
        currentState = UiStateFrame(newState, current())
        if (newState.resetCursorIndexOnPush) { newState.resetCursorIndex() } else { newState.applyCursorBounds() }

        if (newState.defaultCursorIndex != null) { newState.cursorIndex = newState.defaultCursorIndex }

        val success = newState.onPushed?.invoke() ?: true
        if (success && systemSound != null) { AudioManager.playSystemSoundEffect(systemSound) }
    }

    private fun popState(systemSound: SystemSound? = null) {
        currentState.state.onPopped?.invoke()
        currentState = currentState.parent!!
        if (systemSound != null) { AudioManager.playSystemSoundEffect(systemSound) }
    }

    private fun isEnterPressed() : Boolean {
        return keyPressed(Keyboard.Key.ENTER)
    }

    private fun keyPressed(key: Keyboard.Key): Boolean {
        return getFakeTouchPresses().contains(key) || MainTool.platformDependencies.keyboard.isKeyPressed(key)
    }

    private fun keyPressedOrRepeated(key: Keyboard.Key): Boolean {
        return getFakeTouchPresses().contains(key) || MainTool.platformDependencies.keyboard.isKeyPressedOrRepeated(key)
    }

    private fun moveCursor(): Boolean {
        val key = if (keyPressedOrRepeated(Keyboard.Key.LEFT)) { UiMenuCursorKey.Left }
        else if (keyPressedOrRepeated(Keyboard.Key.RIGHT)) { UiMenuCursorKey.Right }
        else if (keyPressedOrRepeated(Keyboard.Key.UP)) { UiMenuCursorKey.Up }
        else if (keyPressedOrRepeated(Keyboard.Key.DOWN)) { UiMenuCursorKey.Down}
        else { return false }

        val current = current().state
        val startingIndex = current.cursorIndex

        val focusMenuName = current.getFocusMenu() ?: return false
        val menu = UiResourceManager.getMenu(focusMenuName)?.uiMenu ?: return false

        val scrollSettings = current.scrollSettings
        val startingView = scrollSettings?.lowestViewableItemIndex

        if (scrollSettings != null) {
            val numTotalItems = scrollSettings.numElementsProvider.invoke()
            val itemIndex = scrollSettings.lowestViewableItemIndex + current.cursorIndex

            val maxItemIndex = (numTotalItems - 1).coerceAtLeast(0)
            val highestViewableItemIndex = scrollSettings.lowestViewableItemIndex + min(maxItemIndex, scrollSettings.numElementsInPage - 1)

            val maxCursorIndex = scrollSettings.numElementsInPage - 1

            if (itemIndex == 0 && key == UiMenuCursorKey.Up) {
                current.cursorIndex = min(maxItemIndex, maxCursorIndex)
                scrollSettings.lowestViewableItemIndex = max(0, numTotalItems - scrollSettings.numElementsInPage)
            } else if (current.cursorIndex == 0 && key == UiMenuCursorKey.Up) {
                scrollSettings.lowestViewableItemIndex -= 1
            } else if (itemIndex == maxItemIndex && key == UiMenuCursorKey.Down) {
                current.cursorIndex = 0
                scrollSettings.lowestViewableItemIndex = 0
            } else if (current.cursorIndex == maxCursorIndex && key == UiMenuCursorKey.Down) {
                scrollSettings.lowestViewableItemIndex += 1
            } else if (key == UiMenuCursorKey.Left && scrollSettings.lowestViewableItemIndex == 0) {
                current.cursorIndex = 0
            } else if (key == UiMenuCursorKey.Left) {
                val amountToSub = min(scrollSettings.numElementsInPage, scrollSettings.lowestViewableItemIndex)
                scrollSettings.lowestViewableItemIndex -= amountToSub
            } else if (key == UiMenuCursorKey.Right && (highestViewableItemIndex == maxItemIndex || maxItemIndex < scrollSettings.numElementsInPage)) {
                current.cursorIndex = min(maxItemIndex, maxCursorIndex)
            } else if (key == UiMenuCursorKey.Right) {
                val amountToAdd = min(scrollSettings.numElementsInPage, maxItemIndex - highestViewableItemIndex)
                scrollSettings.lowestViewableItemIndex += amountToAdd
            } else {
                navigateCursor(menu, key)
            }
        } else {
            navigateCursor(menu, key)
        }

        val endingIndex = current.cursorIndex
        val endingView = scrollSettings?.lowestViewableItemIndex

        if (startingIndex != endingIndex || startingView != endingView) { AudioManager.playSystemSoundEffect(SystemSound.CursorMove) }
        return true
    }

    private fun getFakeTouchPresses(): Set<Keyboard.Key> {
        val touches = MainTool.platformDependencies.keyboard.getTouchData().filter { it.isControlTouch() }

        val fakeKeys = HashSet<Keyboard.Key>()
        for (touch in touches) {
            if (touch.dX < -0.1) { fakeKeys += Keyboard.Key.LEFT }
            else if (touch.dX > 0.1) { fakeKeys += Keyboard.Key.RIGHT }
            else if (touch.dY < -0.1) { fakeKeys += Keyboard.Key.UP }
            else if (touch.dY > 0.1) { fakeKeys += Keyboard.Key.DOWN }
        }

        val click = MainTool.platformDependencies.keyboard.getClickEvents().lastOrNull()
        if (click != null && !click.consumed) {
            if (click.normalizedScreenX > 0.9 && click.normalizedScreenY < 0.1) {
                 fakeKeys += Keyboard.Key.MINUS
            } else if (click.isLongClick()) {
                if (click.normalizedScreenX > 0.5) { fakeKeys += Keyboard.Key.ESC }
            } else {
                fakeKeys += Keyboard.Key.ENTER
            }
        }

        return fakeKeys
    }

    private fun navigateCursor(menu: UiMenu, key: UiMenuCursorKey) {
        if (menu.elements.isEmpty()) { return }
        val current = current()
        val currentElement = menu.elements[current.state.cursorIndex]
        current.state.cursorIndex = currentElement.next[key]!! - 1
    }

    private fun currentItemIndex(): Int {
        val current = current()
        return current.state.cursorIndex + (current.state.scrollSettings?.lowestViewableItemIndex ?: 0)
    }

    private fun handleDefaultEnter() {
        val player = ActorManager.player()
        val target = ActorManager[player.target]

        if (target == null) {
            PlayerTargetSelector.targetCycle()
            if (player.target != null) { return }
        } else {
            if (target.isDoor() || target.isElevator()) {
                if (TargetAnimationUi.isBasicDoor()) {
                    target.toggleDoorState()
                    PlayerTargetSelector.clearTarget()
                    AudioManager.playSystemSoundEffect(SystemSound.MenuClose)
                } else if (TargetAnimationUi.getItems().isNotEmpty()) {
                    pushState(targetAnimationContext, SystemSound.TargetConfirm)
                } else {
                    PlayerTargetSelector.clearTarget()
                    AudioManager.playSystemSoundEffect(SystemSound.MenuClose)
                }
                return
            }
        }

        pushState(actionContext, SystemSound.TargetConfirm)
    }

    private fun getDefaultContextMenu(): String? {
        val player = ActorManager.player()

        return if (ZoneChanger.isChangingZones()) {
            null
        } else if (player.isDead()) {
            "menu    dead    "
        } else {
            null
        }
    }

    private fun drawDefaultContextMenu(uiState: UiState) {
        if (ZoneChanger.isChangingZones()) { return }
        Compass.draw()

        val player = ActorManager.player()
        if (!player.isDead()) { return }

        val initialTime = 60.seconds
        val time = initialTime - player.timeSinceDeath()

        val minutes = time.inWholeMinutes
        val seconds = (time.inWholeSeconds % 60).toString().padStart(2, '0')

        UiElementHelper.drawString("${minutes}:${seconds}", offset = uiState.latestPosition!! + Vector2f(78f, 5f), Font.FontShp)
    }

    fun hasActiveUi(): Boolean {
        return current().state != defaultContext
    }

    fun getPendingActionTargetFilter(): TargetFilter? {
        if (current().state != subTargetContext) { return null }
        return pendingAction?.targetFilter
    }

    fun handleTargetConfirm() {
        handleDefaultEnter()
    }

    private fun handleDefaultContextMenu(cursorPos: Int): Boolean {
        val player = ActorManager.player()

        return if (ZoneChanger.isChangingZones()) {
            true
        } else if (player.isDead()) {
            if (isEnterPressed()) {
                pushState(returnToHomePointContext, SystemSound.MenuSelect)
                true
            } else {
                false
            }
        } else {
            if (isEnterPressed()) {
                handleDefaultEnter()
                true
            } else if (targetDirectly()){
                true
            } else if (keyPressed(Keyboard.Key.TAB)) {
                PlayerTargetSelector.targetCycle()
                true
            } else if (keyPressed(Keyboard.Key.ESC)) {
                PlayerTargetSelector.clearTarget()
                true
            } else if (keyPressed(Keyboard.Key.MINUS)) {
                pushState(menuWindowContext, SystemSound.MenuSelect)
                true
            } else {
                false
            }
        }
    }

    private fun targetDirectly(): Boolean {
        return if (keyPressed(Keyboard.Key.F1)){
            PlayerTargetSelector.targetPartyMember(0)
            true
        } else if (keyPressed(Keyboard.Key.F2)){
            PlayerTargetSelector.targetPartyMember(1)
            true
        } else if (keyPressed(Keyboard.Key.F3)){
            PlayerTargetSelector.targetPartyMember(2)
            true
        } else if (keyPressed(Keyboard.Key.F4)){
            PlayerTargetSelector.targetPartyMember(3)
            true
        } else if (keyPressed(Keyboard.Key.F5)){
            PlayerTargetSelector.targetPartyMember(4)
            true
        } else if (keyPressed(Keyboard.Key.F6)){
            PlayerTargetSelector.targetPartyMember(5)
            true
        } else {
            false
        }
    }

    private fun getActionContextMenu(): String {
        val items = getCurrentActions()
        ActionMenuBuilder.register("custom  actions ", items)
        return "custom  actions "
    }

    private fun handleActionContextMenu(cursorPos: Int): Boolean {
        val actions = getCurrentActions()
        val current = actions.getOrNull(cursorPos)

        if (current == null) {
            clear()
            return true
        }

        return if (current == ActionMenuItem.Attack && isEnterPressed()) {
            BattleEngine.onPlayerEngage()
            true
        } else if (current == ActionMenuItem.Abilities && isEnterPressed()) {
            pushState(abilityTypeContext, SystemSound.MenuSelect)
            true
        } else if (current == ActionMenuItem.Magic && isEnterPressed()) {
            pushState(magicSelectContext, SystemSound.MenuSelect)
            true
        } else if (current == ActionMenuItem.Trust && isEnterPressed()) {
            magicFilter = MagicType.Trust
            pushState(magicSelectContext, SystemSound.MenuSelect)
            true
        } else if (current == ActionMenuItem.Magic && keyPressed(Keyboard.Key.RIGHT)) {
            pushState(magicTypeSelectContext)
            true
        } else if (current == ActionMenuItem.Check && isEnterPressed()) {
            pushState(targetAnimationContext, SystemSound.MenuSelect)
            true
        } else if (current == ActionMenuItem.Items && isEnterPressed()) {
            itemFilter = ItemListType.UsableItem
            pushState(inventoryContext, SystemSound.MenuSelect)
            true
        } else if (current == ActionMenuItem.Release && isEnterPressed()) {
            val player = ActorManager.player()
            BattleEngine.releaseTrust(player, player.target)
            true
        } else if (current == ActionMenuItem.Dismount && isEnterPressed()) {
            BattleEngine.dismount(ActorManager.player())
            true
        } else if (current == ActionMenuItem.Disengage && isEnterPressed()) {
            BattleEngine.onPlayerDisengage()
            AudioManager.playSystemSoundEffect(SystemSound.MenuSelect)
            true
        } else if (keyPressed(Keyboard.Key.ESC)) {
            popState(SystemSound.MenuClose)
            true
        } else {
            false
        }
    }

    private fun getCurrentActions(): List<ActionMenuItem> {
        val actions = ArrayList<ActionMenuItem>()

        val player = ActorManager.player()
        val target = ActorManager[player.target]

        if (!player.isEngagedOrEngaging() && target != null && target.enemy) {
            actions += ActionMenuItem.Attack
        }

        actions += ActionMenuItem.Magic
        actions += ActionMenuItem.Abilities
        actions += ActionMenuItem.Trust
        actions += ActionMenuItem.Items

        if (player.getMount() != null) {
            actions += ActionMenuItem.Dismount
        }

        val playerParty = PartyManager[player]
        if (target != null && playerParty.contains(target) && target.owner == player.id) {
            actions += ActionMenuItem.Release
        }

        if (player.isEngaged()) {
            actions += ActionMenuItem.Disengage
        }

        if (target != null) {
            actions += ActionMenuItem.Check
        }

        return actions
    }

    fun yesNoMenu(text: String, onYes: () -> Unit, onNo: () -> Unit, closeable: Boolean = true): UiState {
        return UiState(
            additionalDraw = { UiElementHelper.drawString(text, Vector2f(8f, 8f) + it.latestPosition!!)},
            focusMenu = "menu    comyn   ",
            drawParent = true,
        ) { cursorPos ->
            if (cursorPos == 0 && isEnterPressed()) {
                onYes.invoke()
                true
            } else if (cursorPos == 1 && isEnterPressed()) {
                onNo.invoke()
                true
            } else if (closeable && keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }
    }

    private fun makeAbilityMenu(type: AbilityType): UiState {
        return jobAbilitySelectMenu.getOrPut(type) { makeAbilityMenuInternal(type) }
    }

    private fun makeAbilityMenuInternal(type: AbilityType): UiState {
        return UiState(
            additionalDraw = { AbilitySelectUi.draw(type, it) },
            focusMenu = "menu    magic   ",
            menuStacks = MenuStacks.LogStack,
            appendType = AppendType.StackAndAppend,
            resetCursorIndexOnPush = false,
            scrollSettings = ScrollSettings(numElementsInPage = 12) { AbilitySelectUi.getItems(type).size },
        ) { cursorPos ->
            if (keyPressed(Keyboard.Key.ENTER)) {
                val itemIndex = currentItemIndex()

                val subTypeMenu = AbilitySelectUi.getSubAbilityMenuType(type, itemIndex)
                if (subTypeMenu != null) {
                    pushState(makeAbilityMenu(subTypeMenu), SystemSound.MenuSelect)
                } else {
                    pendingAction = PendingAction(
                        targetFilter = AbilitySelectUi.getSelectedAbility(type, itemIndex).getTargetFilter(),
                        execute = { AbilitySelectUi.useSelectedAbility(type, itemIndex, ActorManager.player().subTarget!!) }
                    )
                    pushState(subTargetContext, SystemSound.MenuSelect)
                }

                true
            } else if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }
    }

}