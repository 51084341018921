package xim.poc.ui

import xim.poc.gl.Color
import xim.math.Vector2f
import xim.math.Vector3f
import xim.poc.*
import xim.poc.game.PartyManager
import xim.poc.game.UiStateHelper

object PartyUi {

    fun draw() {
        val playerActor = ActorManager.player()

        val party = PartyManager[playerActor]
        val partyMembers = party.getAll()

        val menu = when (partyMembers.size) {
            1 -> "menu    ptw0    "
            else -> "menu    ptw${partyMembers.size}    "
        }

        var cursorIndex = -1
        for (member in partyMembers) {
            val targeted = if (UiStateHelper.isSubTargetMode()) {
                playerActor.subTarget == member.id
            } else {
                playerActor.target == member.id
            }

            if (targeted) { cursorIndex = party.getIndex(member.id) ?: -1 }
        }

        val menuPos = UiElementHelper.drawMenu(menu, cursorIndex = cursorIndex, menuStacks = MenuStacks.PartyStack) ?: return
        val offset = Vector2f().copyFrom(menuPos)
        offset.y += 2f

        for (member in partyMembers) {
            drawMemberStatus(member, offset)
            offset.y += 20f
        }

        val pet = ActorManager[playerActor.getPetId()]
        if (pet != null) {
            offset.x -= 110f; offset.y -= 20f
            drawMemberStatus(pet, offset)
        }

        val targetId = if (UiStateHelper.isSubTargetMode()) { playerActor.subTarget } else { playerActor.target }
        val targetActor = ActorManager[targetId]
        if (targetActor != null) {
            val targetPos = UiElementHelper.drawMenu(menuName = "menu    targetwi", menuStacks = MenuStacks.PartyStack) ?: return
            UiElementHelper.drawString(text = targetActor.name, offset = targetPos + Vector2f(8f, 10f), Font.FontShp)

            if (!targetActor.isStaticNpc()) {
                val barScale = targetActor.hp.toFloat() / targetActor.maxHp.toFloat()
                UiElementHelper.drawUiElement(lookup = "menu    windowps", index = 97, position = Vector2f(28f, 26f) + targetPos, scale = Vector2f(barScale, 1f))
                UiElementHelper.drawUiElement(lookup = "menu    windowps", index = 38, position = Vector2f(25f, 26f) + targetPos)
            }
        }

        ActorManager[playerActor.target]?.let { drawTargetPointer(it) }

        if (playerActor.targetLocked && !UiStateHelper.isSubTargetMode()) {
            UiElementHelper.drawMenu(menuName = "menu    targetlo", menuStacks = MenuStacks.PartyStack, appendType = AppendType.None)
        }
    }

    fun drawTargetPointer(actor: Actor, subTarget: Boolean = false) {
        val cursorPos = Vector3f().copyFrom(actor.position)
        cursorPos.y += actor.actorModel?.getSkeleton()?.resource?.size?.y ?: 0f
        val screenSpaceCursorPos = cursorPos.toScreenSpace()

        if (screenSpaceCursorPos != null) {
            screenSpaceCursorPos.x *= MainTool.platformDependencies.screenSettingsSupplier.width
            screenSpaceCursorPos.y *= MainTool.platformDependencies.screenSettingsSupplier.height
            UiElementHelper.drawUiElement(
                lookup = "anc     anc_l   ",
                index = UiElementHelper.currentCursorIndex(6),
                position = Vector2f(screenSpaceCursorPos.x, screenSpaceCursorPos.y),
                scale = UiElementHelper.globalUiScale,
                color = if (subTarget) { Color(r = 128, g = 128, b = 255, a = 255) } else { Color.NO_MASK },
                disableGlobalScale = true)
        }
    }

    private fun drawMemberStatus(actor: Actor, offset: Vector2f) {
        val hpScale = actor.hp.toFloat() / actor.maxHp.toFloat()
        val mpScale = actor.mp.toFloat() / actor.maxMp.toFloat()

        UiElementHelper.drawUiElement(lookup = "menu    windowps", index = 98, position = Vector2f(51f, 21f) + offset, scale = Vector2f(mpScale, 1f))
        UiElementHelper.drawUiElement(lookup = "menu    windowps", index = 39, position = Vector2f(48f, 21f) + offset)

        UiElementHelper.drawUiElement(lookup = "menu    windowps", index = 97, position = Vector2f(28f, 12f) + offset, scale = Vector2f(hpScale, 1f))
        UiElementHelper.drawUiElement(lookup = "menu    windowps", index = 38, position = Vector2f(25f, 12f) + offset)

        UiElementHelper.drawString(text = actor.name, offset = offset + Vector2f(4f, 4f), Font.FontShp)

        UiElementHelper.drawString(text = "${actor.mp}", offset = offset + Vector2f(98f, 16f), font = Font.FontShp, color = Color.ALPHA_75, alignment = TextAlignment.Right)
        UiElementHelper.drawString(text = "${actor.hp}", offset = offset + Vector2f(88f, 6f), font = Font.FontShp, alignment = TextAlignment.Right)
    }

}