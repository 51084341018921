package xim.resource.table

import xim.poc.browser.DatLoader
import xim.resource.ByteReader

val MonsterAbilityNameTable = BasicStringTable("ROM/27/80.DAT", 0x80.toByte())

data class MobSkillInfo(val id: Int, val animationId: Int, val targetFlag: Int)

// https://raw.githubusercontent.com/LandSandBoat/server/base/sql/mob_skills.sql
object MobSkillInfoTable: TableResource {

    private const val fileTableOffset = 0xF3C

    private lateinit var animInfos: Map<Int, MobSkillInfo>
    private var preloaded = false

    override fun preload() {
        if (preloaded) { return }
        preloaded = true
        loadTable()
    }

    override fun isFullyLoaded() : Boolean {
        return this::animInfos.isInitialized
    }

    operator fun get(skillId: Int): MobSkillInfo? {
        return animInfos[skillId]
    }

    fun getAnimationPath(info: MobSkillInfo): String? {
        val fileTableIndex = fileTableOffset + info.animationId
        return FileTableManager.getFilePath(fileTableIndex)
    }

    private fun loadTable() {
        DatLoader.load("landsandboat/MobSkillsTable.DAT").onReady { parseTable(it.getAsBytes()) }
    }

    private fun parseTable(byteReader: ByteReader) {
        val skills = HashMap<Int, MobSkillInfo>()
        while (byteReader.hasMore()) {
            val skill = MobSkillInfo(id = byteReader.next16(), animationId = byteReader.next16(), targetFlag = byteReader.next16())
            skills[skill.id] = skill
        }
        this.animInfos = skills
    }

}
