package xim.resource.table

object ZoneIdToResourceId {

    fun map(zoneId: Int): Int {
        if (zoneId == 819) {
            return 356 // Temp hack for Jeuno Mog House
        }

        return if (zoneId < 0x100) {
            0x64 + zoneId
        } else {
            0x147B3 + (zoneId - 0x100)
        }
    }
}

val ZoneNameTable = StringTable(resourceName = "ROM/165/84.DAT", bitMask = -1)