package xim.poc.gl

import web.gl.GLint
import web.gl.WebGL2RenderingContext.Companion.ANY_SAMPLES_PASSED
import web.gl.WebGL2RenderingContext.Companion.QUERY_RESULT
import web.gl.WebGL2RenderingContext.Companion.QUERY_RESULT_AVAILABLE
import web.gl.WebGLQuery
import xim.resource.Particle

private class Query(val query: WebGLQuery, val previousValue: Int?, var value: Int? = null)


object OcclusionManager {

    private val webgl by lazy { GlDisplay.getContext() }

    private val queries = HashMap<Long, Query>()

    fun executeQuery(particle: Particle, fn: () -> Unit) {
        val query = webgl.createQuery() ?: return

        webgl.beginQuery(ANY_SAMPLES_PASSED, query)
        fn.invoke()
        webgl.endQuery(ANY_SAMPLES_PASSED)

        val previous = queries[key(particle)]
        queries[key(particle)] = Query(query, previous?.value)
    }

    fun consumeQuery(particle: Particle): Int? {
        val query = queries[key(particle)] ?: return null

        val available = webgl.getQueryParameter(query.query, QUERY_RESULT_AVAILABLE) as Boolean?
        if (available != true) { return query.previousValue }

        val result = webgl.getQueryParameter(query.query, QUERY_RESULT) as GLint
        webgl.deleteQuery(query.query)

        query.value = result
        return result
    }

    private fun key(particle: Particle): Long {
        return particle.internalId
    }

}