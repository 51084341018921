package xim.poc

import xim.math.Vector3f
import xim.poc.game.AttackContext
import xim.poc.gl.PointLight
import xim.resource.*
import xim.util.Fps.secondsToFrames
import xim.util.interpolate

sealed interface EffectAssociation {
    fun copy(): EffectAssociation { return this }
}

class ZoneAssociation(val area: Area): EffectAssociation {
    override fun equals(other: Any?): Boolean {
        if (other !is ZoneAssociation) { return false }
        return area.resourceId == other.area.resourceId
    }

    override fun hashCode(): Int {
        return area.resourceId.hashCode()
    }
}

data class WeatherAssociation(val datId: DatId) : EffectAssociation

class ActorAssociation(val actor: Actor, val context: ActorContext): EffectAssociation {

    override fun copy(): EffectAssociation {
        return ActorAssociation(actor, context.copy())
    }

    override fun equals(other: Any?): Boolean {
        if (other !is ActorAssociation) { return false }
        return actor.id == other.actor.id
    }

    override fun hashCode(): Int {
        return actor.id.hashCode()
    }
}


data class ActorContext(
    val originalActor: ActorId,
    val targetActor: ActorId = originalActor,
    val additionalTargets: List<ActorId> = emptyList(),
    val joint: JointInstance? = null,
    val terrainEffect: Boolean = false,
    val modelSlot: ItemModelSlot? = null,
    val attackContext: AttackContext = AttackContext.noop(),
) {

    private data class ActorSnapshot(val position: Vector3f, val facing: Float)

    private var snapshotPositions = false
    private val positionSnapshots = HashMap<ActorId, ActorSnapshot>()

    private var snapshotJoints = false
    private val jointSnapshots = HashMap<Actor, HashMap<Int, Vector3f>>()

    fun cloneWithOverrideTarget(targetActor: ActorId): ActorContext {
        return this.copy(targetActor = targetActor)
    }

    fun applyJointSnapshot(state: Boolean) {
        snapshotJoints = state
    }

    fun getJointPosition(actor: Actor, standardJointIndex: Int): Vector3f {
        val actorJointLock = jointSnapshots.getOrPut(actor) { HashMap() }

        return if (snapshotJoints) {
            actorJointLock.getOrPut(standardJointIndex) { actor.getJointPosition(standardJointIndex) }
        } else {
            actorJointLock.getOrElse(standardJointIndex) { actor.getJointPosition(standardJointIndex) }
        }
    }

    fun applyPositionSnapshot() {
        snapshotPositions = true
    }

    fun getActorPosition(actorId: ActorId): Vector3f? {
        return getActorSnapshot(actorId)?.position
    }

    fun getActorFacingDir(actorId: ActorId): Float? {
        return getActorSnapshot(actorId)?.facing
    }

    private fun getActorSnapshot(actorId: ActorId): ActorSnapshot? {
        val actor = ActorManager[actorId] ?: return null
        return if (snapshotPositions) {
            positionSnapshots.getOrPut(actorId) { ActorSnapshot(actor.position.copy(), actor.facingDir) }
        } else {
            ActorSnapshot(actor.position, actor.facingDir)
        }
    }


}

class ParticleLight(val particle: Particle, val pointLight: PointLight)

class EffectLighting(private val particles: Map<DatId, List<ParticleLight>>) {
    fun getPointLights(area: Area, datId: DatId, characterMode: Boolean) : List<PointLight> {
        val pointLightParticles = particles[datId] ?: return emptyList()

        return pointLightParticles
            .filter { !it.particle.isCharacterLight() || (it.particle.isCharacterLight() && characterMode) }
            .filter { if(it.particle.association is ZoneAssociation) { it.particle.association.area == area } else { true } }
            .map { it.pointLight }
    }
}

class ParticleContext(val particle: Particle, val opacity: Float)

class FadeParameters(private val opacityStart: Float, private val opacityEnd: Float, private val duration: Float, val removeOnComplete: Boolean) {

    companion object {
        val noFade = FadeParameters(opacityStart = 1f, opacityEnd = 1f, duration = 0f, removeOnComplete = false)

        fun defaultFadeIn(): FadeParameters {
            return FadeParameters(opacityStart = 0.0f, opacityEnd = 1.0f, duration = secondsToFrames(3.33f), removeOnComplete = false)
        }

        fun defaultFadeOut(): FadeParameters {
            return FadeParameters(opacityStart = 1.0f, opacityEnd = 0.0f, duration = secondsToFrames(3.33f), removeOnComplete = true)
        }

        fun fadeIn(duration: Float): FadeParameters {
            return FadeParameters(opacityStart = 0.0f, opacityEnd = 1.0f, duration = duration, removeOnComplete = false)
        }

        fun fadeOut(duration: Float): FadeParameters {
            return FadeParameters(opacityStart = 1.0f, opacityEnd = 0.0f, duration = duration, removeOnComplete = true)
        }
    }

    private var counter = 0f

    fun update(elapsedFrames: Float): Boolean {
        val complete = isComplete()
        counter += elapsedFrames
        return !complete && isComplete()
    }

    fun shouldRemove(): Boolean {
        return removeOnComplete && isComplete()
    }

    fun isComplete(): Boolean {
        return counter >= duration
    }

    fun getOpacity(): Float {
        if (duration == 0f) { return opacityEnd }
        return opacityStart.interpolate(opacityEnd, counter / duration).coerceIn(0f, 1f)
    }

}

private class EffectRoutines {

    val effectRoutines: MutableList<EffectRoutineInstance> = ArrayList()
    var fadeParameters: FadeParameters = FadeParameters.noFade

    fun update(elapsedFrames: Float) {
        effectRoutines.forEach { it.update(elapsedFrames) }

        effectRoutines.filter { it.isComplete() }.forEach { it.onComplete?.onComplete() }
        effectRoutines.removeAll { it.isComplete() }

        fadeParameters.update(elapsedFrames)
        if (fadeParameters.shouldRemove()) {
            effectRoutines.clear()
        }
    }
}

object EffectManager {

    private val routines: HashMap<EffectAssociation, EffectRoutines> = HashMap()

    fun update(elapsedFrames: Float) {
        routines.values.forEach { it.update(elapsedFrames) }
        routines.entries.removeAll { it.value.effectRoutines.isEmpty() }
    }

    fun getLightingParticles(): EffectLighting {
        val lightingParticles = getAllParticles()
            .filter { it.particle.isPointLight() }
            .map { ParticleLight(it.particle, it.particle.getPointLightParams()) }
            .groupBy { it.particle.datId }

        return EffectLighting(lightingParticles)
    }

    fun getAllParticles() : List<ParticleContext> {
        return routines.entries.map { entry ->
            val particles = entry.value.effectRoutines.map { it.getParticles() }.flatten()
            val allParticles = particles + particles.flatMap { it.getChildrenRecursively() }
            allParticles.map { ParticleContext(it, opacity = entry.value.fadeParameters.getOpacity()) }
        }.flatten()
    }

    fun registerActorRoutine(actor: Actor, actorContext: ActorContext, effectRoutineResource: EffectRoutineResource) : EffectRoutineInstance {
        return registerRoutine(ActorAssociation(actor, actorContext), effectRoutineResource)
    }

    fun applyFadeParameter(effectAssociation: EffectAssociation, fadeParameters: FadeParameters) {
        routines[effectAssociation]?.fadeParameters = fadeParameters
    }

    fun clearEffects(effectAssociation: EffectAssociation) {
        routines[effectAssociation]?.effectRoutines?.clear()
    }

    fun clearAllEffects() {
        routines.values.forEach { e -> e.effectRoutines.forEach { it.stop() } }
        routines.clear()
    }

    fun getFirstEffectForAssociation(effectAssociation: EffectAssociation, id: DatId): DatResource? {
        val associationRoutines = routines[effectAssociation]?.effectRoutines ?: return null
        return associationRoutines.firstOrNull { it.id == id }?.initialRoutine
    }

    fun forEachEffectForAssociation(effectAssociation: EffectAssociation, predicate: (EffectRoutineInstance) -> Unit) {
        routines[effectAssociation]?.effectRoutines?.forEach { predicate.invoke(it) }
    }

    fun removeEffectsForAssociation(effectAssociation: EffectAssociation, predicate: (EffectRoutineInstance) -> Boolean) {
        routines[effectAssociation]?.effectRoutines?.removeAll { predicate.invoke(it) }
    }

    fun registerEffect(effectAssociation: EffectAssociation, effectResource: EffectResource) : EffectRoutineInstance {
        val effectRoutineInstance = EffectRoutineInstance.fromSingleton(effectResource, effectAssociation)
        return registerRoutine(effectAssociation, effectRoutineInstance)
    }

    fun registerRoutine(effectAssociation: EffectAssociation, effectRoutineResource: EffectRoutineResource) : EffectRoutineInstance {
        val effectRoutineInstance = EffectRoutineInstance.fromResource(effectRoutineResource, effectAssociation)
        return registerRoutine(effectAssociation, effectRoutineInstance)
    }

    private fun registerRoutine(effectAssociation: EffectAssociation, effectRoutineInstance: EffectRoutineInstance): EffectRoutineInstance {
        val associated = routines.getOrPut(effectAssociation) { EffectRoutines() }
        associated.effectRoutines.add(effectRoutineInstance)
        return effectRoutineInstance
    }


}