package xim.poc.ui

import xim.math.Vector2f
import xim.poc.*
import xim.poc.game.BattleEngine
import xim.poc.game.UiState
import xim.poc.gl.Color
import xim.resource.MagicType
import xim.resource.SpellElement
import xim.resource.SpellInfo
import xim.resource.table.SpellInfoTable
import xim.resource.table.SpellNameTable
import kotlin.time.DurationUnit
import kotlin.time.toDuration

data class SpellItem(val spellIndex: Int) {

    fun getTargetFilter() : (Actor, Actor) -> Boolean {
        val info = SpellInfoTable[spellIndex]
        return ActionTargetFilter(info.targetFlags)::targetFilter
    }

}


object SpellSelectUi {

    private const val iconSet1 = "menu    magiconw"
    private const val iconSet2 = "menu    magico2w"

    private val spellItems: List<SpellItem> by lazy {
        (1 until 1024)
            .filter { SpellInfoTable.hasInfo(it) }
            .filter { SpellNameTable.first(it) != "." }
            .filter { SpellNameTable.first(it) != "jet-stream-attack" }
            .filter { SpellNameTable.first(it) != "dummy" }
            .map { SpellItem(it) }
    }

    fun getSpellItems(magicType: MagicType): List<SpellItem> {
        if (magicType == MagicType.None) { return spellItems }
        return spellItems.filter { SpellInfoTable[it.spellIndex].magicType == magicType }
    }

    fun drawSpells(uiState: UiState, magicType: MagicType) {
        val stackPos = uiState.latestPosition ?: return
        val offset = Vector2f(0f, 0f)

        val player = ActorManager.player()

        val scrollSettings = uiState.scrollSettings!!
        val items = getSpellItems(magicType)

        for (i in scrollSettings.lowestViewableItemIndex until scrollSettings.lowestViewableItemIndex + scrollSettings.numElementsInPage) {
            if (i >= items.size) { break }
            val spell = items[i]

            val spellName = SpellNameTable.first(spell.spellIndex)
            val spellInfo = SpellInfoTable[spell.spellIndex]

            val (iconSet, iconId) = getSpellIcon(spellInfo)

            val textColor = if (BattleEngine.canCastSpell(player, spellInfo)) { Color.NO_MASK } else { Color.GREY }

            UiElementHelper.drawUiElement(lookup = iconSet, index = iconId, position = offset + stackPos + Vector2f(16f, 4f))
            UiElementHelper.drawString(text = spellName, offset = offset + stackPos + Vector2f(36f, 8f), color = textColor)
            offset.y += 16f
        }
    }

    fun drawRecast(index: Int, magicType: MagicType) {
        val spell = getSelectedSpell(index, magicType)
        val spellInfo = SpellInfoTable[spell.spellIndex]

        val infoWindowPos = UiElementHelper.drawMenu(menuName = "menu    subwindo", menuStacks = MenuStacks.PartyStack) ?: return

        UiElementHelper.drawString("MP Cost", offset = infoWindowPos + Vector2f(8f, 8f), font = Font.FontShp)
        UiElementHelper.drawString(spellInfo.mpCost.toString(), offset = infoWindowPos + Vector2f(106f, 8f), font = Font.FontShp, alignment = TextAlignment.Right)

        val recastTime = ActorManager.player().getRecastDelay(spellInfo)?.getRemaining() ?: 0.toDuration(DurationUnit.SECONDS)

        val recastMinutes = recastTime.inWholeMinutes
        val recastSeconds = (recastTime.inWholeSeconds % 60).toString().padStart(2, '0')

        UiElementHelper.drawString("Next", offset = infoWindowPos + Vector2f(8f, 24f), font = Font.FontShp)
        UiElementHelper.drawString("${recastMinutes}:${recastSeconds}", offset = infoWindowPos + Vector2f(106f, 24f), font = Font.FontShp, alignment = TextAlignment.Right)
    }

    fun getSelectedSpell(index: Int, magicType: MagicType): SpellItem {
        return getSpellItems(magicType)[index]
    }

    fun castSelectedSpell(index: Int, target: ActorId, magicType: MagicType) {
        ActorManager[target] ?: return
        val spellItem = getSpellItems(magicType).getOrNull(index) ?: return

        val player = ActorManager.player()
        val spellInfo = SpellInfoTable[spellItem.spellIndex]
        BattleEngine.startCasting(player, target, spellInfo)
    }

    private fun getSpellIcon(spellInfo: SpellInfo): Pair<String, Int> {
        if (spellInfo.iconId in 67..676) {
            return Pair(iconSet1, spellInfo.iconId)
        } else if (spellInfo.iconId >= 1000) {
            return Pair(iconSet2, spellInfo.iconId - 1000)
        }

        val iconBase = when (spellInfo.magicType) {
            MagicType.WhiteMagic -> 0
            MagicType.BlackMagic -> 8
            MagicType.Summoning -> 16
            MagicType.Ninjutsu -> 24
            MagicType.Songs -> 32
            MagicType.BlueMagic -> 56
            else -> 0
        }

        val iconOffset = when (spellInfo.element) {
            SpellElement.None -> 8
            else -> spellInfo.element.index
        }

        return Pair(iconSet1, iconBase + iconOffset)
    }

}