package xim.poc.tools

import kotlinx.browser.window
import web.url.URLSearchParams
import xim.math.Vector3f


object StartupTool {

    private val params by lazy { URLSearchParams(window.location.search) }

    fun getStartingZoneId(): Int? {
        return params["zoneId"]?.toIntOrNull()
    }

    fun getStartingPositionOverride(): Vector3f? {
        val x = params["x"]?.toFloatOrNull() ?: return null
        val y = params["y"]?.toFloatOrNull() ?: return null
        val z = params["z"]?.toFloatOrNull() ?: return null

        return Vector3f(x, y, z)
    }

}