package xim.poc.game

import xim.poc.Actor
import xim.poc.ActorContext
import xim.poc.EffectManager
import xim.resource.DatId
import xim.poc.browser.DatLoader
import xim.resource.EffectRoutineResource
import xim.resource.table.FileTableManager
import xim.util.OnceLogger

object MiscEffects {

    enum class Effect(val id: Int) {
        LevelDown(0xCED),
        LevelUp(0xCEE),
        NewChallenge(0xE86),
        JobPoint(0xE90),
        MasterLevelUp(0xEE8),
        MasterLevelDown(0xEE9),
    }

    fun playEffect(actor: Actor, effect: Effect) {
        playEffectMain(actor, effect.id)
    }

    private fun playEffectMain(actor: Actor, fileTableIndex: Int) {
        val path = FileTableManager.getFilePath(fileTableIndex) ?: return
        playEffectMain(actor, path)
    }

    private fun playEffectMain(actor: Actor, resourceName: String) {
        DatLoader.load(resourceName).onReady {
            val resource = it.getAsResource().getNullableChildRecursivelyAs(DatId.main, EffectRoutineResource::class)
            if (resource != null) {
                EffectManager.registerActorRoutine(actor, ActorContext(actor.id), resource)
            } else {
                OnceLogger.warn("Couldn't find [main] in $resourceName")
            }

        }
    }

}