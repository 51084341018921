package xim.poc.ui

import xim.math.Vector2f
import xim.poc.ActorManager
import xim.poc.Font
import xim.poc.TextAlignment
import xim.poc.UiElementHelper
import xim.poc.game.UiState

object StatusWindowUi {

    private const val lineSpacing = 12f

    fun draw(uiState: UiState) {
        val player = ActorManager.player()

        val statusFramePosition = uiState.latestPosition!!
        populateStatusWindow(statusFramePosition)

        // Level
        val statusFrameSize = uiState.latestMenu!!.frame.size
        val levelFrameOffset = statusFramePosition + Vector2f(0f, statusFrameSize.y + 2f)
        val levelFramePosition = UiElementHelper.drawMenu(menuName = "menu    level   ", offsetOverride = levelFrameOffset) ?: return

        val levelOffset = Vector2f(6f, 8f)

        UiElementHelper.drawString("Level 99", offset = levelFramePosition + levelOffset, Font.FontShp)
        levelOffset.y += lineSpacing

        UiElementHelper.drawString("EXP", offset = levelFramePosition + levelOffset + Vector2f(x = 6f), Font.FontShp)
        levelOffset.y += lineSpacing

        UiElementHelper.drawString("${player.currentExp}", offset = levelFramePosition + levelOffset + Vector2f(x = 80f), Font.FontShp, alignment = TextAlignment.Right)
        levelOffset.y += lineSpacing

        UiElementHelper.drawString("NEXT Level", offset = levelFramePosition + levelOffset + Vector2f(x = 6f), Font.FontShp)
        levelOffset.y += lineSpacing

        UiElementHelper.drawString("${player.nextLevelExp}", offset = levelFramePosition + levelOffset + Vector2f(x = 80f), Font.FontShp, alignment = TextAlignment.Right)
        levelOffset.y += lineSpacing + 1f

        // Exp bar
        val expScale = player.currentExp.toFloat() / player.nextLevelExp.toFloat()
        UiElementHelper.drawUiElement(lookup = "menu    windowps", index = 97, position = levelFramePosition + levelOffset + Vector2f(x = 14f), scale = Vector2f(expScale, 1f))
        UiElementHelper.drawUiElement(lookup = "menu    windowps", index = 38, position = levelFramePosition + levelOffset + Vector2f(x = 14f))
    }

    fun populateStatusWindow(statusFramePosition: Vector2f) {
        val player = ActorManager.player()
        val textOffset = statusFramePosition + Vector2f(4f, 8f)

        UiElementHelper.drawString(player.name, offset = textOffset, Font.FontShp)

        UiElementHelper.drawString("Lv99", offset = textOffset + Vector2f(0f, lineSpacing), Font.FontShp)
        UiElementHelper.drawUiElement("menu    windowps", index = 228, position = textOffset + Vector2f(34f, lineSpacing))

        UiElementHelper.drawString("Lv49", offset = textOffset + Vector2f(0f, lineSpacing*2), Font.FontShp)
        UiElementHelper.drawUiElement("menu    windowps", index = 215, position = textOffset + Vector2f(34f, lineSpacing*2))

        UiElementHelper.drawString("ILv", offset = textOffset + Vector2f(0f, lineSpacing*3), Font.FontShp)
        UiElementHelper.drawString("119/Su 5", offset = textOffset + Vector2f(32f, lineSpacing*3), Font.FontShp)

        val statOffset = textOffset + Vector2f(60f, lineSpacing * 4 + 2f)
        val statSpacing = Vector2f()

        // HP
        UiElementHelper.drawString("${player.hp}/${player.maxHp}", offset = statOffset + statSpacing, Font.FontShp, alignment = TextAlignment.Center)
        statSpacing.y += lineSpacing

        // MP
        UiElementHelper.drawString("${player.mp}/${player.maxMp}", offset = statOffset + statSpacing, Font.FontShp, alignment = TextAlignment.Center)
        statSpacing.y += lineSpacing

        // TP
        UiElementHelper.drawString("0", offset = statOffset + statSpacing, Font.FontShp, alignment = TextAlignment.Center)
        statSpacing.y += lineSpacing + 1f

        val stats = listOf(
            5, // STR
            5, // DEX
            5, // VIT
            5, // AGI
            5, // INT
            5, // MND
            5, // CHR
        )

        for (stat in stats) {
            UiElementHelper.drawString(stat.toString(), offset = statOffset + statSpacing, Font.FontShp, alignment = TextAlignment.Center)
            statSpacing.y += 13f
        }
    }

}