package xim.poc.game

import xim.poc.game.configuration.zones.EastSarutabaruta

data class ZoneDefinition(val monsterSpawnerDefinitions: List<MonsterSpawnerDefinition>)

object ZoneDefinitionManager {

    private val zones = mapOf(
        EastSarutabaruta.zoneId to EastSarutabaruta.zoneDefinition,
    )

    operator fun get(zoneId: Int): ZoneDefinition? {
        return zones[zoneId]
    }

}

class ZoneInstance(val zoneDefinition: ZoneDefinition) {

    private val spawners = zoneDefinition.monsterSpawnerDefinitions.map { MonsterSpawnerInstance(it) }

    fun update(elapsed: Float) {
        spawners.forEach { it.update(elapsed) }
    }

}
