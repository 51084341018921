package xim.resource

import xim.math.Vector3f

data class ZoneInteraction (val position: Vector3f, val orientation: Vector3f, val size: Vector3f, val sourceId: DatId, val destId: DatId?, val param: Int) {

    fun isZoneLine(): Boolean {
        return sourceId.id.first() == 'z' && destId != null
    }

    fun isZoneEntrance(): Boolean {
        return sourceId.id.first() == 'z' && destId == null
    }

    fun isFishingArea(): Boolean {
        return sourceId.id.first() == 'f'
    }

    fun isSubArea(): Boolean {
        return sourceId.id.first() == 'm'
    }

    fun isDoor(): Boolean {
        return sourceId.id.first() == '_'
    }

}

class ZoneInteractionSection(val sectionHeader: SectionHeader) : ResourceParser {

    override fun getResource(byteReader: ByteReader): ParserResult {
        val interactions = read(byteReader)
        return ParserResult.from(ZoneInteractionResource(sectionHeader.sectionId, interactions))
    }

    private fun read(byteReader: ByteReader): List<ZoneInteraction> {
        byteReader.offsetFromDataStart(sectionHeader)

        val magic = byteReader.nextString(0x4)
        if (!magic.startsWith("RID")) { oops(byteReader) }

        val unk0 = byteReader.next32() // 2,4,5,6
        byteReader.position += 0x08

        val dataOffset = byteReader.next32()
        byteReader.offsetFromDataStart(sectionHeader, dataOffset)

        val numEntries = byteReader.next32()
        expectZero32(byteReader)
        expectZero32(byteReader)
        expectZero32(byteReader)

        val interactions = ArrayList<ZoneInteraction>(numEntries)

        for (i in 0 until numEntries) {
            val position = byteReader.nextVector3f()
            val orientation = byteReader.nextVector3f()
            val size = byteReader.nextVector3f()

            val sourceEventId = byteReader.nextDatId()
            val destEventId = byteReader.nextDatId().toNullIfZero()
            val param = byteReader.next32()

            // Haven't investigated
            byteReader.next32()
            byteReader.next32()
            byteReader.next32()
            byteReader.next32()

            interactions += ZoneInteraction(
                position = position,
                orientation = orientation,
                size = size,
                sourceId = sourceEventId,
                destId = destEventId,
                param = param,
            )
        }

        return interactions
    }

}