package xim.poc.game

import xim.math.Matrix4f
import xim.math.Vector3f
import xim.poc.*
import xim.poc.audio.AudioManager
import xim.poc.browser.DatLoader
import xim.poc.gl.ByteColor
import xim.poc.ui.ChatLog
import xim.poc.ui.ShiftJis
import xim.resource.*
import xim.resource.table.*
import xim.util.OnceLogger
import kotlin.random.Random

object BattleEngine {

    private val actorStates = HashMap<ActorId, BattleState>()

    fun tick(elapsedFrames: Float) {
        val allActors = ActorManager.getAll()
        allActors.mapNotNull { ActorManager[it] }.forEach { updateActor(it, elapsedFrames) }
    }

    fun clear() {
        actorStates.clear()
    }

    private fun updateActor(actor: Actor, elapsedFrames: Float) {
        val battleState = getOrCreateBattleState(actor)
        battleState.update(elapsedFrames)

        val expiredStatusEffects = actor.updateStatusEffects(elapsedFrames)

        val bubbleExpired = expiredStatusEffects.any { it.info.id == StatusEffect.Indicolure.id }
        if (bubbleExpired) { releaseBubble(actor) }

        if (actor.isPlayer()) {
            updateBgm(actor)
        }

        if ((actor.enemy || actor.isDependent()) && actor.isDead() && actor.timeSinceDeath().inWholeSeconds >= 10) {
            onActorFadeAway(actor)
        }

        val target = ActorManager[actor.target] ?: return

        if (target.isDead() && actor.isEngaged()) {
            onDisengage(actor)
        }

        if (actor.isDead()) { return }

        if (battleState.canAutoAttack()) {
            onAttack(actor, battleState)
        }

    }

    private fun updateBgm(actor: Actor) {
        val zoneSettings = ZoneSettingsTable[MainTool.zoneConfig.zoneId]

        if (actor.isDead()) {
            AudioManager.playBgm(musicId = 111, resume = false)
        } else if (actor.isEngagedOrEngaging()) {
            val partySize = PartyManager[actor].getAll().size
            val musicId = if (partySize > 1) { zoneSettings.battlePartyMusicId } else { zoneSettings.battleSoloMusicId }
            AudioManager.playBgm(musicId)
        } else if (actor.getMount() != null) {
            val mount = actor.getMount()!!
            val musicId = if (mount.index == 0) { 212 } else { 84 }
            AudioManager.playBgm(musicId)
        } else {
            AudioManager.playBgm(zoneSettings.musicId)
        }
    }

    private fun getOrCreateBattleState(actor: Actor): BattleState {
        return actorStates.getOrPut(actor.id) { BattleState(actor) }
    }

    private fun inflictDamage(actor: Actor, target: Actor, amount: Int): EffectCallback {
        if (target.isDead()) { return EffectCallback.noop }

        target.hp -= amount
        target.hp = target.hp.coerceAtLeast(0)

        target.interruptCasting()
        target.stopResting()

        if (!target.isDead()) {
            aggroPet(actor, target)
            aggroTrusts(actor, target)
        }

        if (target.enemy && !target.isEngagedOrEngaging()) {
            onEngage(target, actor.id)
        } else if (!target.enemy && target.target == null) {
            target.target = actor.id
        }

        return EffectCallback { finalizeDamage(actor, target, amount) }
    }

    private fun finalizeDamage(actor: Actor, target: Actor, amount: Int) {
        ChatLog.addLine("${actor.name}${ShiftJis.rightArrow}${target.name} $amount damage!")

        if (target.hp > 0) { return }
        onDeath(target)

        if (!target.enemy) { return }

        PartyManager[actor].getAll()
            .filter { !it.isDead() && !it.isDependent() }
            .forEach {
                if (it.isPlayer()) { ChatLog.addLine("Gained 10 EXP!") }
                val levelUp = it.gainExp(10)
                if (levelUp) { MiscEffects.playEffect(it, MiscEffects.Effect.LevelUp) }
            }
    }

    private fun onDeath(actor: Actor) {
        if (actor.isPlayer()) { UiStateHelper.clear() }

        onDisengage(actor)
        releaseDependents(actor)
        actor.enqueueModelRoutineIfReady(DatId("dead"))
    }

    private fun heal(actor: Actor, target: Actor, amount: Int): EffectCallback {
        target.hp += amount
        target.hp = target.hp.coerceAtMost(target.maxHp)

        return EffectCallback { finalizeHeal(actor, target, amount) }
    }

    private fun finalizeHeal(actor: Actor, target: Actor, amount: Int) {
        ChatLog.addLine("${actor.name}${ShiftJis.rightArrow}${target.name} $amount healed!")
    }

    private fun gainStatus(actor: Actor, target: Actor, statusId: Int, durationInSeconds: Int? = null): EffectCallback {
        target.gainStatusEffect(statusId, durationInSeconds)
        return EffectCallback.noop
    }

    fun onPlayerEngage() {
        val playerActor = ActorManager.player()
        val target = playerActor.target ?: return
        onEngage(playerActor, target)
    }

    private fun onEngage(actor: Actor, target: ActorId) {
        dismount(actor)
        actor.startEngage(target)

        if (actor.enemy) {
            actor.transitionToIdle(7.5f)
            actor.playSoundPrefixed("idl")
        }

        enqueueWeaponRoutine(actor, DatId("!w00"))
        enqueueSubWeaponRoutine(actor, DatId("!w10"))

        val mainWeaponInfo = actor.actorModel?.model?.getMainWeaponInfo()
        if (mainWeaponInfo != null) {
            enqueueMainBattleRoutine(actor, DatId("in ${mainWeaponInfo.weaponAnimationSubType}")) {
                actor.engage()
            }
        } else {
            actor.engage()
        }

        enqueueWeaponRoutine(actor, DatId("!w01"))
        enqueueSubWeaponRoutine(actor, DatId("!w11"))
    }

    fun onPlayerDisengage() = onDisengage(ActorManager.player())

    private fun onDisengage(actor: Actor) {
        if (!actor.isEngaged()) { return }

        enqueueWeaponRoutine(actor, DatId("!w02"))
        enqueueSubWeaponRoutine(actor, DatId("!w12"))

        if (actor.isDead()) {
            actor.disengage()
            return
        }

        actor.startDisengage()

        val mainWeaponInfo = actor.actorModel?.model?.getMainWeaponInfo()
        if (mainWeaponInfo != null) {
            enqueueMainBattleRoutine(actor, DatId("out${mainWeaponInfo.weaponAnimationSubType}")) {
                actor.disengage()
                UiStateHelper.clear()
            }
        } else {
            actor.disengage()
            UiStateHelper.clear()
        }
    }

    private fun onAttack(actor: Actor, battleState: BattleState) {
        battleState.resetAutoAttack()
        if (!actor.isEngaged()) { return }

        val actorModel = actor.actorModel!!
        val target = ActorManager[actor.target] ?: return
        if (target.isDead()) { return }

        onAttackMainHand(actor, actorModel, target, battleState)
        if (actor.isDualWield()) { onAttackSubHand(actor, actorModel, target, battleState) }
        if (actor.isHandToHand()) { onAttackH2H(actor, actorModel, target, battleState) }
    }

    private fun onAttackMainHand(actor: Actor, actorModel: ActorModel, target: Actor, battleState: BattleState) {
        val attackContext = AttackContext.from(actor, target) { inflictDamage(actor, target, battleState.getMainHandDamage()).invoke() }

        val routine = when(actor.getMovementDirection()) {
            Direction.None -> actorModel.model.getMainAttackIds()?.randomOrNull() ?: DatId("ati0")
            Direction.Forward -> DatId("atf0")
            Direction.Left -> DatId("atl0")
            Direction.Right -> DatId("atr0")
            Direction.Backward -> DatId("atb0")
        }

        enqueueMainBattleRoutine(actor, DatId("atk0"))
        enqueueMainBattleRoutine(actor, routine, attackContext)
    }

    private fun onAttackSubHand(actor: Actor, actorModel: ActorModel, target: Actor, battleState: BattleState) {
        val attackContext = AttackContext.from(actor, target) { inflictDamage(actor, target, battleState.getSubHandDamage()).invoke() }

        enqueueMainBattleRoutine(actor, DatId("atk0"))

        actor.enqueueRoutine(ActorContext(actor.id, actor.target ?: actor.id, attackContext = attackContext)) {
            val battleDir = actorModel.model.getSubBattleAnimationDirectory()

            val subRoutine = when(actor.getMovementDirection()) {
                Direction.None -> actorModel.model.getSubAttackIds()?.randomOrNull() ?: DatId("bti0")
                Direction.Forward -> DatId("btf0")
                Direction.Left -> DatId("btl0")
                Direction.Right -> DatId("btr0")
                Direction.Backward -> DatId("btb0")
            }

            if (battleDir != null) {
                ProvidedRoutine(battleDir.getNullableChildRecursivelyAs(subRoutine, EffectRoutineResource::class), options = RoutineOptions(blocking = false))
            } else {
                null
            }
        }
    }

    private fun onAttackH2H(actor: Actor, actorModel: ActorModel, target: Actor, battleState: BattleState) {
        val attackContext = AttackContext.from(actor, target) { inflictDamage(actor, target, battleState.getMainHandDamage()).invoke() }

        // Left-punch, Right-kick, Left-kick
        val subAttack = listOf(DatId("bti0"), DatId("cti0"), DatId("dti0")).random()

        enqueueMainBattleRoutine(actor, DatId("atk0"))
        actor.enqueueRoutine(ActorContext(actor.id, actor.target ?: actor.id, attackContext = attackContext)) {
            val battleDir = actorModel.model.getMainBattleAnimationDirectory()
            if (battleDir != null) {
                ProvidedRoutine(battleDir.getNullableChildRecursivelyAs(subAttack, EffectRoutineResource::class), options = RoutineOptions(blocking = false))
            } else {
                null
            }
        }
    }

    private fun enqueueMainBattleRoutine(actor: Actor, datId: DatId, attackContext: AttackContext = AttackContext.noop(), callback: (() -> Unit)? = null) {
        actor.enqueueRoutine(ActorContext(actor.id, actor.target ?: actor.id, attackContext = attackContext)) {
            val battleDir = actor.actorModel?.model?.getMainBattleAnimationDirectory()
            if (battleDir != null) {
                ProvidedRoutine(battleDir.getNullableChildRecursivelyAs(datId, EffectRoutineResource::class), options = RoutineOptions(blocking = false)) { callback?.invoke() }
            } else {
                null
            }
        }
    }

    private fun enqueueWeaponRoutine(actor: Actor, datId: DatId) {
        actor.enqueueRoutine(ActorContext(actor.id, actor.target ?: actor.id, modelSlot = ItemModelSlot.Main)) {
            val model = actor.actorModel?.model
            val weapon = model?.getEquipmentModelResource(ItemModelSlot.Main)
            if (weapon != null) {
                val resource = weapon.getNullableChildRecursivelyAs(datId, EffectRoutineResource::class)
                ProvidedRoutine(resource, options = RoutineOptions(blocking = false))
            } else {
                null
            }
        }
    }

    private fun enqueueSubWeaponRoutine(actor: Actor, datId: DatId) {
        actor.enqueueRoutine(ActorContext(actor.id, actor.target ?: actor.id, modelSlot = ItemModelSlot.Sub)) {
            val model = actor.actorModel?.model
            val weapon = model?.getEquipmentModelResource(ItemModelSlot.Sub)
            if (weapon != null) {
                val resource = weapon.getNullableChildRecursivelyAs(datId, EffectRoutineResource::class)
                ProvidedRoutine(resource, options = RoutineOptions(blocking = false))
            } else {
                null
            }
        }
    }

    private fun onActorFadeAway(actor: Actor) {
        if (actor.hasEnqueuedRoutines()) { return }
        actor.playRoutine(DatId.disappear) { ActorManager.remove(actor.id) }
    }

    private fun roll(actor: Actor, abilityInfo: AbilityInfo): AttackContext {
        if (!actor.hasStatusEffect(StatusEffect.DoubleUp.id)) {
            actor.gainStatusEffect(StatusEffect.FightersRoll.id)
            val doubleUpChance = actor.gainStatusEffect(StatusEffect.DoubleUp.id)
            doubleUpChance.linkedAbilityId = abilityInfo.index
            doubleUpChance.linkedStatusId = StatusEffect.FightersRoll.id // todo: work for others
        }

        return doubleUp(actor)!!
    }

    private fun doubleUp(actor: Actor): AttackContext? {
        val doubleUpStatus = actor.getStatusEffect(StatusEffect.DoubleUp.id) ?: return null
        val linkedStatus = actor.getStatusEffect(doubleUpStatus.linkedStatusId) ?: return null

        var rollValue = 1 + Random.nextInt(0, 6)

        // For testing, always hit 11 before busting
        if (linkedStatus.counter < 11 && linkedStatus.counter + rollValue > 11) {
            rollValue = 11 - linkedStatus.counter
        }

        linkedStatus.counter = (linkedStatus.counter + rollValue).coerceIn(1, 12)

        val callback = if (linkedStatus.counter < 12) {
            EffectCallback { ChatLog.addLine("Roll Value: ${linkedStatus.counter}") }
        } else {
            actor.removeStatusEffect(StatusEffect.DoubleUp.id)
            actor.removeStatusEffect(doubleUpStatus.linkedStatusId)
            actor.gainStatusEffect(StatusEffect.Bust.id)
            EffectCallback { ChatLog.addLine("Roll Value: bust!") }
        }

        return AttackContext.fromRoll(rollValue = rollValue, rollSum = linkedStatus.counter, effectCallback = callback)
    }

    fun startCasting(caster: Actor, target: ActorId, spellInfo: SpellInfo) {
        caster.startCasting(spellInfo, target)
    }

    fun startRangedAttack(caster: Actor, target: ActorId) {
        caster.startRangedAttack(target)
    }

    fun castSpell(spellInfo: SpellInfo, source: ActorId, target: ActorId) {
        val animationId = SpellAnimationTable[spellInfo]
        val datPath = FileTableManager.getFilePath(animationId) ?: throw IllegalStateException("Spell has no resource? $spellInfo")

        val sourceActor = ActorManager[source] ?: return
        val targetActor = ActorManager[target] ?: return

        val spellName = SpellNameTable.first(spellInfo.index)

        val callback = when (spellName) {
            "Fire" -> inflictDamage(sourceActor, targetActor, 3)
            "Blizzard" -> inflictDamage(sourceActor, targetActor, 999)
            "Cure" -> heal(sourceActor, targetActor, 3)
            "Cure II" -> heal(sourceActor, targetActor, 20)
            "Self-Destruct" -> inflictDamage(sourceActor, sourceActor, 999)

            "Enfire" -> gainStatus(sourceActor, targetActor, StatusEffect.Enfire.id, durationInSeconds = 30)
            "Enblizzard" -> gainStatus(sourceActor, targetActor, StatusEffect.Enblizzard.id, durationInSeconds = 30)

            "Blaze Spikes" -> gainStatus(sourceActor, targetActor, StatusEffect.BlazeSpikes.id, durationInSeconds = 30)
            "Ice Spikes" -> gainStatus(sourceActor, targetActor, StatusEffect.IceSpikes.id, durationInSeconds = 30)
            else -> EffectCallback.noop
        }

        if (spellInfo.magicType == MagicType.Summoning) {
            gainSummonedPet(sourceActor, spellInfo)
        } else if (spellInfo.magicType == MagicType.Trust)  {
            gainTrust(sourceActor, spellInfo)
        } else if (spellInfo.magicType == MagicType.Geomancy && spellName.startsWith("Geo")) {
            gainLuopan(sourceActor, targetActor, spellInfo)
        } else if (spellInfo.magicType == MagicType.Geomancy && spellName.startsWith("Indi")) {
            gainBubble(sourceActor, spellInfo)
        }

        castSpell(datPath = datPath, sourceId = source, targetId = target, attackContext = AttackContext(effectCallback = callback))
    }

    fun useAbility(abilityInfo: AbilityInfo, sourceId: ActorId, targetId: ActorId, effectCallback: EffectCallback = EffectCallback{} ) {
        val source = ActorManager[sourceId] ?: return
        val model = source.actorModel?.model as PcModel

        val animationId = if (abilityInfo.index == AbilityIds.DoubleUp.id) {
            val linkedAbility = source.getStatusEffect(StatusEffect.DoubleUp.id)?.linkedAbilityId ?: return
            AbilityTable.getAnimationId(AbilityInfoTable[linkedAbility], model.raceGenderConfig)
        } else {
            AbilityTable.getAnimationId(abilityInfo, model.raceGenderConfig)
        }

        if (animationId == null) {
            OnceLogger.warn("No animation for: ${AbilityNameTable.first(abilityInfo.index)}")
            return
        }

        val attackContext = if (abilityInfo.type == AbilityType.PhantomRoll) {
            roll(source, abilityInfo)
        } else if (abilityInfo.index == AbilityIds.DoubleUp.id) {
            doubleUp(source) ?: AttackContext.noop()
        } else if (abilityInfo.type == AbilityType.PetWard || abilityInfo.type == AbilityType.PetAbility) {
            val petCallback = getPetCallback(source, targetId, abilityInfo)
            AttackContext(effectCallback = petCallback)
        } else if (abilityInfo.index == AbilityIds.Release.id){
            releasePet(source)
            AttackContext(effectCallback = effectCallback)
        } else if (abilityInfo.index == AbilityIds.Assault.id) {
            engagePet(source, targetId)
            AttackContext(effectCallback = effectCallback)
        } else if (abilityInfo.index == AbilityIds.Retreat.id) {
            disengagePet(source)
            AttackContext(effectCallback = effectCallback)
        } else if (abilityInfo.index == AbilityIds.CallWyvern.id) {
            gainWyvern(source)
            AttackContext(effectCallback = effectCallback)
        } else {
            AttackContext(effectCallback = effectCallback)
        }

        val datPath = FileTableManager.getFilePath(animationId) ?: throw IllegalStateException("Ability has no resource? ${abilityInfo.index}")
        castSpell(datPath = datPath, sourceId = sourceId, targetId = targetId, attackContext = attackContext)
    }

    fun useItem(inventoryItemInfo: InventoryItemInfo, sourceId: ActorId, targetId: ActorId) {
        val source = ActorManager[sourceId]
        val animPath = ItemAnimationTable.getAnimationPath(inventoryItemInfo)

        if (animPath == null) {
            source?.transitionToIdle(10f)
            return
        }

        val attackContext = AttackContext.noop()
        castSpell(datPath = animPath, sourceId = sourceId, targetId = targetId, attackContext = attackContext)
    }

    fun canCastSpell(actor: Actor, spellInfo: SpellInfo): Boolean {
        val recastDelay = actor.getRecastDelay(spellInfo)
        if (recastDelay != null && !recastDelay.isComplete()) { return false }

        if (spellInfo.mpCost > actor.mp) { return false }

        if (spellInfo.magicType == MagicType.Trust) {
            val actorParty = PartyManager[actor]
            if (actorParty.size() >= 6) { return false }
        }

        return true
    }

    fun castSpell(datPath: String, sourceId: ActorId, targetId: ActorId, debug: Boolean = false, attackContext: AttackContext) {
        DatLoader.load(datPath).onReady { castSpell(sourceId, targetId, it.getAsResource(), attackContext, debug) }
    }

    fun callMount(source: Actor, index: Int) {
        if (!source.isFullyOutOfCombat()) { return }

        val oldMount = dismount(source)
        if (oldMount?.index == index) { return }

        val fileTableIndex = 0x019131 + index
        val resourcePath = FileTableManager.getFilePath(fileTableIndex) ?: return

        val model = NpcModel(resourcePath = resourcePath)

        val mountActorId = ActorManager.nextId()
        val mountActor = Actor(id = mountActorId, name = "", position = Vector3f().copyFrom(source.position), actorController = MountController(source.id), actorModel = ActorModel(model))
            .also { it.isMount = true }

        ActorManager.add(mountActor)
        source.setMount(Mount(index = index, resource = model.resource, id = mountActorId, owner = source.id))

        mountActor.onReadyToDraw {
            it.transitionToIdle(0f)
            it.facingDir = source.facingDir
        }
    }

    fun dismount(source: Actor): Mount? {
        val current = source.getMount() ?: return null
        source.setMount(null)
        source.transitionToIdle(0f)

        val currentActor = ActorManager[current.id] ?: return null
        currentActor.isMount = false

        currentActor.playRoutine(DatId("coff")) { ActorManager.remove(current.id) }
        return current
    }

    private fun castSpell(sourceId: ActorId, targetId: ActorId, dat: DirectoryResource, attackContext: AttackContext, debug: Boolean) {
        val spellMain = dat.getNullableChildRecursivelyAs(DatId.main, EffectRoutineResource::class) ?: throw IllegalStateException("[${dat.id}] Couldn't find [main]")

        val source = ActorManager[sourceId] ?: return
        val target = ActorManager[targetId] ?: return

        val additionalTargets = if (false) {
            if (target.id != ActorManager.player().id) { listOf(ActorManager.player().id) } else { emptyList() }
        } else {
            emptyList()
        }

        val context = ActorContext(originalActor = source.id, targetActor = target.id, additionalTargets = additionalTargets, attackContext = attackContext)
        source.enqueueRoutine(context) { ProvidedRoutine(spellMain) }
    }

    private fun gainTrust(actor: Actor, spellInfo: SpellInfo) {
        val look = TrustTable.getModelFileTableIndex(spellInfo) ?: return
        val resource = FileTableManager.getFilePath(look) ?: return

        val party = PartyManager.getOrCreate(actor)
        if (party.size() >= 6) { return }

        val name = SpellNameTable[spellInfo.index].first()

        val position = Vector3f().copyFrom(actor.position)
        position += Matrix4f().rotateYInPlace(actor.facingDir).transformInPlace(Vector3f(2f, 0f, 0f))

        val trust = Actor(id = ActorManager.nextId(), name = name, position = position, actorModel = ActorModel(NpcModel(resource)), actorController = TrustController(), owner = actor.id)
        trust.renderState.effectColor = ByteColor.zero

        trust.onReadyToDraw {
            it.transitionToIdle(0f)
            it.playRoutine(DatId("spop"))
        }

        ActorManager.add(trust)
        party.addMember(trust.id)
    }

    private fun gainLuopan(actor: Actor, target: Actor, spellInfo: SpellInfo) {
        val offenseOffset = if (target.enemy) { 0x8 } else { 0x0 }
        val lookId = 0xCF99 + spellInfo.element.index + offenseOffset
        gainPet(actor, lookId, "Luopan", entranceAnimation = DatId.pop, stationary = true)
    }

    private fun gainSummonedPet(actor: Actor, spellInfo: SpellInfo) {
        val name = SpellNameTable[spellInfo.index].first()

        val spellId = spellInfo.spellId

        // Basic approximation - the Wyvern's look is 725, so the newer summons are off by 1
        // Also, Water/Lighting Spirit are flipped
        val look = 0x51C + when {
            spellId == 713 -> 5
            spellId == 714 -> 4
            spellId < 725 -> spellId - 709
            else -> spellId - 708
        }

        gainPet(actor, look, name)
    }

    private fun gainWyvern(actor: Actor) {
        gainPet(actor, lookId = 0x51C + 0x10, name = "Wyvern")
    }

    private fun gainPet(actor: Actor, lookId: Int, name: String, entranceAnimation: DatId = DatId("spop"), stationary: Boolean = false) {
        releasePet(actor)

        val resource = FileTableManager.getFilePath(lookId) ?: return

        val position = Vector3f().copyFrom(actor.position)
        position += Matrix4f().rotateYInPlace(actor.facingDir).transformInPlace(Vector3f(2f, 0f, 0f))

        val controller = if (stationary) { NoOpActorController() } else { PetController(actor.id) }

        val pet = Actor(id = ActorManager.nextId(), name = name, position = position, actorModel = ActorModel(NpcModel(resource)), actorController = controller, owner = actor.id)
        pet.renderState.effectColor = ByteColor.zero

        pet.onReadyToDraw {
            it.transitionToIdle(0f)
            it.playRoutine(entranceAnimation)
        }

        ActorManager.add(pet)
        actor.setPet(pet.id)
    }

    private fun releasePet(actor: Actor) {
        val current = ActorManager[actor.getPetId()] ?: return
        actor.removePet()

        current.hp = 0
        current.enqueueModelRoutineIfReady(DatId("sdep"))
    }

    private fun engagePet(actor: Actor, targetId: ActorId) {
        val current = ActorManager[actor.getPetId()] ?: return
        ActorManager[targetId] ?: return

        current.startEngage(targetId)
        current.engage()
    }

    private fun aggroPet(actor: Actor, target: Actor) {
        val current = ActorManager[actor.getPetId()] ?: return
        if (!current.isFullyOutOfCombat()) { return }
        engagePet(actor, target.id)
    }

    private fun disengagePet(actor: Actor) {
        val current = ActorManager[actor.getPetId()] ?: return
        current.startDisengage()
        current.disengage()
    }

    private fun getPetCallback(actor: Actor, target: ActorId, abilityInfo: AbilityInfo): EffectCallback {
        val current = ActorManager[actor.getPetId()] ?: return EffectCallback.noop
        val abilityAnimationId = PetSkillTable.getAnimationId(abilityInfo) ?: return EffectCallback.noop
        val path = FileTableManager.getFilePath(abilityAnimationId) ?: return EffectCallback.noop

        return EffectCallback {
            castSpell(datPath = path, sourceId = current.id, targetId = target, attackContext = AttackContext.noop())
        }
    }

    fun releaseTrust(actor: Actor, targetId: ActorId?) {
        val target = ActorManager[targetId] ?: return
        if (target.owner != actor.id) { return }

        val party = PartyManager[actor]
        party.removeMember(target.id)

        target.hp = 0
        target.enqueueModelRoutineIfReady(DatId("sdep"))
    }

    private fun releaseTrusts(actor: Actor) {
        PartyManager[actor].getAll()
            .filter { it.owner == actor.id }
            .onEach { releaseTrust(actor, it.id) }
    }

    private fun aggroTrusts(actor: Actor, target: Actor) {
        PartyManager[actor].getAll()
            .filter { it.owner == actor.id }
            .forEach {
                it.startEngage(target.id)
                it.engage()
            }
    }

    private fun gainBubble(actor: Actor, spellInfo: SpellInfo) {
        releaseBubble(actor)
        gainStatus(actor, actor, StatusEffect.Indicolure.id, durationInSeconds = 120)

        val offenseOffset = if (Random.nextBoolean()) { 0x8 } else { 0x0 } // TODO: split Indi spells
        val lookId = 0xCBF3 + spellInfo.element.index + offenseOffset
        val resource = FileTableManager.getFilePath(lookId) ?: return

        val position = Vector3f().copyFrom(actor.position)
        val bubble = Actor(id = ActorManager.nextId(), name = "(Bubble)", position = position, actorModel = ActorModel(NpcModel(resource)), actorController = NoOpActorController(), owner = actor.id)
        bubble.renderState.effectColor = ByteColor.zero
        bubble.isBubble = true

        bubble.onReadyToDraw {
            it.transitionToIdle(0f)
            it.playRoutine(DatId.pop)
        }

        ActorManager.add(bubble)
        actor.bubbleState = BubbleState(bubble.id)
    }

    private fun releaseBubble(actor: Actor) {
        val bubble = ActorManager[actor.bubbleState?.id]
        actor.bubbleState = null

        if (bubble != null) {
            bubble.hp = 0
            bubble.enqueueModelRoutineIfReady(DatId("sdep"))
        }

        val bubbleBuff = actor.getStatusEffects().firstOrNull { it.info.id == StatusEffect.Indicolure.id } ?: return
        actor.removeStatusEffect(bubbleBuff.info.id)
    }

    fun releaseDependents(actor: Actor) {
        releaseTrusts(actor)
        releaseBubble(actor)
        releasePet(actor)
    }

}