package xim.poc

import kotlinx.serialization.Serializable
import xim.math.Vector3f
import xim.poc.audio.AudioManager
import xim.poc.browser.DatLoader
import xim.poc.browser.LocalStorage
import xim.poc.game.BattleEngine
import xim.poc.gl.ByteColor
import xim.poc.tools.ZoneChanger
import xim.resource.*
import xim.resource.table.*
import xim.util.Fps.framesToSeconds
import xim.util.Fps.framesToSecondsRoundedUp
import xim.util.Fps.secondsToFrames
import xim.util.OnceLogger.warn
import kotlin.math.*
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds

data class ActorId(val id: Int)

enum class EngageState {
    NotEngaged,
    Engaged,
    Engaging,
    Disengaging,
}

enum class Direction {
    None,
    Forward,
    Left,
    Right,
    Backward,
}

@Serializable
class Equipment {
    val items: HashMap<EquipSlot, Int> = HashMap()
    fun copyFrom(equipment: Equipment) {
        items.clear()
        items.putAll(equipment.items)
    }
}

class ProvidedRoutine(val routine: EffectRoutineResource?, val options: RoutineOptions = RoutineOptions(), val callback: RoutineCompleteCallback? = null)

class RoutineOptions(val blocking: Boolean = true)

fun interface RoutineProvider {
    fun getIfReady(): ProvidedRoutine?
}

fun interface ReadyToDrawAction {
    fun invoke(actor: Actor)
}

private class QueuedRoutine(
    val context: ActorContext,
    val routineProvider: RoutineProvider,
)

class RenderState {
    var effectColor: ByteColor = ByteColor.half
    val wrapEffect = ActorWrapEffect()

    fun reset () {
        effectColor = ByteColor.half
        wrapEffect.reset()
    }

}

class CastingState(val castTime: Float, val sourceId: ActorId, val targetId: ActorId, val spellInfo: SpellInfo?, val itemInfo: InventoryItemInfo?, val rangedAttack: Boolean) {

    var currentTime = 0f
    var interrupted = false

    private val targetFilter: ActionTargetFilter

    init {
        if (spellInfo != null && itemInfo != null && !rangedAttack) { throw IllegalStateException() }
        targetFilter = ActionTargetFilter(spellInfo?.targetFlags ?: itemInfo?.targetFlags ?: TargetFlag.Enemy.flag)
    }

    fun update(elapsedFrames: Float) {
        if (isComplete()) { return }
        currentTime += elapsedFrames
        if (!isTargetValid()) { interrupted = true }
    }

    fun percentProgress(): Int {
        return (currentTime/castTime * 100f).toInt()
    }

    fun isComplete(): Boolean {
        return interrupted || currentTime >= castTime
    }

    fun getInterruptAnimation(): DatId? {
        return if (rangedAttack) {
            DatId("splg")
        } else if (spellInfo != null) {
            DatId.stopCastId(spellInfo)
        } else if (itemInfo != null) {
            DatId("spit")
        } else {
            throw IllegalStateException()
        }
    }

    private fun isTargetValid(): Boolean {
        val source = ActorManager[sourceId] ?: return false
        val target = ActorManager[targetId] ?: return false
        return targetFilter.targetFilter(source, target)
    }

}

class StatusEffectState(val info: StatusEffectInfo) {

    var remainingDuration: Float? = null
    var counter = 0
    var linkedStatusId = 0
    var linkedAbilityId = 0

}

class RecastState(private var timeRemainingInFrames: Float) {

    fun update(elapsedFrames: Float) {
        timeRemainingInFrames -= elapsedFrames
    }

    fun getRemaining(): Duration {
        return framesToSecondsRoundedUp(timeRemainingInFrames)
    }

    fun isComplete(): Boolean {
        return timeRemainingInFrames <= 0f
    }

}

class DoorState {
    var open = false
}

class Actor(
    val id: ActorId,
    val name: String,
    val position: Vector3f,
    val actorController: ActorController = NoOpActorController(),
    var facingDir: Float = 0f,

    var actorModel: ActorModel? = null,
    var enemy: Boolean = false,
    val owner: ActorId? = null,
) {

    var hp = 20
    val maxHp = 20

    var mp = 999
    val maxMp = 999

    var currentExp = 0
    val nextLevelExp = 11

    private val statusEffects = ArrayList<StatusEffectState>()

    private var restingTransition = false
    private var resting = false
    private var timeSpentResting = 0f

    private var synthesisState: SynthesisStateMachine? = null

    private var turnAmount: Float? = null

    private val readyToDrawActions = ArrayList<ReadyToDrawAction>()
    private val routineQueue = ArrayList<QueuedRoutine>()
    private var currentRoutine: EffectRoutineInstance? = null

    private val equipment = Equipment()

    val currentVelocity: Vector3f = Vector3f()
    var stoppedMoving = false

    var target: ActorId? = null
    var subTarget: ActorId? = null

    private var engageState = EngageState.NotEngaged
    var targetLocked = false

    val renderState = RenderState()

    var castingState: CastingState? = null
    private val recastState = HashMap<Int, RecastState>()

    private var framesSinceDeath = 0f

    private var pet: ActorId? = null

    var isMount = false
    private var mount: Mount? = null

    var isBubble = false
    var bubbleState: BubbleState? = null

    private val boundingBoxRef = BoundingBoxRef()

    private val skeletonBoundingBox = ArrayList<BoundingBox>()

    private val doorState = DoorState()

    init {
        onReadyToDraw { startAutoRunParticles() }
    }

    fun getMovementSpeed(): Float {
        return if (mount != null) { 15f } else { 7.5f } / secondsToFrames(1)
    }

    fun setDestinationFacingDir(direction: Vector3f) {
        val horizontal = direction.withY(0f)
        if (horizontal.magnitudeSquare() < 0.001f) { return }

        val dir = horizontal.normalize()
        setDestinationFacingDir(-atan2(dir.z, dir.x))
    }

    private fun setDestinationFacingDir(dest: Float) {
        turnAmount = dest - facingDir
        if (abs(turnAmount!!) > PI ) {
            turnAmount = if (sign(turnAmount!!) == -1f ) {
                turnAmount!! + 2 * PI.toFloat()
            } else {
                turnAmount!! - 2 * PI.toFloat()
            }
        }
    }

    fun update(elapsedFrames: Float) {
        if (readyToDrawActions.isNotEmpty() && isReadyToDraw()) {
            readyToDrawActions.forEach { it.invoke(this) }
            readyToDrawActions.clear()
        }

        updateRoutine(elapsedFrames)
        updateRecastState(elapsedFrames)

        if (isDead()) {
            interruptCasting()
            updateCastingState(elapsedFrames)

            framesSinceDeath += elapsedFrames
            if (id == ActorManager.player().id && timeSinceDeath() >= 60.seconds) { ZoneChanger.returnToHomePoint(restore = true) }

            currentVelocity.copyFrom(Vector3f.ZERO)
            return
        }
        framesSinceDeath = 0f

        if (isSynthesizing()) {
            val current = synthesisState!!
            current.update(elapsedFrames)
            if (current.isComplete()) { synthesisState = null }
        }

        if (isResting()) {
            restingTick(elapsedFrames)
        }

        if (targetLocked) { updateDestinationFacingDir() }

        val newVelocity = actorController.getVelocity(this, elapsedFrames)
        if (isPlayer()) {
            if (isSynthesizing()) {
                newVelocity.copyFrom(Vector3f.ZERO)
            }

            if (isResting() && newVelocity.magnitudeSquare() > 0f) {
                stopResting()
                newVelocity.copyFrom(Vector3f.ZERO)
            }

            if (ZoneChanger.isChangingZones()) {
                newVelocity.copyFrom(Vector3f.ZERO)
            }
        }

        stoppedMoving = currentVelocity.magnitudeSquare() > 0f && newVelocity.magnitudeSquare() == 0f
        currentVelocity.copyFrom(newVelocity)
        updateFacingDir(elapsedFrames)

        val currentCastingState = castingState
        if (currentCastingState != null) {
            updateCastingState(elapsedFrames)
            return
        }
    }

    fun getBoundingBox(): BoundingBox {
        if (boundingBoxRef.referencePosition != position) {
            boundingBoxRef.boundingBox = BoundingBox.scaled(Scene.collisionBoxSize, Vector3f().copyFrom(position))
            boundingBoxRef.referencePosition.copyFrom(position)
        }

        return boundingBoxRef.boundingBox
    }

    fun getSkeletonBoundingBox(index: Int = 0): BoundingBox? {
        return skeletonBoundingBox.getOrNull(index)
    }

    fun updateSkeletonBoundingBoxes(box: List<BoundingBox>) {
        skeletonBoundingBox.clear()
        skeletonBoundingBox.addAll(box)
    }

    fun isPlayer(): Boolean {
        return id == ActorManager.player().id
    }

    fun getNpcInfo(): NpcInfo? {
        return NpcTable[id.id]
    }

    fun isHiddenNpc(): Boolean {
        val info = getNpcInfo() ?: return false
        return info.status == 0x02 || info.status == 0x06
    }

    fun isDead() = hp <= 0

    fun startRangedAttack(targetId: ActorId) {
        val current = castingState
        if (current != null && !current.isComplete()) { return }

        castingState = CastingState(120f, id, targetId = targetId, spellInfo = null, itemInfo = null, rangedAttack = true)
        enqueueModelRoutine(DatId("calg"))
    }

    fun startCasting(spellInfo: SpellInfo, targetId: ActorId) {
        val current = castingState
        if (current != null && !current.isComplete()) { return }

        castingState = CastingState(spellInfo.castTimeInFrames(), id, targetId = targetId, spellInfo = spellInfo, itemInfo = null, rangedAttack = false)
        val animationId = DatId.castId(spellInfo) ?: return

        enqueueModelRoutine(animationId)
    }

    fun startUsingItem(inventoryItemInfo: InventoryItemInfo, targetId: ActorId) {
        val current = castingState
        if (current != null && !current.isComplete()) { return }

        val castTime = inventoryItemInfo.usableItemInfo.castTimeInFrames()

        castingState = CastingState(castTime, id, targetId = targetId, spellInfo = null, itemInfo = inventoryItemInfo, rangedAttack = false)
        enqueueModelRoutine(DatId.castId(inventoryItemInfo))
    }

    fun isCasting(): Boolean {
        return castingState?.isComplete() == false
    }

    fun interruptCasting() {
        castingState?.interrupted = true
    }

    fun getRecastDelay(spellInfo: SpellInfo): RecastState? {
        return recastState[spellInfo.index]
    }

    fun resetRenderState() {
        renderState.reset()

        currentRoutine = null
        routineQueue.clear()

        actorModel?.clearAnimations()
    }

    private fun updateRecastState(elapsedFrames: Float) {
        recastState.forEach { it.value.update(elapsedFrames) }
        recastState.entries.removeAll { it.value.isComplete() }
    }

    private fun updateCastingState(elapsedFrames: Float) {
        val currentCastingState = castingState ?: return

        if (currentVelocity.magnitudeSquare() > 0f) {
            currentCastingState.interrupted = true
        }

        if (currentCastingState.isComplete()) {
            onCompletedCasting()
            castingState = null
        } else {
            currentCastingState.update(elapsedFrames)
        }
    }

    private fun onCompletedCasting() {
        val current = castingState ?: return

        if (current.interrupted) {
            val animId = current.getInterruptAnimation() ?: return
            val resource = GlobalDirectory.directoryResource.getNullableChildRecursivelyAs(animId, EffectRoutineResource::class) ?: return
            EffectManager.registerActorRoutine(this, ActorContext(id), resource)
            return
        }

        if (current.spellInfo != null) {
            val spellInfo = current.spellInfo
            if (spellInfo.mpCost > mp) { return }

            mp -= spellInfo.mpCost
            recastState[spellInfo.index] = RecastState(spellInfo.recastDelayInFrames())
            BattleEngine.castSpell(spellInfo, id, current.targetId)
        } else if (current.rangedAttack) {
            enqueueModelRoutine(DatId("shlg"), ActorContext(id, current.targetId))
        } else if (current.itemInfo != null) {
            BattleEngine.useItem(current.itemInfo, id, current.targetId)
        }
    }

    fun timeSinceDeath(): Duration {
        return framesToSeconds(framesSinceDeath)
    }

    fun transitionToIdle(transitionTime: Float) {
        actorModel?.forceTransitionToIdle(idleId = getIdleAnimationId(), transitionTime = transitionTime, animationDirs = getAllAnimationDirectories())
    }

    fun getIdleAnimationId(): DatId {
        if (isMount) { return DatId("chi?") }

        val mountDef = mount?.getInfo()
        if (mountDef != null) { return DatId("${mountDef.poseType}un?") }

        return if (!isFullyOutOfCombat()) { DatId("btl?") } else { DatId("idl?") }
    }

    fun canEngage(): Boolean {
        return !isDead() && !isResting() && !isSynthesizing()
    }

    fun isSynthesizing(): Boolean {
        return synthesisState != null
    }

    fun startSynthesizing(crystalType: SynthesisType, result: DatId) {
        if (isDead() || isResting()) { return }
        if (!isSynthesizing()) {
            synthesisState = SynthesisStateMachine(this, crystalType, result)
        }
    }

    fun toggleResting() {
        if (isDead() || isSynthesizing()) { return }
        if (resting) { stopResting() } else { startResting() }
    }

    fun isResting(): Boolean {
        return resting
    }

    fun startResting() {
        if (!isFullyOutOfCombat() || actorModel!!.isAnimationLocked() || actorModel!!.isMovementLocked()) { return }

        if (restingTransition) { return }
        restingTransition = true
        resting = true

        interruptCasting()

        enqueueModelRoutine(DatId.startResting) { restingTransition = false; timeSpentResting = 0f }
        enqueueModelRoutine(DatId.resting)
    }

    fun stopResting() {
        if (restingTransition || !resting) { return }
        restingTransition = true

        enqueueModelRoutine(DatId.stopResting) { resting = false; restingTransition = false; timeSpentResting = 0f }
    }

    private fun restingTick(elapsedFrames: Float) {
        timeSpentResting += elapsedFrames

        val tickSize = secondsToFrames(1)
        if (timeSpentResting >= tickSize) {
            timeSpentResting -= tickSize
            hp += (maxHp * 0.05).roundToInt().coerceAtLeast(1)
            hp = hp.coerceAtMost(maxHp)

            mp += (maxMp * 0.05).roundToInt().coerceAtLeast(1)
            mp = mp.coerceAtMost(maxMp)
        }
    }

    fun startEngage(targetId: ActorId) {
        target = targetId
        engageState = EngageState.Engaging
        targetLocked = true
    }

    fun engage() {
        engageState = EngageState.Engaged
    }

    fun startDisengage() {
        engageState = EngageState.Disengaging
    }

    fun disengage() {
        target = null
        engageState = EngageState.NotEngaged
        targetLocked = false
    }

    fun onRevive() {
        hp = maxHp
        mp = maxMp
        actorModel?.clearAnimations()
        transitionToIdle(0f)
    }

    fun gainExp(amount: Int): Boolean {
        currentExp += amount

        if (currentExp >= nextLevelExp) {
            currentExp -= nextLevelExp
            return true
        }

        return false
    }

    fun isFullyOutOfCombat(): Boolean {
        return engageState == EngageState.NotEngaged
    }

    fun isEngaged(): Boolean {
        return engageState == EngageState.Engaged
    }

    fun isEngagedOrEngaging(): Boolean {
        return engageState == EngageState.Engaged || engageState == EngageState.Engaging
    }

    fun getAllAnimationDirectories() : List<DirectoryResource> {
        val model = actorModel?.model ?: return emptyList()

        if (isFullyOutOfCombat()) { return model.getAnimationDirectories() }

        val main = model.getMainBattleAnimationDirectory()
        val sub = model.getSubBattleAnimationDirectory()

        return listOfNotNull(main, sub) + model.getAnimationDirectories()
    }

    fun isReadyToDraw() : Boolean {
        val model = actorModel?.model ?: return false
        if (isFullyOutOfCombat()) { return model.isReadyToDraw() }

        if (model.getMainBattleAnimationDirectory() == null) {
            return false
        }

        val dualWield = isDualWield()
        if (dualWield && model.getSubBattleAnimationDirectory() == null) {
            return false
        }

        return true
    }

    fun enqueueRoutine(context: ActorContext, routineProvider: RoutineProvider) {
        routineQueue += QueuedRoutine(context, routineProvider)
    }

    fun hasEnqueuedRoutines(): Boolean {
        return currentRoutine != null || routineQueue.isNotEmpty()
    }

    fun getTargetDirectionVector(): Vector3f? {
        val targetActor = ActorManager[target] ?: return null
        return (targetActor.position - position).also { it.y = 0f }.normalizeInPlace()
    }

    fun getMovementDirection(): Direction {
        if (!targetLocked) { return Direction.None }
        val targetDirection = getTargetDirectionVector()!!

        val movementVelocity = Vector3f().copyFrom(currentVelocity).also { it.y = 0f }
        if (movementVelocity.magnitudeSquare() <= 1e-5) {
            return Direction.None
        }

        val normalizedVelocity = movementVelocity.normalizeInPlace()
        val cosAngle = normalizedVelocity.dot(targetDirection)

        // Prefer to run forward > horizontal > backward
        return if (cosAngle >= 0.25f ) {
            return Direction.Forward
        } else if (cosAngle >= -0.75f) {
            val right = targetDirection.cross(Vector3f.UP).normalizeInPlace()
            val horizontalCosAngle = normalizedVelocity.dot(right)
            if (horizontalCosAngle >= 0f) {
                Direction.Right
            } else {
                Direction.Left
            }
        } else {
            Direction.Backward
        }
    }

    fun getMovementAnimation(): DatId {
        return when (getMovementDirection()) {
            Direction.None, Direction.Forward -> DatId("run?")
            Direction.Left -> DatId("mvl?")
            Direction.Right -> DatId("mvr?")
            Direction.Backward -> DatId("mvb?")
        }
    }

    fun getJointPosition(index: Int): Vector3f {
        return actorModel?.getJointPosition(index) ?: Vector3f.ZERO
    }

    fun getWorldSpaceJointPosition(index: Int): Vector3f {
        return position + getJointPosition(index)
    }

    private fun updateFacingDir(elapsedFrames: Float) {
        val turn = turnAmount ?: return

        val stepAmount = sign(turn) * elapsedFrames * PI.toFloat() / 20f
        if (abs(turn) < abs(stepAmount) ) {
            facingDir += turn
            turnAmount = null
            return
        }

        facingDir += stepAmount
        turnAmount = turnAmount!! - stepAmount

        if (facingDir < -PI) {
            facingDir += 2 * PI.toFloat()
        } else if (facingDir > PI) {
            facingDir -= 2 * PI.toFloat()
        }
    }

    private fun updateRoutine(elapsedFrames: Float) {
        if (actorModel?.isAnimationLocked() == true) { return }

        if (currentRoutine?.hasCompletedAllSequences() == true) { currentRoutine = null }

        val nextProvider = routineQueue.firstOrNull() ?: return
        val nextRoutine = nextProvider.routineProvider.getIfReady() ?: return
        routineQueue.removeFirst()

        if (nextRoutine.routine == null) { return }

        currentRoutine = EffectManager.registerActorRoutine(this, nextProvider.context, nextRoutine.routine).also {
            it.onComplete = nextRoutine.callback
        }

        if (!nextRoutine.options.blocking) {
            currentRoutine = null
        }
    }

    private fun updateDestinationFacingDir() {
        val target = getTargetDirectionVector() ?: return
        setDestinationFacingDir(target)
    }

    fun isDualWield(): Boolean {
        val sub = equipment.items[EquipSlot.Sub] ?: return false
        val subInfo = InventoryItems[sub]

        // TODO - better dual wield check?
        val equipSlots = subInfo.equipmentItemInfo.equipSlots
        return equipSlots.contains(EquipSlot.Main) && equipSlots.contains(EquipSlot.Sub)
    }

    fun isHandToHand(): Boolean {
        val main = equipment.items[EquipSlot.Main] ?: return false
        val mainInfo = InventoryItems[main]
        return mainInfo.equipmentItemInfo.weaponInfo.skill == Skill.HandToHand
    }

    fun equip(equipment: Equipment) {
        this.equipment.copyFrom(equipment)
    }

    fun equip(equipSlot: EquipSlot, itemInfo: InventoryItemInfo) {
        val beforeId = equipment.items[equipSlot]
        if (beforeId != null && beforeId == itemInfo.itemId) {
            equipment.items[equipSlot] = 0
        } else {
            equipment.items[equipSlot] = itemInfo.itemId
        }

        if (isPlayer()) { LocalStorage.changeConfiguration { it.playerEquipment.copyFrom(equipment) } }

        refreshModelEquipment()
    }

    fun refreshModelEquipment() {
        val model = actorModel?.model ?: return
        if (model !is PcModel) { return }

        val look = model.copyLook()

        for ((slot, id) in equipment.items.entries) {
            val modelSlot = slot.toModelSlot() ?: continue
            val itemModelId = ItemModelTable[InventoryItems[id]]
            look[modelSlot] = itemModelId
        }

        val mainWepItemId = equipment.items[EquipSlot.Main] ?: 0
        val mainWepItemInfo = InventoryItems[mainWepItemId]

        if (mainWepItemInfo.equipmentItemInfo.weaponInfo.skill == Skill.HandToHand) {
            look[ItemModelSlot.Sub] = look[ItemModelSlot.Main]
        }

        model.updateLook(look)
    }

    fun getEquipment(equipSlot: EquipSlot) : InventoryItemInfo? {
        val itemId = equipment.items[equipSlot] ?: return null
        return InventoryItems[itemId]
    }

    fun playRoutine(routineId: DatId, routineCompleteCallback: RoutineCompleteCallback? = null): EffectRoutineInstance? {
        val routine = findAnimationRoutine(routineId)

        return if (routine == null) {
            routineCompleteCallback?.onComplete()
            null
        } else {
            playRoutine(routine).also { it.onComplete = routineCompleteCallback }
        }
    }

    private fun playRoutine(routine: EffectRoutineResource): EffectRoutineInstance {
        return EffectManager.registerActorRoutine(this, ActorContext(this.id), routine)
    }

    fun loopRoutine(routine: DatId): EffectRoutineInstance? {
        val output = playRoutine(routine)
        output?.onComplete = RoutineCompleteCallback { output?.repeat() }
        return output
    }

    fun playEmote(mainId: Int, subId: Int) {
        val model = actorModel?.model ?: return
        if (model !is PcModel) { return }

        val raceGenderConfig = model.raceGenderConfig
        val fileIndexId = MainDll.getBaseEmoteAnimationIndex(raceGenderConfig) + mainId

        val filePath = FileTableManager.getFilePath(fileIndexId)
        if (filePath == null) {
            warn("Couldn't resolve emote: $mainId/$subId")
            return
        }

        DatLoader.load(filePath).onReady {
            val emoteId = DatId("em0${subId}")
            val routine = it.getAsResource().getNullableChildRecursivelyAs(emoteId, EffectRoutineResource::class)

            if (routine != null) {
                EffectManager.registerActorRoutine(this, ActorContext(this.id), routine)
            }
        }
    }

    fun enqueueModelRoutine(routineId: DatId, actorContext: ActorContext = ActorContext(id), options: RoutineOptions = RoutineOptions(), callback: RoutineCompleteCallback? = null) {
        enqueueRoutine(actorContext) {
            val routine = findAnimationRoutine(routineId) ?: return@enqueueRoutine null
            ProvidedRoutine(routine, options, callback)
        }
    }

    fun enqueueModelRoutineIfReady(routineId: DatId, actorContext: ActorContext = ActorContext(id), options: RoutineOptions = RoutineOptions(), callback: RoutineCompleteCallback? = null) {
        val routine = findAnimationRoutine(routineId)

        if (routine == null) {
            callback?.onComplete()
        } else {
            enqueueRoutine(actorContext) { ProvidedRoutine(routine, options, callback) }
        }
    }

    private fun findAnimationRoutine(routineId: DatId): EffectRoutineResource? {
        return actorModel?.model?.getAnimationDirectories()?.firstNotNullOfOrNull { it.getNullableChildAs(routineId, EffectRoutineResource::class) }
    }

    fun onReadyToDraw(readyToDrawAction: ReadyToDrawAction) {
        readyToDrawActions += readyToDrawAction
    }

    fun playSoundPrefixed(prefix: String) {
        val randomMatch = getAllAnimationDirectories()
            .flatMap { it.collectByType(SoundPointerResource::class) }
            .filter { it.id.id.startsWith(prefix) }
            .randomOrNull() ?: return

        AudioManager.playSoundEffect(randomMatch, ActorAssociation(this, ActorContext(id)), positionFn = { position })
    }

    fun updateStatusEffects(elapsedFrames: Float): Set<StatusEffectState> {
        statusEffects.forEach {
            if (it.remainingDuration != null) {
                it.remainingDuration = it.remainingDuration!! - elapsedFrames
            }
        }

        val expired = statusEffects.filter { it.remainingDuration != null && it.remainingDuration!! <= 0f }.toSet()
        statusEffects.removeAll(expired)

        return expired
    }

    fun gainStatusEffect(statusId: Int, durationInSeconds: Int? = null): StatusEffectState {
        val state = StatusEffectState(StatusEffectHelper[statusId])

        if (durationInSeconds != null) {
            state.remainingDuration = secondsToFrames(durationInSeconds)
        }

        statusEffects += state
        return state
    }

    fun getStatusEffects() = statusEffects

    fun getStatusEffect(statusId: Int): StatusEffectState? {
        return statusEffects.firstOrNull { it.info.id == statusId }
    }

    fun hasStatusEffect(statusId: Int): Boolean {
        return getStatusEffect(statusId) != null
    }

    fun removeStatusEffect(statusId: Int) {
        statusEffects.removeAll { it.info.id == statusId }
    }

    fun getPetId(): ActorId? {
        return pet
    }

    fun setPet(id: ActorId) {
        pet = id
    }

    fun removePet() {
        pet = null
    }

    fun setMount(mount: Mount?) {
        this.mount = mount
    }

    fun getMount() = mount

    fun syncMountPosition() {
        val mount = ActorManager[mount?.id] ?: return
        mount.position.copyFrom(position)
        mount.facingDir = facingDir
    }

    fun syncBubblePosition() {
        val bubble = ActorManager[bubbleState?.id] ?: return
        bubble.position.copyFrom(position)
        bubble.facingDir = facingDir
    }

    private fun startAutoRunParticles() {
        val model = actorModel?.model
        if (model !is NpcModel) { return }
        if (owner != null) { return }

        val association = ActorAssociation(this, context = ActorContext(id))
        model.resource.getAsResource().collectByTypeRecursive(EffectResource::class)
            .filter { pg -> pg.particleGenerator.autoRun }
            .forEach { pg -> EffectManager.registerEffect(association, pg) }
    }

    fun isStaticNpc(): Boolean {
        return getNpcInfo() != null
    }

    fun isDoor(): Boolean {
        return getNpcInfo()?.datId?.isDoorId() ?: return false
    }

    fun isElevator(): Boolean {
        return getNpcInfo()?.datId?.isElevatorId() ?: return false
    }

    fun toggleDoorState() {
        val animation = if (doorState.open) { DatId.close } else { DatId.open }
        enqueueModelRoutine(animation)
        doorState.open = !doorState.open
    }

    fun shouldApplyCollision(): Boolean {
        getNpcInfo() ?: return true
        if (!ActorManager.isVisible(id)) { return false }
        return !isDoor() && !isElevator()
    }

    fun isDependent(): Boolean {
        return owner != null
    }

}
