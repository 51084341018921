package xim.poc.tools

import kotlinx.browser.document
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLCanvasElement
import org.w3c.dom.HTMLInputElement
import xim.math.Vector3f
import xim.poc.ActorManager
import xim.poc.MainTool
import xim.poc.browser.LocalStorage

object FrameTool {

    private var setup = false

    fun setup() {
        if (setup) { return }
        setup = true

        val nextButton = document.getElementById("next") as HTMLButtonElement
        nextButton.onclick = {
            MainTool.internalLoop(0.25f, 0.25f)
        }

        val fullScreen = document.getElementById("fullScreen") as HTMLButtonElement
        fullScreen.onclick = {
            val canvas = document.getElementById("canvas") as HTMLCanvasElement
            canvas.requestFullscreen()
        }

        val resetConfig = document.getElementById("resetConfig") as HTMLButtonElement
        resetConfig.onclick = { LocalStorage.resetConfiguration() }

        val goButton = document.getElementById("goButton") as HTMLButtonElement
        goButton.onclick = {
            val goValue = (document.getElementById("go") as HTMLInputElement).value

            try {
                val parts = goValue.split('(', ')')[1].split(',').map { it.trim() }
                val x = parts[0].split("x=").last().toFloat()
                val y = parts[1].split("y=").last().toFloat()
                val z = parts[2].split("z=").last().toFloat()

                ActorManager.player().position.copyFrom(Vector3f(x, y, z))
            } catch (e: Exception) { console.error("Failed to parse: $goValue") }
        }
    }

}