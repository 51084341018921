package xim.poc

import xim.math.Vector3f
import xim.poc.camera.Camera
import xim.poc.camera.CameraReference
import xim.poc.tools.ZoneNpcTool
import xim.resource.DatId
import xim.util.Fps
import xim.util.Timer
import kotlin.time.Duration.Companion.seconds

class ActorCollision(val collisionsByArea: Map<Area, List<CollisionProperty>> = emptyMap(), val freeFallDuration: Float = 0f) {

    fun isInFreeFall(): Boolean {
        return freeFallDuration > 0f
    }

    fun getEnvironment(): Pair<Area?, DatId?> {
        for (perAreaCollisionProperties in collisionsByArea) {
            val first = perAreaCollisionProperties.value.firstOrNull { it.environmentId != null } ?: continue
            return Pair(perAreaCollisionProperties.key, first.environmentId)
        }

        return Pair(null, null)
    }

}

object ActorManager {

    private val actors: HashMap<ActorId, Actor> = LinkedHashMap()
    private var idCounter = 1

    private var visibleActors = HashMap<ActorId, Actor>()

    fun nextId(): ActorId {
        val id = ActorId(idCounter)
        idCounter += 1
        return id
    }

    fun add(actor: Actor) {
        actors[actor.id] = actor
    }

    fun getAll(): Set<ActorId> {
        return actors.keys
    }

    fun clear() {
        val preserveIds = setOfNotNull(player().id, player().getMount()?.id)
        actors.entries.removeAll { !preserveIds.contains(it.key) }
        actors.forEach { it.value.resetRenderState() }
    }

    fun isVisible(actorId: ActorId): Boolean {
        return visibleActors.containsKey(actorId)
    }

    fun getVisibleActors(): Collection<Actor> {
        return visibleActors.values
    }

    operator fun get(actorId: ActorId?): Actor? {
        return actorId?.let { getActor(actorId) }
    }

    fun remove(actorId: ActorId) {
        val actor = actors[actorId] ?: return
        EffectManager.clearEffects(ActorAssociation(actor, ActorContext(actorId)))
        actors.remove(actorId)
    }

    fun player(): Actor {
        return this[ActorId(0)] ?: throw IllegalStateException("Player wasn't created?")
    }

    private fun getActor(actorId: ActorId): Actor? {
        return actors[actorId]
    }

    fun updateAll(elapsedFrames: Float) {

        val actorsToUpdate = actors.values.filter { !ZoneNpcTool.isForceHidden(it.id) }

        for (actor in actorsToUpdate) {
            updateActor(elapsedFrames, actor)
        }

        for (actor in actorsToUpdate) {
            actor.syncMountPosition()
            actor.syncBubblePosition()
        }

        Timer.time("filterActors") { refreshVisibleActors(actorsToUpdate) }

        // In game, skeletal animations are only updated every other frame.
        // Halving the frame has the same effect on animation speed, but is smoother.
        val scaledElapsedFrames = elapsedFrames / 2f
        for (actor in visibleActors.values) {
            Timer.time("updateAnimation") { updateAnimation(scaledElapsedFrames, actor) }
        }
    }

    private fun refreshVisibleActors(validActors: List<Actor>) {
        visibleActors.clear()

        val pet = get(player().getPetId())
        if (pet != null) { visibleActors[pet.id] = pet }

        val mount = get(player().getMount()?.id)
        if (mount != null) { visibleActors[mount.id] = mount }

        visibleActors[player().id] = player()

        val camera = CameraReference.getInstance()
        visibleActors += validActors
            .filter { !visibleActors.containsKey(it.id) && !isActorCulled(camera, it) }
            .sortedBy { Vector3f.distance(camera.getPosition(), it.position) }
            .take(15)
            .associateBy { it.id }
    }

    private fun updateActor(elapsedFrames: Float, actor: Actor) {
        val currentScene = SceneManager.getCurrentScene()

        Timer.time("updateActor") { actor.update(elapsedFrames) }
        val collisionResults = Timer.time("collideActor") {
            if (actor.isStaticNpc()) {
                FrameCoherence.getNpcCollision(actor.id) { ActorCollision(currentScene.collideActor(actor, elapsedFrames)) }
            } else {
                ActorCollision(currentScene.collideActor(actor, elapsedFrames))
            }
        }

        actor.lastCollisionResult = if (collisionResults.collisionsByArea.entries.any { it.value.isNotEmpty() }) {
             collisionResults
        } else if (Fps.framesToSeconds(actor.lastCollisionResult.freeFallDuration + elapsedFrames) > 1.seconds) {
            ActorCollision(
                collisionsByArea = emptyMap(),
                freeFallDuration = actor.lastCollisionResult.freeFallDuration + elapsedFrames)
        } else {
            ActorCollision(
                collisionsByArea = actor.lastCollisionResult.collisionsByArea,
                freeFallDuration = actor.lastCollisionResult.freeFallDuration + elapsedFrames
            )
        }
    }

    private fun updateAnimation(elapsedFrames: Float, actor: Actor) {
        val actorModel = actor.actorModel ?: return
        val actorMount = actor.getMount()

        if (!actorModel.isAnimationLocked()) {
            val idleId = actor.getIdleAnimationId()

            if (actorMount != null) {
                actorModel.transitionToIdleOnStopMoving(idleId, actor.getAllAnimationDirectories())
            } else if (actor.currentVelocity.magnitude() > 0.0001f) {
                val transition = if (needsInBetweenFrame(actor)) { TransitionParams(inBetween = idleId) } else { TransitionParams() }
                actorModel.transitionToMoving(actor.getMovementAnimation(), actor.getAllAnimationDirectories(), transitionParams = transition)
            } else if (!actor.hasEnqueuedRoutines() && actor.stoppedMoving) {
                actorModel.transitionToIdleOnStopMoving(idleId, actor.getAllAnimationDirectories())
            } else {
                actorModel.transitionToIdleOnCompleted(idleId, actor.getAllAnimationDirectories())
            }
        }

        actorModel.update(elapsedFrames)
        actorModel.getSkeleton()?.animate(actor, actorModel, actorMount)
    }


    private fun needsInBetweenFrame(actor: Actor): Boolean {
        // Interpolating between [mvr?] and [mvl?] has bad results, because some joints will rotate ~180 deg
        // So, depending on the joint-tree, some joints interpolate the "long way"
        // To mitigate, we can use the first frame of the idle animation as an in-between frame
        if (!actor.targetLocked) { return false }

        val currentMovementDir = actor.getMovementDirection()
        return currentMovementDir == Direction.Left || currentMovementDir == Direction.Right
    }

    private fun isActorCulled(camera: Camera, actor: Actor): Boolean {
        val cameraPosition = camera.getPosition()
        if (Vector3f.distance(cameraPosition, actor.position) > 50f) { return true }

        if (player().target == actor.id) { return false }

        val bbBox = actor.getBoundingBox()
        return !camera.isVisible(bbBox)
    }

}