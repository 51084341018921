package xim.poc.game

import xim.poc.browser.LocalStorage
import xim.resource.EquipSlot
import xim.resource.InventoryItemInfo
import xim.resource.InventoryItems

data class InventoryItem(val id: Int)

object PlayerInventory {

    val inventoryItems = ArrayList<InventoryItem>()

    init {
        // Restore added items
        LocalStorage.getConfiguration().playerInventory.forEach { inventoryItems += InventoryItem(it) }

        if (inventoryItems.isEmpty()) {
            // Add one of each crystal
            for (i in 4096 until 4096 + 8) {
                inventoryItems += InventoryItem(i)
            }

            // Add one of each gathering tool
            listOf(605, 1020, 1021).forEach { inventoryItems += InventoryItem(it) }
        }
    }

    fun getInventoryItemsByEquipSlot(equipSlot: EquipSlot) : List<InventoryItemInfo> {
        return inventoryItems.map { InventoryItems[it.id] }.filter { it.equipmentItemInfo.equipSlots.contains(equipSlot) }
    }

    fun addItem(id: Int) {
        inventoryItems += InventoryItem(id)
        updateLocalStorage()
    }

    fun discardItem(index: Int, currentItem: InventoryItemInfo) {
        val item = inventoryItems.getOrNull(index)
        if (item?.id != currentItem.itemId) { return }

        inventoryItems.removeAt(index)
        updateLocalStorage()
    }

    fun sort() {
        inventoryItems.sortBy { it.id }
        updateLocalStorage()
    }

    private fun updateLocalStorage() {
        LocalStorage.changeConfiguration {
            it.playerInventory.clear()
            it.playerInventory.addAll(inventoryItems.map { item -> item.id })
        }
    }

}