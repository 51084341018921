package xim.poc.tools

import kotlinx.browser.document
import kotlinx.dom.clear
import org.w3c.dom.HTMLBRElement
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import xim.resource.table.ZoneNameTable

object ZoneSearchTool {

    private var setup = false

    private lateinit var input: HTMLInputElement
    private lateinit var suggestions: HTMLDivElement

    private lateinit var nameIdMapping: Map<String, Int>

    fun setup() {
        if (setup) { return }
        setup = true

        nameIdMapping = ZoneNameTable.getAllFirst()
            .mapIndexed { index, name -> Pair("$name ($index)", index) }
            .associate { it.first to it.second }

        input = document.getElementById("ZoneInput") as HTMLInputElement
        input.oninput = { onChange() }
        input.value = ""

        suggestions = document.getElementById("ZoneSuggestions") as HTMLDivElement
    }

    private fun onChange() {
        suggestions.clear()

        val text = input.value.lowercase()
        if (text.length < 3) {
            return
        }

        nameIdMapping.entries
            .filter { it.key.lowercase().contains(text) }
            .take(10)
            .forEach { addButton(it) }
    }

    private fun addButton(entry: Map.Entry<String, Int>) {
        val button = document.createElement("button") as HTMLButtonElement
        button.onclick = {
            val id = entry.value
            ZoneChanger.beginChangeZone(ZoneConfig(zoneId = id))
        }

        button.innerText = entry.key
        suggestions.appendChild(button)

        val br = document.createElement("br") as HTMLBRElement
        suggestions.appendChild(br)
    }

}