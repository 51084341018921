package xim.poc

import xim.poc.camera.CameraReference
import xim.poc.browser.Keyboard
import xim.math.Vector3f
import xim.poc.game.PartyManager
import xim.poc.ui.ChatLog

interface ActorController {
    fun getVelocity(actor: Actor, elapsedFrames: Float): Vector3f
}

class NoOpActorController: ActorController {
    override fun getVelocity(actor: Actor, elapsedFrames: Float): Vector3f {
        return Vector3f()
    }
}

class KeyboardActorController(val keyboard: Keyboard): ActorController {

    private var autorun = false

    override fun getVelocity(actor: Actor, elapsedFrames: Float): Vector3f {
        val forwardVector = if (actor.targetLocked) { actor.getTargetDirectionVector()!! } else { CameraReference.getInstance().getViewVector() }
        val leftVector = Vector3f.UP.cross(forwardVector)

        val speedUp = if (keyboard.isKeyPressedOrRepeated(Keyboard.Key.SHIFT)) { 10.0f } else { 1.0f }

        forwardVector.y = 0f
        val forward = forwardVector.normalize()

        leftVector.y = 0f
        val right = leftVector.normalize()

        val velocity = getDirectionFromTouchEvents(forward, right) ?: getDirectionFromButtonPresses(forward, right)
        if (velocity.magnitudeSquare() <= 1e-7) {
            return velocity
        }

        return velocity.normalizeInPlace() * speedUp * actor.getMovementSpeed() * elapsedFrames
    }

    private fun getDirectionFromTouchEvents(forward: Vector3f, right: Vector3f): Vector3f? {
        val events = keyboard.getTouchData()
        if (events.isEmpty()) { return null }

        for (event in events) {
            if (event.startingX > 0.5) { continue }

            val direction = Vector3f(event.dX.toFloat(), 0f, event.dY.toFloat())
            if (direction.magnitudeSquare() <= 1e-7) { return Vector3f() }

            direction.normalizeInPlace()
            return right * -direction.x + forward * -direction.z
        }

        return null
    }

    private fun getDirectionFromButtonPresses(forward: Vector3f, right: Vector3f): Vector3f {
        val velocity = Vector3f()

        if (keyboard.isKeyPressed(Keyboard.Key.R)) {
            autorun = !autorun
        }

        if (autorun || keyboard.isKeyPressedOrRepeated(Keyboard.Key.W)) {
            velocity.x += forward.x
            velocity.z += forward.z
        }
        if (keyboard.isKeyPressedOrRepeated(Keyboard.Key.A)) {
            velocity.x += right.x
            velocity.z += right.z
        }
        if (keyboard.isKeyPressedOrRepeated(Keyboard.Key.S)) {
            velocity.x -= forward.x
            velocity.z -= forward.z
            autorun = false
        }
        if (keyboard.isKeyPressedOrRepeated(Keyboard.Key.D)) {
            velocity.x -= right.x
            velocity.z -= right.z
        }
        if (keyboard.isKeyPressedOrRepeated(Keyboard.Key.SPACE)) {
            velocity.y -= 1f
        }

        return velocity
    }

}

class DefaultEnemyController : ActorController {

    private val followDistanceNear = 2f
    private val followDistanceFar = 4f

    private var approaching = false

    override fun getVelocity(actor: Actor, elapsedFrames: Float): Vector3f {
        if (!actor.isEngaged()) { return Vector3f.ZERO }

        val target = ActorManager[actor.target] ?: return Vector3f.ZERO
        val distance = Vector3f.distance(actor.position, target.position)

        return if (distance <= followDistanceNear) {
            approaching = false
            Vector3f.ZERO
        } else if (approaching || distance >= followDistanceFar) {
            approaching = true
            velocityVectorTo(actor, target, actor.getMovementSpeed(), elapsedFrames)
        } else {
            Vector3f.ZERO
        }
    }
}

class PetController(val ownerId: ActorId) : ActorController {

    private val followDistanceNear = 2f
    private val followDistanceFar = 4f

    private var approaching = false

    override fun getVelocity(actor: Actor, elapsedFrames: Float): Vector3f {
        val followTarget = if (actor.isEngaged()) { ActorManager[actor.target] } else { ActorManager[ownerId] } ?: return Vector3f.ZERO
        val distance = Vector3f.distance(followTarget.position, actor.position)

        return if (distance <= followDistanceNear) {
            approaching = false
            Vector3f.ZERO
        } else if (approaching || distance >= followDistanceFar) {
            approaching = true
            velocityVectorTo(actor, followTarget, actor.getMovementSpeed(), elapsedFrames)
        } else {
            Vector3f.ZERO
        }
    }

}

class TrustController() : ActorController {

    private val followDistanceNear = 2f
    private val followDistanceFar = 4f

    private var approaching = false

    override fun getVelocity(actor: Actor, elapsedFrames: Float): Vector3f {
        val party = PartyManager[actor]
        val partyIndex = party.getIndex(actor.id)

        val partyFollowTarget = if (partyIndex != null) {
            party.getByIndex(partyIndex - 1)
        } else {
            null
        }

        val followTarget = if (actor.isEngaged()) { ActorManager[actor.target] } else { partyFollowTarget } ?: return Vector3f.ZERO
        val distance = Vector3f.distance(followTarget.position, actor.position)

        return if (distance <= followDistanceNear) {
            approaching = false
            Vector3f.ZERO
        } else if (approaching || distance >= followDistanceFar) {
            approaching = true
            velocityVectorTo(actor, followTarget, actor.getMovementSpeed(), elapsedFrames)
        } else {
            Vector3f.ZERO
        }
    }

}

class MountController(val ownerId: ActorId): ActorController {

    override fun getVelocity(actor: Actor, elapsedFrames: Float): Vector3f {
        if (!actor.isMount) { return Vector3f.ZERO}
        val owner = ActorManager[ownerId] ?: return Vector3f.ZERO
        return owner.currentVelocity
    }

}

private fun velocityVectorTo(actor: Actor, other: Actor, speed: Float, elapsedFrames: Float): Vector3f {
    val direction = other.position - actor.position
    direction.y = 0f

    if (direction.magnitudeSquare() < 1e-5) { return Vector3f.ZERO }
    return direction.normalizeInPlace() * speed * elapsedFrames
}
