package xim.poc.ui

import xim.poc.gl.Color
import xim.math.Vector2f
import xim.math.Vector4f
import xim.poc.MenuStacks
import xim.poc.TextDirection
import xim.poc.UiElementHelper
import xim.poc.UiResourceManager
import xim.resource.*

enum class ChatLogColor(val color: Color) {
    Normal(Color(255, 255, 255, 255)),
    Info(Color(255, 255, 200, 255)),
    SystemMessage(Color(215, 115, 255, 255)),
}

private class ChatLogLine(val text: String, val chatColor: ChatLogColor)

object ChatLog {

    private const val width = 512f

    private const val numLines = 8
    private const val lineHeight = 16f
    private const val height = numLines * lineHeight + 10f

    private const val fakeUiMenuName = "fake chatlog menu"

    private val lines = ArrayDeque<ChatLogLine>()
    private lateinit var chatLogFrame: UiMenuElement

    fun addLine(line: String, chatLogColor: ChatLogColor = ChatLogColor.Normal) {
        lines.addFirst(ChatLogLine(line, chatLogColor))
        while (lines.size > 80) { lines.removeLast() }
    }

    fun draw() {
        registerFakeMenu()
        val logWindowPos = UiElementHelper.drawMenu(menuName = fakeUiMenuName, menuStacks = MenuStacks.LogStack) ?: return

        // To support on-demand size change
        chatLogFrame.size.x = width
        chatLogFrame.size.y = height

        val borderSize = Vector2f(8f, 8f)
        val lineOffset = Vector2f(0f, height - borderSize.y - lineHeight)

        val maxWidth = width - borderSize.x + logWindowPos.x
        val clipSize = Vector4f(logWindowPos.x, logWindowPos.y + borderSize.y, maxWidth, height - borderSize.y)

        var totalLines = 0
        for (line in lines) {
            val offset = logWindowPos + borderSize + lineOffset

            val linesDrawn = UiElementHelper.drawMultilineText(line.text, offset, maxWidth = maxWidth.toInt(), textDirection = TextDirection.BottomToTop, color = line.chatColor.color, clipSize = clipSize)
                ?: throw IllegalStateException("Couldn't draw line")

            lineOffset.y -= (lineHeight * linesDrawn)

            totalLines += linesDrawn
            if (totalLines >= 8) { break }
        }
    }

    private var registered = false
    private fun registerFakeMenu() {
        if (registered) { return }
        registered = true

        val option = UiMenuElementOption(elementIndex = 0, config = 0 , elementGroupName = "")
        chatLogFrame = UiMenuElement(Vector2f(16f, 0f), Vector2f(), listOf(option), emptyMap())

        val menu = UiMenu(fakeUiMenuName, chatLogFrame, emptyList())
        UiResourceManager.register(UiMenuResource(DatId.zero, menu))
    }

}