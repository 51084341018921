package xim.poc

import xim.math.Vector3f
import xim.poc.Actor
import xim.poc.ActorManager
import xim.poc.game.PartyManager
import xim.resource.TargetFlag

class ActionTargetFilter(val targetFlags: Int) {

    fun targetFilter(source: Actor, target: Actor): Boolean {
        // TODO real range check?
        if (Vector3f.distance(source.position, target.position) > 50f) {
            return false
        }

        if (!target.isDead() && TargetFlag.Corpse.match(targetFlags)) {
            return false
        }

        if (target.isDead() && !TargetFlag.Corpse.match(targetFlags)) {
            return false
        }

        if (target.enemy && TargetFlag.Enemy.match(targetFlags)) {
            return true
        }

        if (source.id == target.id && TargetFlag.Self.match(targetFlags)) {
            return true
        }

        if (target.id == ActorManager.player().id && TargetFlag.Player.match(targetFlags)) {
            return true
        }

        val party = PartyManager[source]
        if (party.contains(target) && TargetFlag.Party.match(targetFlags)) {
            return true
        }

        if (source.getPetId() == target.id && (TargetFlag.Ally.match(targetFlags))) {
            return true
        }

        if (target.isStaticNpc() && TargetFlag.Npc.match(targetFlags)) {
            return true
        }

        return false
    }

}