package xim.poc.tools

import kotlinx.browser.document
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLSpanElement
import xim.poc.*
import xim.resource.DatId
import xim.resource.EffectRoutineResource
import xim.util.toTruncatedString

object PlayerAnimationTrackerTool {

    private var setup = false

    fun update() {
        if (!setup) {
            setup = true
            setup()
        }

        val playerActor = ActorManager.player()
        val targetActor = ActorManager[playerActor.target] ?: playerActor

        val animCoordinator = targetActor.actorModel?.skeletonAnimationCoordinator ?: return

        for (i in 0..4) {
            val animator = animCoordinator.animations[i]
            if (animator == null) {
                (document.getElementById("anim${i}") as HTMLSpanElement).innerText = "N/A"
                continue
            }

            val transition = animator.transition
            if (transition != null) {
                (document.getElementById("anim${i}") as HTMLSpanElement).innerText = "transition -> ${transition.next.animation.id}"
                continue
            }

            val current = animator.currentAnimation ?: continue
            (document.getElementById("anim${i}") as HTMLSpanElement).innerText = "${current.animation.id} : ${animator.currentAnimation?.currentFrame?.toTruncatedString(2)} (done: ${animator.currentAnimation?.isDoneLooping()})"
        }

        (document.getElementById("moveLocked") as HTMLSpanElement).innerText = targetActor.actorModel!!.isMovementLocked().toString()
        (document.getElementById("animLocked") as HTMLSpanElement).innerText = targetActor.actorModel!!.isAnimationLocked().toString()
    }

    private fun setup() {
        val input = document.getElementById("animInput") as HTMLInputElement
        val button = document.getElementById("animPlay") as HTMLButtonElement

        button.onclick = {
            val routineId = DatId(input.value)

            val player = ActorManager.player()
            val routine = player.actorModel?.model?.let {
                it.getAnimationDirectories() + it.getMainBattleAnimationDirectory() + it.getSubBattleAnimationDirectory()
            }?.filterNotNull()?.firstNotNullOfOrNull { it.getNullableChildRecursivelyAs(routineId, EffectRoutineResource::class) }

            if (routine != null) { EffectManager.registerActorRoutine(player, ActorContext(player.id), routine) } else { println("Didn't find $routineId") }
        }

        val skelPlay = document.getElementById("skelPlay") as HTMLButtonElement
        skelPlay.onclick = { setSkeletonAnimation() }

        val emotePlay = document.getElementById("emotePlay") as HTMLButtonElement
        emotePlay.onclick = { playEmote() }
    }

    private fun playEmote() {
        val emoteInput0 = document.getElementById("emoteId0") as HTMLInputElement
        val emoteInput1 = document.getElementById("emoteId1") as HTMLInputElement

        val id0 = emoteInput0.value.toIntOrNull() ?: return
        val id1 = emoteInput1.value.toIntOrNull() ?: return

        ActorManager.player().playEmote(id0, id1)
    }

    private fun setSkeletonAnimation() {
        val input = document.getElementById("skelInput") as HTMLInputElement

        val player = ActorManager.player()
        val model = player.actorModel ?: return

        model.lockAnimation(300f)
        model.setSkeletonAnimation(DatId(input.value), player.getAllAnimationDirectories(), loopParams = LoopParams.lowPriorityLoop(), transitionParams = TransitionParams())
    }

}