package xim.resource.table

import xim.poc.browser.DatLoader
import xim.resource.ByteReader

val MonsterAbilityNameTable = BasicStringTable("ROM/27/80.DAT", 0x80.toByte())

data class MobSkillInfo(val id: Int, val animationId: Int, val targetFlag: Int)

// https://raw.githubusercontent.com/LandSandBoat/server/base/sql/mob_skills.sql
object MobSkillInfoTable: TableResource {


    private lateinit var animInfos: Map<Int, MobSkillInfo>
    private var preloaded = false

    override fun preload() {
        if (preloaded) { return }
        preloaded = true
        loadTable()
    }

    override fun isFullyLoaded() : Boolean {
        return this::animInfos.isInitialized
    }

    operator fun get(skillId: Int): MobSkillInfo? {
        return animInfos[skillId]
    }

    fun getAnimationPath(info: MobSkillInfo): String? {
        return getAnimationPath(info.animationId)
    }

    fun getAnimationPath(animationId: Int): String? {
        val fileTableOffset = if (animationId < 0x200) {
            0x0F3C
        } else if (animationId < 0x600) {
            0xC1EF
        } else if (animationId < 0x800) {
            0xE739
        } else {
            0x14B07
        }

        val fileTableIndex = fileTableOffset + animationId
        return FileTableManager.getFilePath(fileTableIndex)
    }

    private fun loadTable() {
        DatLoader.load("landsandboat/MobSkillsTable.DAT").onReady { parseTable(it.getAsBytes()) }
    }

    private fun parseTable(byteReader: ByteReader) {
        val skills = HashMap<Int, MobSkillInfo>()
        while (byteReader.hasMore()) {
            val skill = MobSkillInfo(id = byteReader.next16(), animationId = byteReader.next16(), targetFlag = byteReader.next16())
            skills[skill.id] = skill
        }
        this.animInfos = skills
    }

}
