package xim.poc.game

import xim.poc.Actor

fun interface EffectCallback {
    fun invoke()

    companion object {
        val noop = EffectCallback {  }
    }

}

class AttackContext(val hitFlag: Int = 0, val retaliationFlag: Int = 0, val rollValueFlag: Int = 0, val rollSumFlag: Int = 0, val effectCallback: EffectCallback) {

    companion object {
        fun from(attacker: Actor, defender: Actor, effectCallback: EffectCallback): AttackContext {
            val hitFlag = if (attacker.hasStatusEffect(94)) { 1 } else if (attacker.hasStatusEffect(95)) { 2 } else { 0 }
            val retaliationFlag = if (defender.hasStatusEffect(34)) { 1 } else if (defender.hasStatusEffect(35)) { 2 } else { 0 }
            return AttackContext(hitFlag = hitFlag, retaliationFlag = retaliationFlag, effectCallback = effectCallback)
        }

        fun fromRoll(rollValue: Int, rollSum: Int, effectCallback: EffectCallback): AttackContext {
            return AttackContext(rollValueFlag = rollValue, rollSumFlag = rollSum, effectCallback = effectCallback)
        }

        fun noop(): AttackContext {
            return AttackContext(effectCallback = {})
        }

    }

}