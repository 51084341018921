package xim.poc.tools

import kotlinx.browser.document
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLOptionElement
import org.w3c.dom.HTMLSelectElement
import org.w3c.dom.events.MouseEvent
import xim.poc.*
import xim.poc.game.AttackContext
import xim.poc.game.BattleEngine
import xim.resource.InventoryItems
import xim.resource.ItemListType
import xim.resource.table.*

object SpellHelper {

    private var spellsPopulated = false

    fun setup() {
        populateSpellButtons()
    }

    private fun populateSpellButtons() {
        if (spellsPopulated) { return }
        spellsPopulated = true

        // Magic
        val magicInput = (document.getElementById("MagicSpellId") as HTMLSelectElement)

        SpellNameTable.getAllFirst().forEachIndexed { i, string ->
            val option = document.createElement("option") as HTMLOptionElement
            magicInput.appendChild(option)

            option.text = string
            option.value = i.toString()
        }

        (document.getElementById("CastMagic") as HTMLButtonElement).onclick = {
            castSpellFromIndex(magicInput.value.toInt())
        }

        (document.getElementById("NextMagic") as HTMLButtonElement).onclick = {
            castSpellFromIndex(magicInput.value.toInt())
            magicInput.selectedIndex += 1; Unit
        }

        // Abilities
        val abilityInput = document.getElementById("JobAbilityId") as HTMLSelectElement
        AbilityNameTable.getAllFirst().forEachIndexed { i, string ->
            if (string.startsWith(".") || string.startsWith("#")) { return@forEachIndexed }

            val option = document.createElement("option") as HTMLOptionElement
            abilityInput.appendChild(option)

            option.text = string
            option.value = i.toString()
        }

        (document.getElementById("UseAbility") as HTMLButtonElement).onclick = {
            useAbilityFromIndex(abilityInput.value.toInt())
        }

        (document.getElementById("NextAbility") as HTMLButtonElement).onclick = {
            useAbilityFromIndex(abilityInput.value.toInt())
            abilityInput.selectedIndex += 1; Unit
        }

        // MobSkills
        val mobSkillInput = document.getElementById("MobAbilityId") as HTMLSelectElement
        MonsterAbilityNameTable.getAll().forEachIndexed { i, string ->
            val option = document.createElement("option") as HTMLOptionElement
            mobSkillInput.appendChild(option)

            option.text = string
            option.value = i.toString()
        }

        (document.getElementById("MobAbility") as HTMLButtonElement).onclick = {
            useMobAbilityFromIndex(mobSkillInput.value.toInt())
        }

        (document.getElementById("NextMobAbility") as HTMLButtonElement).onclick = {
            useMobAbilityFromIndex(mobSkillInput.value.toInt())
            mobSkillInput.selectedIndex += 1; Unit
        }

        // Items
        val itemInput = (document.getElementById("ItemId") as HTMLSelectElement)

        InventoryItems.getAll().filter { it.type == ItemListType.UsableItem }.forEach {
            val option = document.createElement("option") as HTMLOptionElement
            itemInput.appendChild(option)

            option.text = it.name
            option.value = it.itemId.toString()
        }

        (document.getElementById("UseItem") as HTMLButtonElement).onclick = {
            useItemFromIndex(itemInput.value.toInt())
        }

        (document.getElementById("NextItem") as HTMLButtonElement).onclick = {
            useItemFromIndex(itemInput.value.toInt())
            itemInput.selectedIndex += 1; Unit
        }

        // Custom
        val pathInput = document.getElementById("ExecPathId") as HTMLInputElement
        val pathExec = document.getElementById("Exec") as HTMLButtonElement
        pathExec.onclick = {
            val maybeFileId = pathInput.value.toIntOrNull(0x10)
            if (maybeFileId != null) {
                val path = FileTableManager.getFilePath(maybeFileId) ?: throw IllegalStateException("No such file: $maybeFileId")
                BattleEngine.castSpell(path, debug = true, sourceId = ActorManager.player().id, targetId = getSpellTarget(), attackContext = AttackContext.noop())
            } else {
                BattleEngine.castSpell(pathInput.value, debug = true, sourceId = ActorManager.player().id, targetId = getSpellTarget(), attackContext = AttackContext.noop())
            }
        }

        (document.getElementById("NextExec") as HTMLButtonElement).onclick = {
            pathExec.onclick?.invoke(MouseEvent(""))
            val current = pathInput.value.toIntOrNull(0x10)
            if (current != null) { pathInput.value = (current + 1).toString(0x10) }
        }

    }

    private fun castSpellFromIndex(index: Int) {
        val animationId = SpellAnimationTable[index]
        val magicInfo = SpellInfoTable[index]
        println("Using magic: $magicInfo")
        val dat = FileTableManager.getFilePath(animationId) ?: throw IllegalStateException("No such file for: $animationId")
        BattleEngine.castSpell(dat, debug = true, sourceId = ActorManager.player().id, targetId = getSpellTarget(), attackContext = AttackContext.noop())
    }

    private fun useAbilityFromIndex(index: Int) {
        val playerRaceGenderConfig = (ActorManager.player().actorModel?.model as PcModel).raceGenderConfig
        val abilityInfo = AbilityInfoTable[index]
        val animationId = AbilityTable.getAnimationId(abilityInfo, playerRaceGenderConfig) ?: return
        val dat = FileTableManager.getFilePath(animationId) ?: return

        println("Using ability: $abilityInfo. Animation: 0x${animationId.toString(0x10)} -> $dat")

        BattleEngine.castSpell(dat, debug = true, sourceId = ActorManager.player().id, targetId = getSpellTarget(), attackContext = AttackContext.noop())
    }

    private fun useMobAbilityFromIndex(index: Int) {
        val abilityInfo = MobSkillInfoTable[index] ?: throw IllegalStateException("No skill for $index")
        val path = MobSkillInfoTable.getAnimationPath(abilityInfo) ?: throw IllegalStateException("No animation for $abilityInfo")

        println("Using ability: $abilityInfo -> $path")

        val player = ActorManager.player()

        val source = player.target ?: player.id
        val target = if (abilityInfo.targetFlag and 0x04 != 0) { player.id } else { source }

        BattleEngine.castSpell(path, debug = true, sourceId = source, targetId = target, attackContext = AttackContext.noop())
    }

    private fun useItemFromIndex(index: Int) {
        val itemInfo = InventoryItems[index]
        println("Using item: $itemInfo")

        if (itemInfo.itemId >= 4096 && itemInfo.itemId < 4096 + 8) {
           ActorManager.player().startSynthesizing(SynthesisType.fromItemId(itemInfo.itemId), SynthesisStateMachine.getRandomResult())
           return
        }

        val dat = ItemAnimationTable.getAnimationPath(itemInfo) ?: return
        BattleEngine.castSpell(dat, debug = true, sourceId = ActorManager.player().id, targetId = getSpellTarget(), attackContext = AttackContext.noop())
    }

    private fun getSpellTarget(): ActorId {
        val playerActor = ActorManager.player()
        return playerActor.target ?: playerActor.id
    }

}