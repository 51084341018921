package xim.poc.tools

import kotlinx.browser.document
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLOptionElement
import org.w3c.dom.HTMLSelectElement
import xim.math.Vector3f
import xim.poc.*
import xim.poc.game.BattleEngine
import xim.poc.game.UiStateHelper
import xim.poc.ui.MapDrawer
import xim.resource.DatId
import xim.resource.ZoneInteraction
import xim.resource.table.ZoneNameTable
import xim.util.Fps.secondsToFrames
import xim.util.OnceLogger

data class ZoneConfig(val zoneId: Int, val entryId: DatId? = null, val startPosition: Vector3f? = null)

enum class CustomZoneConfig(val config: ZoneConfig) {
    PsoXja_Elevator(ZoneConfig(9, startPosition = Vector3f(300f, 0f, -50f))),
    MogGarden(ZoneConfig(280, startPosition = Vector3f(0f, 0f, 0f))),
    Valkurm_Cave(ZoneConfig(103, startPosition = Vector3f(718f, -8f, -180f))),
    Valkurm_Beach(ZoneConfig(103, startPosition = Vector3f(377f, 4.3f, -188f))),
    Selbina(ZoneConfig(248, startPosition = Vector3f(34f, -11f, 30.6f))),
    Mhaura(ZoneConfig(249, startPosition = Vector3f(0f, -8f, 48f))),
    CastleOz(ZoneConfig(151, startPosition = Vector3f(0f, 0f, 0f))),
    RoMaeve(ZoneConfig(122, startPosition = Vector3f(0f, -30f, 61f))),
    Beaucedine(ZoneConfig(111, startPosition = Vector3f(-12.5f, -60f, -86f))),
    Uleguerand(ZoneConfig(5, startPosition = Vector3f(-328f, -176f, -40.6f))),
    Reisenjima_Entrance(ZoneConfig(291, startPosition = Vector3f(-500f, -20f, -490f))),
    EastAdoulin(ZoneConfig(257, startPosition = Vector3f(-66f, -1f, -21f))),
    Sarutabartua_West(ZoneConfig(115, startPosition = Vector3f(320f, -4f, -36f))),
    Sarutabartua_East(ZoneConfig(116, startPosition = Vector3f(-116f, -4f, -520f))),
    EmpParadox(ZoneConfig(36, startPosition = Vector3f(500f, 0f, 500f))),
    JeunoUpper(ZoneConfig(244, startPosition = Vector3f(11f, 2f, 72f))),
}

class ZoneChangeOptions(val fullyRevive: Boolean = false)
private class DestinationZoneConfig(val zoneConfig: ZoneConfig, val options: ZoneChangeOptions = ZoneChangeOptions())

object ZoneChanger {

    var setupZonePanel = false

    private var fadeOutParams: FadeParameters? = null
    private var fadeInParams: FadeParameters? = null
    private var destinationZoneConfig: DestinationZoneConfig? = null

    fun setup(defaultZoneConfig: ZoneConfig): ZoneConfig? {
        if (setupZonePanel) { return null }
        setupZonePanel = true

        // Custom
        val customSelect = document.getElementById("CustomZones") as HTMLSelectElement
        val customSelectGo = document.getElementById("CustomZoneGo") as HTMLButtonElement
        customSelectGo.onclick = {
            val selected = CustomZoneConfig.values().first {it.name == customSelect.value}
            beginChangeZone(selected.config)
        }

        for (customConfig in CustomZoneConfig.values()) {
            val option = document.createElement("option") as HTMLOptionElement
            option.text = customConfig.name
            option.value = customConfig.name
            customSelect.appendChild(option)
        }

        val zoneSelect = document.getElementById("Zones") as HTMLSelectElement
        val zoneSelectGo = document.getElementById("ZoneGo") as HTMLButtonElement

        zoneSelectGo.onclick = {
            val zoneId = zoneSelect.value.toInt()
            beginChangeZone(ZoneConfig(zoneId = zoneId))
        }

        ZoneNameTable.getAllFirst().forEachIndexed { index, name ->
            val option = document.createElement("option") as HTMLOptionElement
            option.text = name
            option.value = index.toString()
            zoneSelect.appendChild(option)
        }

        val zonePrev = document.getElementById("ZonePrev") as HTMLInputElement
        val previous = zonePrev.value.toIntOrNull()

        destinationZoneConfig = if (previous != null) {
            val zoneConfig = ZoneConfig(zoneId = previous, startPosition = PlayerPositionTracker.lastStoredPosition())
            DestinationZoneConfig(zoneConfig)
        } else {
            DestinationZoneConfig(defaultZoneConfig)
        }

        return destinationZoneConfig!!.zoneConfig
    }

    fun update(elapsedFrames: Float) {
        val completedFadingOut = fadeOutParams?.update(elapsedFrames)
        if (completedFadingOut == true) {
            changeZone(destinationZoneConfig!!)
        }

        val completedFadingIn = fadeInParams?.update(elapsedFrames)
        if (completedFadingIn == true) {
            destinationZoneConfig = null
            fadeInParams = null
        }
    }

    fun drawScreenFade() {
        val params = fadeOutParams ?: fadeInParams ?: return
        val opacity = params.getOpacity()
        UiElementHelper.drawBlackScreenCover(1f - opacity)
    }

    fun isChangingZones(): Boolean {
        return destinationZoneConfig != null
    }

    fun onZoneIn() {
        movePlayerToStartingPosition()
        SceneManager.getCurrentScene().assignNearestSubAreaOnZoneIn()
        fadeOutParams = null
        fadeInParams = FadeParameters.fadeIn(secondsToFrames(1f))
    }

    fun returnToHomePoint(restore: Boolean) {
        beginChangeZone(DatId("z381"), options = ZoneChangeOptions(fullyRevive = restore))
    }

    fun beginChangeZone(interaction: ZoneInteraction, options: ZoneChangeOptions = ZoneChangeOptions()) {
        val destinationZoneLine = interaction.destId!!
        return beginChangeZone(destinationZoneLine, options)
    }

    fun beginChangeZone(destinationZoneLine: DatId, options: ZoneChangeOptions = ZoneChangeOptions()) {
        if (isChangingZones()) { return }

        val zoneId = destinationZoneLine.toZoneId()

        val zoneInteractions = SceneManager.getCurrentScene().getZoneInteractions()
        val intraZoneInteraction = zoneInteractions.firstOrNull { it.sourceId == destinationZoneLine }

        val zoneConfig = if (intraZoneInteraction != null) {
            val currentZoneId = SceneManager.getCurrentScene().getMainArea().id
            ZoneConfig(zoneId = currentZoneId, entryId = destinationZoneLine)
        } else {
            ZoneConfig(zoneId = zoneId, entryId = destinationZoneLine)
        }

        println("Changing zones to: $destinationZoneConfig via [${destinationZoneLine}]")
        beginChangeZone(zoneConfig, options)
    }

    fun beginChangeZone(toZone: ZoneConfig, options: ZoneChangeOptions = ZoneChangeOptions()) {
        if (isChangingZones()) { return }
        BattleEngine.releaseDependents(ActorManager.player())
        destinationZoneConfig = DestinationZoneConfig(toZone, options)
        fadeOutParams = FadeParameters.fadeOut(secondsToFrames(1f))
    }

    private fun changeZone(destination: DestinationZoneConfig) {
        val toZone = destination.zoneConfig

        println("Changing zones from ${MainTool.zoneConfig} to: [${toZone.zoneId}]")
        val zonePrev = document.getElementById("ZonePrev") as HTMLInputElement
        zonePrev.value = toZone.zoneId.toString()

        UiStateHelper.clear()

        val options = destination.options
        if (options.fullyRevive) {
            ActorManager.player().onRevive()
        }

        val currentZoneId = SceneManager.getCurrentScene().getMainArea().id
        if (toZone.zoneId == currentZoneId) {
            onZoneIn()
            return
        }

        SceneManager.unloadScene()

        MapDrawer.clear()
        ActorManager.clear()
        FrameCoherence.clear()
        EffectManager.clearAllEffects()

        ParticleGenTool.clear()
        RoutineViewer.clear()

        MainTool.zoneConfig = toZone
        MainTool.setupZone = false
    }

    private fun movePlayerToStartingPosition() {
        val zoneConfig = destinationZoneConfig!!.zoneConfig

        val startingPosition = if (zoneConfig.startPosition != null) {
            zoneConfig.startPosition
        } else if (zoneConfig.entryId != null) {
            val destEntry = SceneManager.getCurrentScene().getZoneInteractions()
                .firstOrNull { it.sourceId == zoneConfig.entryId }

            if (destEntry != null) {
                destEntry.position + Vector3f(0f, destEntry.size.y/2f, 0f)
            } else {
                OnceLogger.warn("[${zoneConfig.entryId}] Couldn't find entry");
                Vector3f()
            }
        } else {
            val firstEntry = SceneManager.getCurrentScene().getZoneInteractions()
                .firstOrNull { it.isZoneEntrance() }

            if (firstEntry != null) {
                firstEntry.position + Vector3f(0f, firstEntry.size.y/2f, 0f)
            } else {
                OnceLogger.warn("[${zoneConfig.zoneId}] Didn't find any entry-points")
                getFallbackStartingPosition() ?: Vector3f(0f, -50f, 0f)
            }
        }

        ActorManager.player().position.copyFrom(startingPosition)
    }

    private fun getFallbackStartingPosition(): Vector3f? {
        val scene = SceneManager.getCurrentScene()

        val npcNames = listOf("Home Point", "Cavernous Maw", "Dimensional Portal", "Spatial Displacement", "Undulating Confluence", "Somnial Threshold", "Goblin Footprint", "Planar Rift", "Moogle")
        val zoneNpcs = scene.getNpcs().npcs

        for (npcName in npcNames) {
            val first = zoneNpcs.firstOrNull { it.name.contains(npcName) } ?: continue
            return first.info.position
        }

        return null
    }

}