package xim.util

object OncePerFrame {

    private val keys = HashSet<String>()

    fun onNewFrame() {
        keys.clear()
    }

    fun oncePerFrame(key: String, action: () -> Unit) {
        if (keys.contains(key)) { return }
        keys += key
        action.invoke()
    }

}

object OnceLogger {

    private val messages: HashSet<String> = HashSet()

    fun info(message: String) {
        ifFirst(message, console::info)
    }

    fun warn(message: String) {
        ifFirst(message, console::warn)
    }

    fun error(message: String) {
        ifFirst(message, console::error)
    }

    private fun ifFirst(message: String, fn: (String) -> Unit) {
        if (messages.contains(message)) {
            return
        }

        messages.add(message)
        fn.invoke(message)
    }

}