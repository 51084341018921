package xim.poc.ui

import xim.math.Vector2f
import xim.poc.*
import xim.poc.game.PlayerInventory
import xim.poc.game.UiState
import xim.poc.game.UiStateHelper
import xim.resource.InventoryItemInfo
import xim.resource.InventoryItems
import xim.resource.ItemListType

object InventoryUi {

    fun getTargetFilter(inventoryItemInfo: InventoryItemInfo) : (Actor, Actor) -> Boolean {
        return ActionTargetFilter(inventoryItemInfo.targetFlags)::targetFilter
    }

    fun getItems(equipSlotFilter: Int? = null, itemTypeFilter: ItemListType? = null): List<InventoryItemInfo> {
        return if (equipSlotFilter != null) {
            EquipScreenUi.getInventoryItemsByEquipSlot(equipSlotFilter)
        } else if (itemTypeFilter != null) {
            PlayerInventory.inventoryItems.map { InventoryItems[it.id] }.filter { it.type == itemTypeFilter }
        } else {
            PlayerInventory.inventoryItems.map { InventoryItems[it.id] }
        }
    }

    fun getSelectedItemIndex(uiState: UiState): Int {
        return uiState.cursorIndex + uiState.scrollSettings!!.lowestViewableItemIndex
    }

    fun getSelectedItem(uiState: UiState, equipSlotFilter: Int? = null, itemTypeFilter: ItemListType? = null): InventoryItemInfo? {
        val currentItemIndex = getSelectedItemIndex(uiState)
        return getItems(equipSlotFilter = equipSlotFilter, itemTypeFilter = itemTypeFilter).getOrNull(currentItemIndex)
    }

    fun drawInventoryItems(uiState: UiState, equipSlotFilter: Int? = null, itemTypeFilter: ItemListType? = null) {
        val stackPos = uiState.latestPosition ?: return
        val offset = Vector2f(0f, 0f)

        val filteredItems = getItems(equipSlotFilter, itemTypeFilter)

        val scrollSettings = uiState.scrollSettings!!
        for (i in scrollSettings.lowestViewableItemIndex until scrollSettings.lowestViewableItemIndex + scrollSettings.numElementsInPage) {
            if (i >= filteredItems.size) { break }

            val itemInfo = filteredItems[i]
            val color = if (itemInfo.type == ItemListType.UsableItem) { ChatLogColor.Info } else { ChatLogColor.Normal }.color

            UiElementHelper.drawInventoryItem(itemInfo = itemInfo, position = offset + stackPos + Vector2f(2f, 4f), scale = Vector2f(0.5f, 0.5f))
            UiElementHelper.drawString(text = itemInfo.name, color = color, offset = offset + stackPos + Vector2f(22f, 8f))
            offset.y += 18f
        }

        if (UiStateHelper.isFocus(uiState)) { drawSelectedInventoryItem(uiState, equipSlotFilter, itemTypeFilter) }
    }

    private fun drawSelectedInventoryItem(uiState: UiState, equipSlotFilter: Int?, itemTypeFilter: ItemListType? = null ) {
        val currentItem = getSelectedItem(uiState, equipSlotFilter, itemTypeFilter) ?: return
        drawSelectedInventoryItem(currentItem, equipContext = equipSlotFilter != null)
    }

    fun drawSelectedInventoryItem(itemInfo: InventoryItemInfo, equipContext: Boolean = false) {
        if (itemInfo.itemId == 0) { return }

        val description = "${itemInfo.name}\n${itemInfo.description}"
        val formattedDescription = UiElementHelper.formatString(description, maxWidth = 300, textDirection = TextDirection.TopToBottom) ?: return
        val descriptionLines = formattedDescription.numLines.coerceIn(3, 12)

        val menuName = when {
            descriptionLines == 3 -> "menu    iteminfo"
            descriptionLines <= 9 -> "menu    item${descriptionLines}inf"
            else -> "menu    item${descriptionLines}in"
        }

        val menuPos = if (equipContext) {
            val context = UiStateHelper.equipContext
            val offset = Vector2f(context.latestPosition!!.x, context.latestPosition!!.y + context.latestMenu!!.frame.size.y + 2f)
            UiElementHelper.drawMenu(menuName = menuName, offsetOverride = offset) ?: return
        } else {
            UiElementHelper.drawMenu(menuName = menuName, menuStacks = MenuStacks.LogStack) ?: return
        }

        UiElementHelper.drawFormattedString(formattedDescription, offset = menuPos + Vector2f(x = 46f, y = 8f))
        UiElementHelper.drawInventoryItem(itemInfo, position = menuPos + Vector2f(8f, 10f))
    }

    fun useSelectedInventoryItem(currentItem: InventoryItemInfo, target: ActorId) {
        val player = ActorManager.player()

        if (currentItem.itemId >= 4096 && currentItem.itemId < 4096 + 8) { // Crystals
            player.startSynthesizing(SynthesisType.fromItemId(currentItem.itemId), SynthesisStateMachine.getRandomResult())
        } else if (currentItem.itemId == 605) { // Gathering tools
            player.playEmote(6, 0)
        } else if (currentItem.itemId == 1020) {
            player.playEmote(7, 0)
        } else if (currentItem.itemId == 1021) {
            player.playEmote(5, 0)
        } else if (currentItem.type == ItemListType.UsableItem) {
            ActorManager.player().startUsingItem(currentItem, target)
        }

    }

}