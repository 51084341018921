package xim.poc.game

import xim.math.Vector3f
import xim.poc.*
import xim.poc.game.configuration.MonsterDefinition
import xim.poc.game.configuration.MonsterInstance
import xim.resource.DatId
import xim.resource.table.FileTableManager
import xim.resource.table.NpcTable
import xim.util.RandHelper.rand
import kotlin.random.Random

data class WeightedMonsterDef(val monsterDefinition: MonsterDefinition, val weight: Double)

class MonsterTable(val definitions: List<WeightedMonsterDef>) {

    private val sum = definitions.sumOf { it.weight }

    fun getRandomMonster(): MonsterDefinition {
        val rand = Random.nextDouble(sum)
        var cumulativeSum = 0.0

        for (definition in definitions) {
            cumulativeSum += definition.weight
            if (rand < cumulativeSum) {
                return definition.monsterDefinition
            }
        }

        throw IllegalStateException()
    }

}

data class MonsterSpawnerDefinition(val position: Vector3f, val size: Vector3f, val table: MonsterTable, val maxMonsters: Int, val spawnDelay: Float)

class MonsterSlot {
    var spawnDelay = 0f
    var monster: MonsterInstance? = null
}

class MonsterSpawnerInstance(val definition: MonsterSpawnerDefinition) {

    private val slots = Array(definition.maxMonsters) { MonsterSlot() }

    fun update(elapsed: Float) {
        for (slot in slots) {
            val current = slot.monster

            if (current != null) {
                // Deleted monster
                if (ActorManager[current.actorId] == null) {
                    slot.monster = null
                    slot.spawnDelay = definition.spawnDelay
                }
                continue
            }

            // Spawning new monster
            slot.spawnDelay -= elapsed
            if (slot.spawnDelay < 0f && slot.monster == null) {
                spawnMonster(slot)
            }
        }
    }

    private fun spawnMonster(slot: MonsterSlot) {
        val monsterDefinition = definition.table.getRandomMonster()
        val position = definition.position + Vector3f(definition.size.x * rand(), definition.size.y * rand(), definition.size.z * rand())

        val id = ActorManager.nextId()

        val actor = Actor(id = id, name = monsterDefinition.name, position = position, actorController = DefaultEnemyController(), enemy = true)

        actor.onReadyToDraw {
            it.transitionToIdle(0f)
            it.playRoutine(DatId("aper"))
            it.playRoutine(DatId("efon"))
            it.playRoutine(DatId.pop)
        }

        val modelResourcePath = FileTableManager.getFilePath(NpcTable.getNpcModelId(monsterDefinition.look)) ?: return
        actor.actorModel = ActorModel(NpcModel(modelResourcePath))

        actor.setDestinationFacingDir(Vector3f(rand(), 0f, rand()))

        ActorManager.add(actor)
        slot.monster = MonsterInstance(monsterDefinition, id)

        monsterDefinition.onSpawn?.invoke(actor)
    }

}