package xim.poc.game

import xim.math.Vector2f
import xim.poc.ActorManager
import xim.poc.MainTool
import xim.poc.RaySphereCollider
import xim.poc.camera.CameraReference

object ClickHandler {

    fun handleClickEvents() {
        val clickEvent = MainTool.platformDependencies.keyboard.getClickEvents().lastOrNull() ?: return
        if (clickEvent.isLongClick()) {
            PlayerTargetSelector.tryDirectlyTarget(null)
        }

        val ray = CameraReference.getInstance().getWorldSpaceRay(Vector2f(clickEvent.normalizedScreenX, clickEvent.normalizedScreenY)) ?: return

        val visibleActors = ActorManager.getVisibleActors()
        for (actor in visibleActors) {
            val sphere = actor.getBoundingBox().toBoundingSphere()
            if (!RaySphereCollider.intersect(ray, sphere)) { continue }
            if (!PlayerTargetSelector.tryDirectlyTarget(actor)) { continue }
            clickEvent.consumed = true
            break
        }
    }

}