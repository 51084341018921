package xim.poc.tools

import kotlinx.browser.document
import kotlinx.dom.clear
import org.w3c.dom.HTMLBRElement
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import xim.poc.ActorManager
import xim.poc.game.PlayerInventory
import xim.poc.ui.ChatLog
import xim.resource.InventoryItemInfo
import xim.resource.InventoryItems

object InventoryTool {

    private var setup = false

    private lateinit var input: HTMLInputElement
    private lateinit var suggestions: HTMLDivElement

    fun setup() {
        if (setup) { return }
        setup = true

        input = document.getElementById("ItemInput") as HTMLInputElement
        input.oninput = { onChange() }
        input.value = ""

        suggestions = document.getElementById("ItemSuggestions") as HTMLDivElement
    }

    private fun onChange() {
        suggestions.clear()

        val text = input.value.lowercase()
        if (text.length < 3) {
            return
        }

        InventoryItems.getAll()
            .filter { it.name.lowercase().contains(text) }
            .take(10)
            .forEach { addButton(it) }
    }

    private fun addButton(inventoryItemInfo: InventoryItemInfo) {
        val button = document.createElement("button") as HTMLButtonElement
        button.onclick = {
            println("Adding: $inventoryItemInfo")
            PlayerInventory.addItem(inventoryItemInfo.itemId)

            val player = ActorManager.player()
            ChatLog.addLine("${player.name} obtains a ${inventoryItemInfo.logName}.")
        }
        button.innerText = "Add: ${inventoryItemInfo.name}"

        suggestions.appendChild(button)

        val br = document.createElement("br") as HTMLBRElement
        suggestions.appendChild(br)
    }

}