package xim.poc.game.configuration

import xim.poc.Actor
import xim.poc.ActorId
import xim.resource.table.NpcLook

data class MonsterDefinition(
    val name: String,
    val look: NpcLook,
    val onSpawn: ((Actor) -> Unit)? = null
)

class MonsterInstance(val monsterDefinition: MonsterDefinition, val actorId: ActorId)