package xim.poc.tools

import web.dom.document
import web.html.HTMLInputElement
import xim.poc.UiResourceManager
import xim.poc.browser.LocalStorage

object ScreenSettingsTool {

    private var setup = false

    private val windowWidth by lazy { document.getElementById("WindowWidth") as HTMLInputElement }
    private val windowHeight by lazy { document.getElementById("WindowHeight") as HTMLInputElement }
    private val resMultiplier by lazy { document.getElementById("ResMultiplier") as HTMLInputElement }
    private val aspectRatio by lazy { document.getElementById("aspectRatio") as HTMLInputElement }
    private val aspectRatioEnabled by lazy { document.getElementById("aspectRatioEnable") as HTMLInputElement }

    private val uiScale by lazy { document.getElementById("UiScale") as HTMLInputElement }
    private val uiOffsetLeft by lazy { document.getElementById("LeftStackOffset") as HTMLInputElement }
    private val uiOffsetRight by lazy { document.getElementById("RightStackOffset") as HTMLInputElement }

    private val windowStyle by lazy { document.getElementById("WindowStyle") as HTMLInputElement }

    private val cameraInvertX by lazy { document.getElementById("cameraX") as HTMLInputElement }
    private val cameraInvertY by lazy { document.getElementById("cameraY") as HTMLInputElement }

    fun setup() {
        if (setup) { return }
        setup = true

        val config = LocalStorage.getConfiguration().screenSettings
        val cameraConfig = LocalStorage.getConfiguration().cameraSettings
        
        windowWidth.value = config.windowWidth.toString()
        windowHeight.value = config.windowHeight.toString()
        resMultiplier.value = config.resolution.toString()
        aspectRatio.value = config.aspectRatio.toString()
        aspectRatioEnabled.checked = config.aspectRatioEnabled
        
        uiScale.value = config.uiScale.toString()
        uiOffsetLeft.value = config.uiOffsetLeft.toString()
        uiOffsetRight.value = config.uiOffsetRight.toString()
        
        windowStyle.value = config.windowStyle.toString()

        cameraInvertX.value = cameraConfig.invertX.toString()
        cameraInvertY.value = cameraConfig.invertY.toString()

        windowWidth.onchange = { LocalStorage.changeConfiguration { it.screenSettings.windowWidth = windowWidth.value.toIntOrNull() ?: 1280 } }
        windowHeight.onchange = { LocalStorage.changeConfiguration { it.screenSettings.windowHeight = windowHeight.value.toIntOrNull() ?: 1280 } }
        resMultiplier.onchange = { LocalStorage.changeConfiguration { it.screenSettings.resolution = resMultiplier.value.toFloatOrNull() ?: 1f } }
        aspectRatio.onchange = { LocalStorage.changeConfiguration { it.screenSettings.aspectRatio = aspectRatio.value.toFloatOrNull() ?: 1f } }
        aspectRatioEnabled.onchange = { LocalStorage.changeConfiguration { it.screenSettings.aspectRatioEnabled = aspectRatioEnabled.checked } }

        uiScale.onchange = { LocalStorage.changeConfiguration { it.screenSettings.uiScale = uiScale.value.toFloatOrNull() ?: 1f } }
        uiOffsetLeft.onchange = { LocalStorage.changeConfiguration { it.screenSettings.uiOffsetLeft = uiOffsetLeft.value.toFloatOrNull() ?: 1f } }
        uiOffsetRight.onchange = { LocalStorage.changeConfiguration { it.screenSettings.uiOffsetRight = uiOffsetRight.value.toFloatOrNull() ?: 1f } }
        
        windowStyle.onchange = { updateWindowStyle() }

        cameraInvertX.onchange = { LocalStorage.changeConfiguration { it.cameraSettings.invertX = cameraInvertX.checked } }
        cameraInvertY.onchange = { LocalStorage.changeConfiguration { it.cameraSettings.invertY = cameraInvertY.checked } }
    }
    
    private fun updateWindowStyle() {
        val windowStyle = windowStyle.value.toIntOrNull() ?: 1
        LocalStorage.changeConfiguration { it.screenSettings.windowStyle = windowStyle }
        UiResourceManager.setWindowStyle(windowStyle)
    }

}