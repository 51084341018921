package xim.poc

import xim.poc.browser.DatLoader
import xim.poc.browser.DatWrapper
import xim.resource.DatId
import xim.resource.EffectRoutineResource
import xim.resource.RoutineCompleteCallback
import xim.resource.table.FileTableManager
import xim.util.Fps.secondsToFrames

enum class SynthesisType(val animationOffset: Int) {
    Water(0),
    Wind(1),
    Fire(2),
    Earth(3),
    Lightning(4),
    Ice(5),
    Light(6),
    Dark(7),
    ;

    companion object {

        fun fromItemId(itemId: Int): SynthesisType {
            return when (itemId) {
                4096 -> Fire
                4097 -> Ice
                4098 -> Wind
                4099 -> Earth
                4100 -> Lightning
                4101 -> Water
                4102 -> Light
                4103 -> Dark
                else -> throw IllegalStateException("Not a crystal! $itemId")
            }
        }
    }

}

enum class SynthesisState {
    Start,
    Kneeling,
    StartCrafting,
    CraftingInProgress,
    EndCrafting,
    EndCraftingInProgress,
    CraftingComplete,
    Standing,
    Complete,
}

class SynthesisStateMachine(val actor: Actor, val synthesisType: SynthesisType, var resultType: DatId) {

    companion object {
        private val craftingDuration = secondsToFrames(10)

        fun getRandomResult(): DatId {
            return listOf(DatId.synthesisBreak, DatId.synthesisNq, DatId.synthesisNq).random()
        }

    }

    private val resource: DatWrapper

    private var currentState = SynthesisState.Start
    private var craftingTime = 0f

    init {
        val resourcePath = FileTableManager.getFilePath(0x1340 + synthesisType.animationOffset) ?: throw IllegalStateException("No path for crystal type: $synthesisType?")
        resource = DatLoader.load(resourcePath)
    }

    fun update(elapsedFrames: Float) {
        when (currentState) {
            SynthesisState.Start -> handleStart()
            SynthesisState.StartCrafting -> handleStartCrafting()
            SynthesisState.CraftingInProgress -> handleCraftingInProgress(elapsedFrames)
            SynthesisState.EndCrafting -> handleEndCrafting()
            SynthesisState.CraftingComplete -> handleCraftingComplete()
            else -> { } // no-op
        }
    }

    fun isComplete(): Boolean {
        return currentState == SynthesisState.Complete
    }

    private fun handleStart() {
        actor.enqueueModelRoutine(DatId.startResting) { currentState = SynthesisState.StartCrafting }
        currentState = SynthesisState.Kneeling
    }

    private fun handleStartCrafting() {
        if (!resource.isReady()) { return }

        val first = resource.getAsResource().getNullableChildRecursivelyAs(DatId("frst"), EffectRoutineResource::class) ?: throw IllegalStateException("No frst? ${resource.resourceName}")
        EffectManager.registerActorRoutine(actor, ActorContext(actor.id), first)
        currentState = SynthesisState.CraftingInProgress
    }

    private fun handleCraftingInProgress(elapsedFrames: Float) {
        craftingTime += elapsedFrames
        if (craftingTime < craftingDuration) { return }

        val stop = resource.getAsResource().getNullableChildRecursivelyAs(DatId("stcr"), EffectRoutineResource::class) ?: throw IllegalStateException("No stop? ${resource.resourceName}")
        EffectManager.registerActorRoutine(actor, ActorContext(actor.id), stop)

        currentState = SynthesisState.EndCrafting
    }

    private fun handleEndCrafting() {
        if (!resource.isReady()) { return }

        val resultAnim = resource.getAsResource().getNullableChildRecursivelyAs(resultType, EffectRoutineResource::class) ?: throw IllegalStateException("No $resultType? ${resource.resourceName}")
        val result = EffectManager.registerActorRoutine(actor, ActorContext(actor.id), resultAnim)

        result.onComplete = RoutineCompleteCallback { currentState = SynthesisState.CraftingComplete }
        currentState = SynthesisState.EndCraftingInProgress
    }

    private fun handleCraftingComplete() {
        actor.enqueueModelRoutine(DatId.stopResting) { currentState = SynthesisState.Complete }
        currentState = SynthesisState.Standing
    }

}