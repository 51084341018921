package xim.poc.browser

import kotlinx.browser.window
import web.dom.document
import web.html.HTMLElement
import xim.poc.gl.GpuTimer

class JsViewExecutor(val jsWindow: JsWindow) : ViewExecutor {

    private var lastTimestamp: Double = 0.0

    override fun beginDrawing(loopFunction: (t: Double) -> Unit) {
        loop(loopFunction,0.0)
    }

    private fun loop(loopFunction: (t: Double) -> Unit, timestamp: Double) {
        GpuTimer.report()

        GpuTimer.start()
        jsWindow.clear()

        val elapsed = timestamp - lastTimestamp

        try {
            loopFunction.invoke(elapsed)
        } catch (t: Throwable) {
            val errorElem = document.getElementById("error") as HTMLElement
            errorElem.innerText = t.stackTraceToString()
            throw t
        }

        jsWindow.webgl.flush()
        lastTimestamp = timestamp

        GpuTimer.end()
        window.requestAnimationFrame { loop(loopFunction, it) }
    }

}