package xim.poc

import xim.math.Matrix4f
import xim.math.Vector3f
import xim.poc.browser.DatLoader
import xim.poc.browser.Keyboard
import xim.poc.browser.ParserContext
import xim.poc.camera.Camera
import xim.poc.tools.ZoneConfig
import xim.poc.tools.ZoneObjectTool
import xim.poc.tools.isCheckBox
import xim.resource.*
import xim.resource.table.*
import xim.util.OnceLogger.error
import xim.util.OnceLogger.warn

data class ModelTransform(val translation: Vector3f = Vector3f(), val rotation: Vector3f = Vector3f())

/* accessCount is used to distinguish between left/right panels for a given doors */
class ModelTransforms(val transforms: HashMap<Int, ModelTransform> = HashMap(), var accessCount: Int = 0)

class AreaTransform (val transform: Matrix4f, val inverseTransform: Matrix4f)

class Area(val resourceId: String, val root: DirectoryResource, val id: Int) {

    val modelRoutines = HashMap<DatId, EffectRoutineInstance>()
    val modelTransforms = HashMap<DatId, ModelTransforms>()

    private val zoneResource = root.getFirstChildByTypeRecursively(ZoneResource::class) ?: throw IllegalStateException("[$resourceId][${root.id}] Failed to find a ZoneResource")
    private val interactions = root.collectByTypeRecursive(ZoneInteractionResource::class).flatMap { it.interactions }

    val transform = Matrix4f()
    var invTransform = Matrix4f()

    fun getZoneResource(): ZoneResource {
        return zoneResource
    }

    fun getInteractions(): List<ZoneInteraction> {
        return interactions
    }

    fun getVisibleZoneObjects(camera: Camera, cullContext: CullContext, collisionProperties: List<CollisionProperty>): Set<ZoneObjId>? {
        val zoneResource = getZoneResource()

        val debugOverride = ZoneObjectTool.cullingGroupOverride()
        val collisionCullingTables = collisionProperties.mapNotNull { debugOverride ?: it.cullingTableIndex }
            .mapNotNull { zoneResource.zoneCullingTables.getOrNull(it) }
            .firstOrNull() ?: emptySet()

        val visibleIds = Culler.getZoneObjects(camera, zoneResource, cullContext, collisionCullingTables)
        return visibleIds.ifEmpty { null }
    }

    fun getModelTransform(datId: DatId): ModelTransforms? {
        return modelTransforms[datId]
    }

    fun getModelTransformForRendering(datId: DatId): ModelTransform? {
        val transforms = modelTransforms[datId] ?: return null

        val access = transforms.accessCount
        transforms.accessCount += 1

        return transforms.transforms[access]
    }

    fun updateModelTransform(datId: DatId, index: Int, updater: (ModelTransform) -> Unit) {
        val transforms = modelTransforms.getOrPut(datId) { ModelTransforms() }
        val transform = transforms.transforms.getOrPut(index) { ModelTransform() }
        updater.invoke(transform)
    }

    fun registerEffects() {
        if (root.hasSubDirectory(DatId.effect)) {
            val effectDir = root.getSubDirectory(DatId.effect)
            registerEffectsRecursively(effectDir)
        }

        if (root.hasSubDirectory(DatId.model)) {
            val modelDir = root.getSubDirectory(DatId.model)
            registerEffectsRecursively(modelDir)
        }
    }

    private fun registerEffectsRecursively(directoryResource: DirectoryResource) {
        directoryResource.collectByTypeRecursive(EffectResource::class)
            .filter { it.particleGenerator.autoRun }
            .forEach { effect -> EffectManager.registerEffect(ZoneAssociation(this), effect) }

        directoryResource.collectByTypeRecursive(EffectRoutineResource::class)
            .filter { it.effectRoutineDefinition.autoRunHeuristic }
            .forEach { EffectManager.registerRoutine(ZoneAssociation(this), it) }
    }

}

private class SubAreaManager(ids: List<Int>) {

    val subAreaIds = ids.filter { it != 0 }.toSet()
    val subAreas = HashMap<Int, Area>()

    init {
        subAreaIds.forEach { fetchSubArea(it) }
    }

    fun isFullyLoaded(): Boolean {
        return subAreaIds.size == subAreas.size
    }

    fun releaseAll() {
        subAreas.values.forEach { it.root.release(); DatLoader.release(it.resourceId) }
    }

    private fun fetchSubArea(subAreaId: Int) {
        // Everything except [Escha - Ru'Aun] fits in the first section of the file-table. Not sure if these ranges are exact.
        val fileTableOffset = if (subAreaId < 0x271) { 0x64 } else { 0x14768 - 0x271 }

        val fileTableIndex = subAreaId + fileTableOffset
        val path = FileTableManager.getFilePath(fileTableIndex) ?: throw IllegalStateException("No such sub-area: $fileTableIndex")

        println("Fetching sub-area (0x${subAreaId.toString(0x10)}) -> $path")

        DatLoader.load(path, parserContext = ParserContext(zoneResource = true)).onReady {
            subAreas[subAreaId] = Area(path, it.getAsResource(), subAreaId)
        }
    }

}

class Scene(val config: ZoneConfig) {

    companion object {
        val collisionBoxSize = Vector3f(0.5f, 1f, 0.5f)
        val interactionBoxSize = Vector3f(0.05f, 1f, 0.05f)
    }

    private lateinit var mainArea: Area

    private var shipArea: Area? = null
    private var shipRoute: Route? = null
    private var shipRouteProgress = 0f

    private lateinit var zoneNpcList: ZoneNpcList
    private lateinit var subAreaManager: SubAreaManager

    private var interactions: Set<ZoneInteraction> = emptySet()
    private var subArea: Area? = null

    init {
        val resourceId = ZoneIdToResourceId.map(config.zoneId)
        val resource = FileTableManager.getFilePath(resourceId) ?: throw IllegalStateException("$config doesn't have a resource?")
        DatLoader.load(resource, parserContext = ParserContext(zoneResource = true)).onReady {
            mainArea = Area(resource, it.getAsResource(), config.zoneId).also { area -> area.registerEffects() }
            maybeRegisterShipArea()
            subAreaManager = SubAreaManager(mainArea.getInteractions().filter { z -> z.isSubArea() }.map { z -> z.param })
        }

        val npcDat = ZoneNpcTableProvider.getNpcDat(config.zoneId)
        DatLoader.load(npcDat).onReady {
            zoneNpcList = ZoneNpcTableProvider.parseNpcs(config.zoneId, it)
            populateNpcActors()
        }
    }

    fun isFullyLoaded(): Boolean {
        return this::mainArea.isInitialized && this::zoneNpcList.isInitialized
                && this::subAreaManager.isInitialized && subAreaManager.isFullyLoaded()
    }

    fun release() {
        mainArea.root.release()
        DatLoader.release(mainArea.resourceId)
        subAreaManager.releaseAll()
    }

    fun getMainArea() = mainArea

    fun getSubArea() = subArea

    fun getAreas() = listOfNotNull(mainArea, shipArea, subArea)

    fun isCurrentSubArea(subAreaId: Int): Boolean {
        return subArea?.id == subAreaId
    }

    // This resolves the issue of logging out in a sub-area (like houses in [Selbina])
    // Is there a better way to do it, though?
    fun assignNearestSubAreaOnZoneIn() {
        val playerPos = ActorManager.player().position

        checkInteractions()
        if (subArea != null) { return }

        val nearestSubArea = getZoneInteractions()
            .filter { it.isSubArea() }
            .minByOrNull { Vector3f.distance(it.position, playerPos) }

        nearestSubArea?.let { setSubArea(it.param) }
    }

    fun getMainAreaRootDirectory(): DirectoryResource {
        return mainArea.root
    }

    fun getNpcs(): ZoneNpcList {
        return zoneNpcList
    }

    private fun populateNpcActors() {
        for (npc in zoneNpcList.npcs) {
            val actor = Actor(ActorId(npc.id), npc.name, npc.info.position, facingDir = npc.info.rotation)

            val model = createNpcModel(actor, npc)
            if (model == null) {
                warn("[${npc.name}] Failed to generate NPC model")
                continue
            }

            actor.actorModel = ActorModel(model)
            ActorManager.add(actor)
        }
    }

    private fun createNpcModel(actor: Actor, npc: Npc): Model? {
        val npcLook = npc.info.look

        return if (npcLook.type == 0) {
            actor.onReadyToDraw {
                it.transitionToIdle(0f)
                it.playRoutine(DatId("aper"))
                it.playRoutine(DatId("efon"))
                it.playRoutine(DatId.pop)
                it.loopRoutine(DatId("@scd"))
            }
            val modelResourcePath = FileTableManager.getFilePath(NpcTable.getNpcModelId(npcLook)) ?: return null
            val additionalAnimationId = FileTableManager.getFilePath(NpcTable.getAdditionalAnimationId(npcLook))
            NpcModel(modelResourcePath, listOfNotNull(additionalAnimationId))
        } else if (npcLook.type == 1) {
            actor.onReadyToDraw { it.playRoutine(DatId.pop) }
            val raceGenderConfig = RaceGenderConfig.from(npcLook.race) ?: return null
            PcModel(raceGenderConfig, actor, npcLook)
        } else if (npcLook.type == 2) {
            actor.onReadyToDraw {
                it.playRoutine(DatId.closed)
                it.playRoutine(DatId("inte"))
            }
            val datId = npc.info.datId ?: return null
            ZoneObjectModel(datId, this)
        } else if (npcLook.type == 3) {
            val datId = npc.info.datId ?: return null
            ZoneObjectModel(datId, this)
        } else {
            warn("[${actor.name}] Unknown NPC type: ${npcLook.type}")
            null
        }
    }

    fun update(elapsedFrames: Float) {
        mainArea.modelTransforms.forEach { it.value.accessCount = 0 }
        mainArea.modelRoutines.entries.removeAll { it.value.isComplete() }

        if (!isCheckBox("pauseShip")) { updateShipRoute(elapsedFrames) }
    }

    fun getZoneInteractions(): List<ZoneInteraction> {
        return mainArea.getInteractions()
    }

    fun collideActor(actor: Actor, elapsedFrames: Float) : Map<Area, List<CollisionProperty>> {
        if (!actor.shouldApplyCollision()) {
            return emptyMap()
        }

        if (actor.isPlayer() && MainTool.platformDependencies.keyboard.isKeyPressedOrRepeated(Keyboard.Key.C)) {
            actor.position += actor.currentVelocity + Vector3f(0f, 0.1f * elapsedFrames, 0f)
            return emptyMap()
        }

        val actorModel = actor.actorModel ?: return emptyMap()

        val areas = getAreas()

        if (!actorModel.isMovementLocked() && actor.currentVelocity.magnitudeSquare() > 0.001) {
            actor.setDestinationFacingDir(actor.currentVelocity)
            Collider.updatePosition(areas, actor, actor.currentVelocity, collisionBoxSize, stopOnCollision = false)
        }

        if (actor.isPlayer() && MainTool.platformDependencies.keyboard.isKeyPressedOrRepeated(Keyboard.Key.SPACE)) {
            return emptyMap()
        }

        if (actor.isPlayer() && getNearestFloor(actor.position) == null) {
            return emptyMap()
        }

        val vertical = Vector3f(0f, 0.5f * elapsedFrames, 0f)
        return Collider.updatePosition(areas, actor, vertical, collisionBoxSize, stopOnCollision = true)
    }

    fun getNearestFloor(position: Vector3f): Vector3f? {
        return Collider.nearestFloor(position + Vector3f(0f, -0.01f, 0f), getAreas())
    }

    fun getVisibleZoneObjects(camera: Camera, cullContext: CullContext, collisionProperties: ActorCollision = ActorCollision()) : Map<Area, Set<ZoneObjId>?> {
        return getAreas().associateWith {
            val perAreaCollisionProperties = collisionProperties.collisionsByArea[it] ?: emptyList()

            val areaTransform = getAreaTransform(it)

            val cullingCamera = if (areaTransform != null) { camera.transform(areaTransform) } else { camera }
            it.getVisibleZoneObjects(cullingCamera, cullContext, perAreaCollisionProperties)
        }
    }

    fun checkInteractions(): ZoneInteraction? {
        val player = ActorManager.player()
        val currentInteractions = getZoneInteractions()
            .filter { interactsWith(player, it) }
            .toSet()

        val newInteractions = currentInteractions - interactions
        interactions = currentInteractions

        manageSubArea(newInteractions)
        return shouldZone()
    }

    fun getModelTransformForRendering(area: Area, datId: DatId): ModelTransform? {
        val areaTransform = area.getModelTransformForRendering(datId)
        if (area == mainArea || areaTransform != null) { return areaTransform }
        return mainArea.getModelTransformForRendering(datId)
    }

    private fun shouldZone(): ZoneInteraction? {
        return interactions.firstOrNull { it.isZoneLine() }
    }

    private fun manageSubArea(newInteractions: Set<ZoneInteraction>) {
        val subAreas = newInteractions.filter { it.isSubArea() }

        // Some zones have a large "clear" interaction that intersects with everything
        // There should be at most one intersection with a non-zero param
        val nonZeroSubAreaCount = subAreas.distinctBy { it.param }.count { it.param != 0 }
        if (nonZeroSubAreaCount > 1) { throw IllegalStateException("How can this be") }
        if (subAreas.isEmpty()) { return }

        if (subAreas.all { it.param == 0 }) {
            removeSubArea()
            return
        }

        val subAreaInteraction = if (subAreas.size == 1) { subAreas.first() } else { subAreas.first { it.param != 0 } }
        setSubArea(subAreaInteraction.param)
    }

    private fun interactsWith(actor: Actor, zoneInteraction: ZoneInteraction): Boolean {
        val interactionBox = BoundingBox.from(zoneInteraction.position, zoneInteraction.orientation, zoneInteraction.size, verticallyCentered = true)
        val actorBox = BoundingBox.from(actor.position, Vector3f(0f, actor.facingDir, 0f), interactionBoxSize, verticallyCentered = false)

        if (!Sphere.intersects(interactionBox.toBoundingSphere(), actorBox.toBoundingSphere())) {
            return false
        }

        return SatCollider.boxBoxIntersection(interactionBox, actorBox)
    }

    private fun setSubArea(subAreaId: Int) {
        if (isCurrentSubArea(subAreaId)) { return }

        // This is necessary for NPC lighting, since lighting-links may come from the newly loaded sub-area collision
        FrameCoherence.clearCachedNpcCollision()
        removeSubArea()

        println("Activating sub-area (0x${subAreaId.toString(0x10)})")
        subArea = subAreaManager.subAreas[subAreaId]?.also { it.registerEffects() }
    }

    private fun removeSubArea() {
        val current = subArea ?: return
        subArea = null

        println("Deactivating sub-area (0x${current.id.toString(0x10)})")
        EffectManager.clearEffects(ZoneAssociation(current))
    }

    private fun maybeRegisterShipArea() {
        val modelDir = mainArea.root.getSubDirectory(DatId.model)

        val shipDir = modelDir.getNullableSubDirectory(DatId.ship) ?: return
        shipArea = Area(mainArea.resourceId, shipDir, 0)

        shipRoute = getMainAreaRootDirectory().getSubDirectory(DatId.model)
            .collectByType(RouteResource::class)
            .firstOrNull()
            ?.route

        if (shipRoute == null) { error("Ship area has no routes?") }

        println("Registered ship area! -> ${shipArea!!.getZoneResource().id}")
    }

    fun getAreaTransform(area: Area? = null): AreaTransform? {
        val ship = shipArea ?: return null
        if (area == shipArea) { return null }

        return AreaTransform(ship.transform, ship.invTransform)
    }

    fun setShipRoute(id: DatId) {
        shipRoute = getMainAreaRootDirectory().getNullableChildRecursivelyAs(id, RouteResource::class)?.route ?: return
        shipRouteProgress = 0f
    }

    fun setShipRouteProgress(progress: Float) {
        val route = shipRoute ?: return
        shipRouteProgress = route.totalLength * progress
        updateShipRoute(0f)
    }

    private fun updateShipRoute(elapsedFrames: Float) {
        val ship = shipArea ?: return
        val route = shipRoute ?: return

        shipRouteProgress += elapsedFrames

        val position = route.getPosition(shipRouteProgress)
        val yRotation = route.getFacingDir(shipRouteProgress)

        ship.transform.identity()
            .translateInPlace(position)
            .rotateYInPlace(yRotation)

        ship.invTransform.identity()
            .rotateYInPlace(-yRotation)
            .translateInPlace(position * -1f)
    }

}

object SceneManager {

    private var currentScene: Scene? = null

    fun getCurrentScene(): Scene {
        return currentScene ?: throw IllegalStateException("Scene is not initialized")
    }

    fun unloadScene() {
        val current = currentScene
        currentScene = null

        if (current != null) {
            current.release()
            DatLoader.release(current.getNpcs().resourceId)
        }
    }

    fun loadScene(config: ZoneConfig) {
        if (currentScene?.config != config) {
            currentScene = Scene(config)
        }
    }

    fun isFullyLoaded(): Boolean {
        return currentScene?.isFullyLoaded() == true
    }

}