package xim.poc.game

import xim.math.Vector3f
import xim.poc.Actor
import xim.poc.ActorManager
import xim.resource.EquipSlot
import xim.util.Fps.secondsToFrames
import kotlin.math.floor
import kotlin.random.Random

class BattleState(val actor: Actor) {

    private val attackRange = Pair(0.5f, 4f)

    private var timeUntilAttack = getAutoAttackInterval()

    fun update(elapsed: Float) {
        timeUntilAttack -= elapsed

        if (!isInAttackRange() || !actor.isEngaged()) {
            timeUntilAttack = timeUntilAttack.coerceAtLeast(secondsToFrames(1f))
        }
    }

    fun resetAutoAttack() {
        timeUntilAttack = getAutoAttackInterval()
    }

    fun canAutoAttack(): Boolean {
        return timeUntilAttack <= 0f && isInAttackRange()
    }

    fun getMainHandDamage(): Int {
        return delayToDamage(getMainDelayInFrames())
    }

    fun getSubHandDamage(): Int {
        return delayToDamage(getSubDelayInFrames())
    }

    private fun isInAttackRange(): Boolean {
        val target = actor.target

        val targetActor = ActorManager[target] ?: return false
        val distance = Vector3f.distance(actor.position, targetActor.position)

        return if (actor.isPlayer()) {
            distance >= attackRange.first && distance <= attackRange.second
        } else {
            distance <= attackRange.second
        }
    }

    private fun getAutoAttackInterval(): Float {
        return getMainDelayInFrames() + getSubDelayInFrames()
    }

    private fun getMainDelayInFrames(): Float {
        return actor.getEquipment(EquipSlot.Main)?.equipmentItemInfo?.weaponInfo?.delay?.toFloat() ?: secondsToFrames(3)
    }

    private fun getSubDelayInFrames(): Float {
        return actor.getEquipment(EquipSlot.Sub)?.equipmentItemInfo?.weaponInfo?.delay?.toFloat() ?: 0f
    }

    private fun delayToDamage(delay: Float): Int {
        val base = delay / 60f
        val remainder = (base % 1f)
        val roundUp = if (Random.nextFloat() < remainder) { 1 } else { 0 }
        return floor(base).toInt() + roundUp
    }

}