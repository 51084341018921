package xim.poc.tools

import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.dom.clear
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLSpanElement
import xim.math.Vector3f
import xim.poc.ActorManager
import xim.poc.Area
import xim.poc.SceneManager

object PlayerPositionTracker {

    private val lastAreas = ArrayList<Area>()

    fun update() {
        (document.getElementById("copyLink") as HTMLButtonElement).onclick = { copyLink() }

        val playerActor = ActorManager.player()
        (document.getElementById("npcPosX") as HTMLInputElement).value = playerActor.position.x.toString()
        (document.getElementById("npcPosY") as HTMLInputElement).value = playerActor.position.y.toString()
        (document.getElementById("npcPosZ") as HTMLInputElement).value = playerActor.position.z.toString()

        updateAreaList()
    }

    fun lastStoredPosition(): Vector3f? {
        val x = (document.getElementById("npcPosX") as HTMLInputElement).value.toFloatOrNull() ?: return null
        val y = (document.getElementById("npcPosY") as HTMLInputElement).value.toFloatOrNull() ?: return null
        val z = (document.getElementById("npcPosZ") as HTMLInputElement).value.toFloatOrNull() ?: return null
        return Vector3f(x, y, z)
    }

    private fun copyLink() {
        val clipboard = window.navigator.clipboard

        val area = SceneManager.getCurrentScene().getMainArea()
        val position = ActorManager.player().position

        val x = position.x.toString()
        val y = position.y.toString()
        val z = position.z.toString()

        clipboard.writeText(window.location.origin + "?zoneId=${area.id}&x=$x&y=$y&z=$z")
    }

    private fun updateAreaList() {
        val areas = SceneManager.getCurrentScene().getAreas()
        if (lastAreas == areas) { return }

        lastAreas.clear()
        lastAreas.addAll(areas)

        val zoneRef = document.getElementById("ZoneRef") as HTMLElement
        zoneRef.clear()

        areas.forEach {
            val child = document.createElement("span") as HTMLSpanElement
            child.innerText = "[${it.id}] ${it.resourceId}"
            zoneRef.appendChild(child)
            zoneRef.appendChild(document.createElement("br"))
        }
    }

}