package xim.poc.browser

import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import web.location.location
import web.storage.localStorage
import xim.poc.Equipment
import xim.poc.RaceGenderConfig
import xim.util.OnceLogger

@Serializable
data class CameraSettings(
    var invertX: Boolean = false,
    var invertY: Boolean = false,
)

@Serializable
data class ScreenSettings(
    var windowWidth: Int = 1280,
    var windowHeight: Int = 720,
    var resolution: Float = 1f,
    var aspectRatio: Float = 1.6f,
    var aspectRatioEnabled: Boolean = false,

    var windowStyle: Int = 1,
    var uiScale: Float = 1f,
    var uiOffsetLeft: Float = 0f,
    var uiOffsetRight: Float = 0f,
)

@Serializable
data class VolumeSettings(
    var backgroundMusicVolume: Float = 0.05f,
    var ambientVolume: Float = 0.05f,
    var systemSoundVolume: Float = 0.05f,
    var effectVolume: Float = 0.05f,
)

@Serializable
data class LocalConfiguration(
    var playerRace: RaceGenderConfig = RaceGenderConfig.HumeM,
    var playerFace: Int = 0,
    var playerInventory: ArrayList<Int> = ArrayList(),
    var playerEquipment: Equipment = Equipment(),
    var volumeSettings: VolumeSettings = VolumeSettings(),
    var screenSettings: ScreenSettings = ScreenSettings(),
    var cameraSettings: CameraSettings = CameraSettings(),
)

object LocalStorage {

    private val storage by lazy { localStorage }

    private lateinit var localConfiguration: LocalConfiguration

    fun changeConfiguration(fn: (LocalConfiguration) -> Unit) {
        val config = getConfiguration()
        fn.invoke(config)
        writeConfig()
    }

    fun getConfiguration(): LocalConfiguration {
        if (this::localConfiguration.isInitialized) { return localConfiguration }

        localConfiguration = readConfig()
        return localConfiguration
    }

    fun resetConfiguration() {
        storage.clear()
        location.reload()
    }

    private fun readConfig(): LocalConfiguration {
        val raw = storage.getItem("configuration") ?: return LocalConfiguration()

        return try {
            Json.decodeFromString(raw)
        } catch (e: Exception) {
            OnceLogger.error("Failed to parse config! Clearing storage... ${e.message}")
            LocalConfiguration()
        }
    }

    private fun writeConfig() {
        storage.setItem("configuration", Json.encodeToString(localConfiguration))
    }

}