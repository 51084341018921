package xim.poc

import kotlinx.serialization.Serializable
import xim.poc.browser.DatLoader
import xim.poc.browser.DatWrapper
import xim.resource.*
import xim.resource.table.EquipmentResources
import xim.resource.table.FileTableManager
import xim.resource.table.MainDll
import xim.resource.table.NpcLook
import xim.util.OnceLogger

enum class ItemModelSlot(val prefix: Int) {
    Face(0x0000),
    Head(0x1000),
    Body(0x2000),
    Hands(0x3000),
    Legs(0x4000),
    Feet(0x5000),
    Main(0x6000),
    Sub(0x7000),
    Range(0x8000),
    ;

    companion object {
        fun toSlot(value: Int): ItemModelSlot {
            val prefix = value and 0xF000
            return ItemModelSlot.values().firstOrNull { it.prefix == prefix } ?: throw IllegalStateException("Failed to match input: ${value.toString(0x10)}")
        }
    }

    fun toEquipSlot(): EquipSlot? {
        return when(this) {
            Face -> null
            Head -> EquipSlot.Head
            Body -> EquipSlot.Body
            Hands -> EquipSlot.Hands
            Legs -> EquipSlot.Legs
            Feet -> EquipSlot.Feet
            Main -> EquipSlot.Main
            Sub -> EquipSlot.Sub
            Range -> EquipSlot.Range
        }
    }

}

@Serializable
enum class RaceGenderConfig(val index: Int) {
    HumeM(index = 1),
    HumeF(index = 2),
    ElvaanM(index = 3),
    ElvaanF(index = 4),
    TaruM(index = 5),
    TaruF(index = 6),
    Mithra(index = 7),
    Galka(index = 8),
    ;

    companion object {
        fun from(race: Int): RaceGenderConfig? {
            return RaceGenderConfig.values().firstOrNull { it.index == race }
        }
    }
}

object PcModelLoader {

    private var preloaded = false

    val raceConfigDat = HashMap<RaceGenderConfig, DatWrapper>()
    val additionalAnimDats = HashMap<RaceGenderConfig, DatWrapper>()

    fun preload() {
        if (preloaded) { return }
        preloaded = true

        for (config in RaceGenderConfig.values()) {
            val fileTableIndex = MainDll.getBaseRaceConfigIndex(config)

            val raceConfig = FileTableManager.getFilePath(fileTableIndex + 0x00) ?: throw IllegalStateException("Failed to resolve race config DAT")
            raceConfigDat[config] = DatLoader.load(raceConfig)

            val additionalAnimationDat = FileTableManager.getFilePath(fileTableIndex + 0x01) ?: throw IllegalStateException("Failed to resolve additional animation DAT")
            additionalAnimDats[config] = DatLoader.load(additionalAnimationDat)

            OnceLogger.info("[$config] Preloaded: [$raceConfig] and [$additionalAnimationDat]")
        }
    }

    fun isFullyLoaded(): Boolean {
        return raceConfigDat.values.all { it.isReady() } && additionalAnimDats.values.all { it.isReady() }
    }

}

interface Model {

    fun isReadyToDraw(): Boolean

    fun getMeshResources(excludeSlots: Set<ItemModelSlot> = emptySet()): List<DirectoryResource>

    fun getSkeletonResource(): SkeletonResource?

    fun getAnimationDirectories(): List<DirectoryResource>

    fun getMainBattleAnimationDirectory(): DirectoryResource?

    fun getSubBattleAnimationDirectory(): DirectoryResource?

    fun getEquipmentModelResource(modelSlot: ItemModelSlot): DirectoryResource?

    fun getMovementInfo(): InfoDefinition?

    fun getMainWeaponInfo(): InfoDefinition?

    fun getSubWeaponInfo(): InfoDefinition?

    fun getRangedWeaponInfo(): InfoDefinition?

    fun isDualWield(): Boolean?

    fun getBlurConfig(): BlurConfig?

    fun getMainAttackIds(): List<DatId>? {
        val dir = getMainBattleAnimationDirectory() ?: return emptyList()
        return dir.collectByTypeRecursive(EffectRoutineResource::class)
            .map { it.id }
            .filter { it.id.startsWith("ati") }
    }

    fun getSubAttackIds(): List<DatId>? {
        val dir = getSubBattleAnimationDirectory() ?: return emptyList()
        return dir.collectByTypeRecursive(EffectRoutineResource::class)
            .map { it.id }
            .filter { it.id.startsWith("bti") }
    }

}

class ModelLook() {

    private val look = HashMap<ItemModelSlot, Int>()

    constructor(faceIndex: Int): this() {
        set(ItemModelSlot.Face, faceIndex)
    }

    fun copy(): ModelLook {
        val copy = ModelLook()
        copy.look.putAll(look)
        return copy
    }

    operator fun set(slot: ItemModelSlot, modelId: Int) {
        look[slot] = modelId
    }

    operator fun get(slot: ItemModelSlot): Int {
        return look[slot] ?: 0
    }

}

class PcModel private constructor(val raceGenderConfig: RaceGenderConfig, val actor: Actor) : Model {

    private val race = PcModelLoader.raceConfigDat[raceGenderConfig]!!
    private val additionalAnims = PcModelLoader.additionalAnimDats[raceGenderConfig]!!

    private val look = ModelLook()
    private val meshResources = HashMap<ItemModelSlot, DatWrapper>()

    constructor(raceGenderConfig: RaceGenderConfig, actor: Actor, modelLook: ModelLook): this(raceGenderConfig, actor) {
        updateLook(modelLook)
    }

    constructor(raceGenderConfig: RaceGenderConfig, actor: Actor, npcLook: NpcLook): this(raceGenderConfig, actor) {
        updateLook(npcLook.look)
    }

    private fun fullyLoaded() : Boolean {
        return listOf(race, additionalAnims).all { it.isReady() } && meshResources.values.all { it.isReady() }
    }

    override fun isReadyToDraw(): Boolean {
        return fullyLoaded()
    }

    override fun getMeshResources(excludeSlots: Set<ItemModelSlot>): List<DirectoryResource> {
        if (!fullyLoaded()) { return emptyList() }
        return meshResources.filter { !excludeSlots.contains(it.key) }.mapNotNull { it.value.getAsResourceIfReady() }
    }

    override fun getSkeletonResource(): SkeletonResource? {
        return race.getAsResourceIfReady()?.getOnlyChildByType(SkeletonResource::class)
    }

    override fun getAnimationDirectories(): List<DirectoryResource> {
        return listOfNotNull(
            getMountAnimationResource()?.getAsResourceIfReady(),
            race.getAsResourceIfReady(),
            additionalAnims.getAsResourceIfReady(),
            getEquipmentModelResource(ItemModelSlot.Face),
            getEquipmentModelResource(ItemModelSlot.Main),
            getEquipmentModelResource(ItemModelSlot.Sub),
            getEquipmentModelResource(ItemModelSlot.Range),
        )
    }

    override fun getMainBattleAnimationDirectory(): DirectoryResource? {
        val dualWield = isDualWield() ?: return null
        val weaponInfo = getMainWeaponInfo() ?: return null

        val offset = if (dualWield) { MainDll.getBaseDualWieldMainHandAnimationIndex(raceGenderConfig) } else { MainDll.getBaseBattleAnimationIndex(raceGenderConfig) }
        val fileIndex = offset + weaponInfo.weaponAnimationType
        val filePath = resolveFile(fileIndex)
        return DatLoader.load(filePath).getAsResourceIfReady()
    }

    override fun getSubBattleAnimationDirectory(): DirectoryResource? {
        val dualWield = isDualWield() ?: return null
        if (!dualWield) { return null }

        val subWeaponInfo = getSubWeaponInfo() ?: return null
        val fileIndex = MainDll.getBaseDualWieldOffHandAnimationIndex(raceGenderConfig) + subWeaponInfo.weaponAnimationType
        val filePath = resolveFile(fileIndex)
        return DatLoader.load(filePath).getAsResourceIfReady()
    }

    override fun getMovementInfo(): InfoDefinition {
        if (!fullyLoaded()) { return InfoDefinition() }

        val raceInfo = race.getAsResource().getOnlyChildByType(InfoResource::class).infoDefinition
        val feetInfo = getInfo(ItemModelSlot.Feet) ?: throw IllegalStateException("No info for feet?")
        return InfoDefinition(movementType = raceInfo.movementType, movementChar = feetInfo.movementChar, shakeFactor = feetInfo.shakeFactor)
    }

    override fun getMainWeaponInfo(): InfoDefinition? {
        return getInfo(ItemModelSlot.Main)
    }

    override fun getSubWeaponInfo(): InfoDefinition? {
        return getInfo(ItemModelSlot.Sub)
    }

    override fun getRangedWeaponInfo(): InfoDefinition? {
        return getInfo(ItemModelSlot.Range)
    }

    override fun getBlurConfig(): BlurConfig? {
        return null
    }

    fun copyLook(): ModelLook {
        return look.copy()
    }

    fun updateLook(newLook: ModelLook) {
        for (slot in ItemModelSlot.values()) {
            val current = look[slot]
            val new = newLook[slot]
            if (meshResources[slot] != null && current == new) { continue }
            onSwapEquipment(slot, new)
        }
    }

    private fun onSwapEquipment(modelSlot: ItemModelSlot, newModelId: Int) {
        val context = ActorContext(actor.id)

        val oldModelId = look[modelSlot]
        look[modelSlot] = newModelId

        val oldDat = resolveEquipmentResource(modelSlot, oldModelId)
        if (oldDat.isReady()) {
            val unloadEvent = oldDat.getAsResource().getNullableChildRecursivelyAs(DatId.eventOnUnload, EffectRoutineResource::class)
            if (unloadEvent != null) { EffectManager.registerActorRoutine(actor, context, unloadEvent) }

            val itemEffectId = when (modelSlot) {
                ItemModelSlot.Main -> DatId("!w00")
                ItemModelSlot.Sub -> DatId("!w10")
                ItemModelSlot.Body -> DatId("!bd0")
                else -> null
            }

            if (itemEffectId != null) {
                val itemEffect = oldDat.getAsResource().getNullableChildRecursivelyAs(itemEffectId, EffectRoutineResource::class)
                if (itemEffect != null) { EffectManager.registerActorRoutine(actor, context, itemEffect) }
            }
        }

        val newDat = resolveEquipmentResource(modelSlot, newModelId)
        newDat.onReady {
            val loadEvent = newDat.getAsResource().getNullableChildRecursivelyAs(DatId.eventOnLoad, EffectRoutineResource::class)
            if (loadEvent != null) { EffectManager.registerActorRoutine(actor, context, loadEvent) }

            val bodyEffect = newDat.getAsResource().getNullableChildRecursivelyAs(DatId("!bd1"), EffectRoutineResource::class)
            if (bodyEffect != null) { EffectManager.registerActorRoutine(actor, context, bodyEffect) }
        }

        meshResources[modelSlot] = newDat
    }

    override fun isDualWield(): Boolean? {
        return actor.isDualWield()
    }

    override fun getEquipmentModelResource(modelSlot: ItemModelSlot): DirectoryResource? {
        return meshResources[modelSlot]?.getAsResourceIfReady()
    }

    private fun getEquipmentModelResource(): Map<ItemModelSlot, DirectoryResource?> {
        return ItemModelSlot.values().associateWith { getEquipmentModelResource(it) }
    }

    private fun resolveEquipmentResource(modelSlot: ItemModelSlot, modelId: Int): DatWrapper {
        val datResource = EquipmentResources.get(raceGenderConfig, modelSlot, modelId)
        return DatLoader.load(datResource, lazy = true)
    }

    private fun getInfo(slot: ItemModelSlot): InfoDefinition? {
        val directory = getEquipmentModelResource()[slot] ?: return null

        return directory.getNullableChildRecursivelyAs(DatId.info, InfoResource::class)
            ?.infoDefinition
            ?: throw IllegalStateException("$slot doesn't have info...?")
    }

    private fun resolveFile(fileIndex: Int): String {
        return FileTableManager.getFilePath(fileIndex) ?: throw IllegalStateException("Couldn't resolve battle aimations: ${fileIndex.toString(0x10)}")
    }

    private fun getMountAnimationResource(): DatWrapper? {
        actor.getMount() ?: return null

        val index = MainDll.getActionAnimationIndex(raceGenderConfig) + 0x05
        val resource = FileTableManager.getFilePath(index) ?: return null

        return DatLoader.load(resource)
    }

}

class NpcModel (resourcePath: String, additionalAnimationPaths: List<String> = emptyList()) : Model {

    val resource = DatLoader.load(resourcePath, lazy = true)
    val additionalAnimations = additionalAnimationPaths.map { DatLoader.load(it, lazy = true) }

    private val blurLink = DatLink<BlurResource>(DatId.zero)

    override fun isReadyToDraw(): Boolean {
        return resource.isReady()
    }

    override fun getMeshResources(excludeSlots: Set<ItemModelSlot>): List<DirectoryResource> {
        return resource.getAsResourceIfReady()?.let { listOf(it) } ?: emptyList()
    }

    override fun getSkeletonResource(): SkeletonResource? {
        return resource.getAsResourceIfReady()?.getFirstChildByTypeRecursively(SkeletonResource::class)
    }

    override fun getAnimationDirectories(): List<DirectoryResource> {
        val baseAnimations = resource.getAsResourceIfReady() ?: return emptyList()
        val allAnimations = additionalAnimations.mapNotNull { it.getAsResourceIfReady() } + baseAnimations
        return allAnimations.flatMap { it.getSubDirectoriesRecursively() + it }
    }

    override fun getMainBattleAnimationDirectory(): DirectoryResource? {
        return resource.getAsResourceIfReady()
    }

    override fun getSubBattleAnimationDirectory(): DirectoryResource? {
        return resource.getAsResourceIfReady()
    }

    override fun getEquipmentModelResource(modelSlot: ItemModelSlot): DirectoryResource? {
        return resource.getAsResourceIfReady()
    }

    override fun getMovementInfo(): InfoDefinition? {
        return resource.getAsResourceIfReady()?.getFirstChildByTypeRecursively(InfoResource::class)?.infoDefinition
    }

    override fun getMainWeaponInfo(): InfoDefinition? {
        return null
    }

    override fun getSubWeaponInfo(): InfoDefinition? {
        return null
    }

    override fun getRangedWeaponInfo(): InfoDefinition? {
        return null
    }

    override fun isDualWield(): Boolean? {
        return false
    }

    override fun getBlurConfig(): BlurConfig? {
        val root = resource.getAsResourceIfReady() ?: return null
        return blurLink.getOrPut { root.collectByTypeRecursive(BlurResource::class).firstOrNull() }?.blurConfig
    }

}

class ZoneObjectModel(val id: DatId, val scene: Scene): Model {

    private val animDir by lazy {
        scene.getMainAreaRootDirectory().getNullableChildRecursivelyAs(id, DirectoryResource::class)
    }

    override fun isReadyToDraw(): Boolean {
        return true
    }

    override fun getMeshResources(excludeSlots: Set<ItemModelSlot>): List<DirectoryResource> {
        return emptyList()
    }

    override fun getSkeletonResource(): SkeletonResource? {
        return null
    }

    override fun getAnimationDirectories(): List<DirectoryResource> {
        return  if (animDir != null) { listOf(animDir!!) } else { emptyList() }
    }

    override fun getMainBattleAnimationDirectory(): DirectoryResource? {
        return null
    }

    override fun getSubBattleAnimationDirectory(): DirectoryResource? {
        return null
    }

    override fun getEquipmentModelResource(modelSlot: ItemModelSlot): DirectoryResource? {
        return null
    }

    override fun getMovementInfo(): InfoDefinition? {
        return null
    }

    override fun getMainWeaponInfo(): InfoDefinition? {
        return null
    }

    override fun getSubWeaponInfo(): InfoDefinition? {
        return null
    }

    override fun getRangedWeaponInfo(): InfoDefinition? {
        return null
    }

    override fun isDualWield(): Boolean? {
        return null
    }

    override fun getBlurConfig(): BlurConfig? {
        return null
    }

}