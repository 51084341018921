package xim.poc.game

enum class StatusEffect(val id: Int) {
    BlazeSpikes(34),
    IceSpikes(35),

    Enfire(94),
    Enblizzard(95),

    DoubleUp(308),
    Bust(309),
    FightersRoll(310),

    Indicolure(612),
}

enum class AbilityIds(val id: Int) {
    CallWyvern(573),
    Assault(600),
    Retreat(601),
    Release(602),
    BloodPactRage(603),
    PhantomRoll(609),
    FightersRoll(610),
    DoubleUp(635),
    QuickDraw(636),
    BloodPactWard(684),
    Samba(694),
    Waltz(695),
    Step(710),
    FlourishI(711),
    Jig(712),
    FlourishII(725),
    FlourishIII(775),
}