import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import xim.poc.MainTool
import xim.poc.browser.BrowserPlatformDependencies
import xim.poc.browser.PlatformDependencies

private var started = false
private lateinit var platform: PlatformDependencies

fun main() {
    platform = BrowserPlatformDependencies.get(canvasId = "canvas")

    if (window.location.hostname == "localhost") {
        onStart()
    }

    val startButton = (document.getElementById("start-container") as HTMLElement)
    startButton.onclick = { onStart() }
}

fun onStart() {
    if (started) { return }
    started = true

    val startContainer = document.getElementById("start-container") as HTMLDivElement
    startContainer.remove()

    MainTool.run(platform)
}
