package xim.poc

import xim.math.Vector3f
import xim.poc.gl.MeshBuffer
import xim.resource.*

enum class FootState {
    Grounded,
    Lifting,
    Ungrounded,
    Landing,
    ;

    fun next(isTouching: Boolean): FootState {
        return when (this) {
            Grounded -> if (isTouching) { Grounded } else { Lifting }
            Lifting -> if (isTouching) { Grounded } else { Ungrounded }
            Landing -> if (isTouching) { Grounded } else { Ungrounded }
            Ungrounded -> if (isTouching) { Landing } else { Ungrounded }
        }
    }
}

class ActorModel(val model: Model) {

    private var skeletonInstance: SkeletonInstance? = null
    val skeletonAnimationCoordinator = SkeletonAnimationCoordinator()

    private var leftFootState: FootState = FootState.Grounded
    private var rightFootState: FootState = FootState.Grounded

    private var animationLockDuration = 0f
    private var movementLockDuration = 0f

    private var displayRangedDuration = 0f

    private val hiddenUntilIdle = HashSet<ItemModelSlot>()

    fun update(elapsedFrames: Float) {
        if (skeletonInstance == null) {
            val resource = model.getSkeletonResource() ?: return
            skeletonInstance = SkeletonInstance(resource)
        }

        skeletonAnimationCoordinator.update(elapsedFrames)
        movementLockDuration -= elapsedFrames
        animationLockDuration -= elapsedFrames
        displayRangedDuration -= elapsedFrames

        leftFootState = leftFootState.next(skeletonInstance!!.isLeftFootTouchingGround())
        rightFootState = rightFootState.next(skeletonInstance!!.isRightFootTouchingGround())
    }

    fun getHiddenSlots(): Set<ItemModelSlot> {
        return hiddenUntilIdle + if (displayRangedDuration > 0f) {
            setOf(ItemModelSlot.Main, ItemModelSlot.Sub)
        } else {
            setOf(ItemModelSlot.Range)
        }
    }

    fun getMeshes(): List<MeshBuffer> {
        val resources = getMeshResources().flatMap { it.collectByTypeRecursive(SkeletonMeshResource::class) }
        val occluded = resources.map { it.occlusionType }.toSet()
        return resources.flatMap { it.meshes }.filter { !isOccluded(it, occluded) }
    }

    private fun getMeshResources(): List<DirectoryResource> {
        return model.getMeshResources(excludeSlots = getHiddenSlots())
    }

    fun lockAnimation(duration: Float) {
        animationLockDuration = duration
    }

    fun isAnimationLocked(): Boolean {
        return animationLockDuration > 0f
    }

    fun lockMovement(duration: Float) {
        movementLockDuration = duration
    }

    fun isMovementLocked() : Boolean {
        return movementLockDuration > 0f
    }

    fun clearAnimations() {
        skeletonAnimationCoordinator.animations.clear()
        animationLockDuration = 0f
        movementLockDuration = 0f
    }

    fun getSkeleton(): SkeletonInstance? {
        return skeletonInstance
    }

    fun setSkeletonAnimation(datId: DatId, animationDirs: List<DirectoryResource>, loopParams: LoopParams = LoopParams.lowPriorityLoop(), transitionParams: TransitionParams) {
        val resources = fetchAnimations(datId, animationDirs)

        if (transitionParams.inBetween != null) {
            transitionParams.resolvedInBetween = fetchAnimations(transitionParams.inBetween, animationDirs).associateBy { it.id.finalDigit() ?: 0 }
        }

        skeletonAnimationCoordinator.registerAnimation(resources, loopParams, transitionParams = transitionParams, parameterized = datId.isParameterized())
    }

    fun transitionToMoving(datId: DatId, animationDirs: List<DirectoryResource>, transitionParams: TransitionParams) {
        hiddenUntilIdle.clear()
        setSkeletonAnimation(datId, animationDirs, LoopParams.lowPriorityLoop(), transitionParams)
    }

    fun transitionToIdleOnStopMoving(datId: DatId, animationDirs: List<DirectoryResource>) {
        val idleResources = fetchAnimations(datId, animationDirs)
        skeletonAnimationCoordinator.registerIdleAnimation(idleResources, requireTransitionOut = false)
    }

    fun transitionToIdleOnCompleted(idleId: DatId, animationDirs: List<DirectoryResource>) {
        if (!skeletonAnimationCoordinator.hasCompleteTransitionOutAnimations()) { return }
        hiddenUntilIdle.clear()

        val idleResources = fetchAnimations(idleId, animationDirs)
        skeletonAnimationCoordinator.registerIdleAnimation(idleResources, requireTransitionOut = true)
    }

    fun forceTransitionToIdle(idleId: DatId, transitionTime: Float, animationDirs: List<DirectoryResource>) {
        val idleResources = fetchAnimations(idleId, animationDirs)
        skeletonAnimationCoordinator.registerAnimation(idleResources,
            LoopParams.lowPriorityLoop(),
            TransitionParams(transitionInTime = transitionTime, transitionOutTime = 0f)
        )
    }

    private fun fetchAnimations(datId: DatId, animationDirs: List<DirectoryResource>): List<SkeletonAnimationResource> {
        return animationDirs.map { it.root() }
            .flatMap { it.collectByTypeRecursive(SkeletonAnimationResource::class) }
            .filter { it.id.parameterizedMatch(datId) }
            .distinctBy { it.id }
    }

    fun getJointPosition(standardPosition: Int): Vector3f? {
        val skeleton = getSkeleton() ?: return null
        return skeleton.getStandardJointPosition(standardPosition)
    }

    fun getFootInfoDefinition() : InfoDefinition? {
        return model.getMovementInfo()
    }

    fun getCollidingFoot(): StandardPosition? {
        return if (leftFootState == FootState.Landing) {
            StandardPosition.LeftFoot
        } else if (rightFootState == FootState.Landing) {
            StandardPosition.RightFoot
        } else {
            null
        }
    }

    fun displayRanged(duration: Float) {
        displayRangedDuration = duration
    }

    fun toggleSlotVisibilityUntilIdle(hidden: Boolean, slot: ItemModelSlot) {
        if (hidden) {
            hiddenUntilIdle += slot
        } else {
            hiddenUntilIdle -= slot
        }
    }

    fun getBlurConfig(): BlurConfig? {
        return model.getBlurConfig()
    }

    private fun isOccluded(meshBuffer: MeshBuffer, occlusion: Set<Int>): Boolean {
        val renderProperties = meshBuffer.skeletalMeshProperties ?: return false

        // TODO - do occlusions 0x01, 0x11, 0x21, and 0x31 do anything?
        return when (renderProperties.displayTypeFlag) {
            0 -> false
            /* hair 1*/ 1 -> occlusion.contains(0x02) || occlusion.contains(0x03) || occlusion.contains(0x04) || occlusion.contains(0x05) || occlusion.contains(0x06)
            /* hair 2*/ 2 -> occlusion.contains(0x04) || occlusion.contains(0x05) || occlusion.contains(0x06)
            /* hair 3*/ 3 -> occlusion.contains(0x04) || occlusion.contains(0x05) || occlusion.contains(0x06)
            /* face  */ 4 -> occlusion.contains(0x05)
            /* wrist */ 5 -> occlusion.contains(0x12)
            /* pants */ 6 -> occlusion.contains(0x32)
            /* shins */ 7 -> occlusion.contains(0x22)
            else -> throw IllegalStateException("Unknown display type: ${renderProperties.displayTypeFlag}")
        }

    }

}
