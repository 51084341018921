package xim.poc.game

import xim.poc.Actor
import xim.poc.ActorId
import xim.poc.ActorManager

class Party(leader: Actor) {

    private val members = mutableListOf(leader.id)

    fun addMember(actorId: ActorId) {
        members += actorId
    }

    fun removeMember(actorId: ActorId) {
        members.remove(actorId)
    }

    fun getIndex(actorId: ActorId): Int? {
        val index = members.indexOf(actorId)
        return if (index < 0) { null } else { index }
    }

    fun getByIndex(index: Int): Actor? {
        return ActorManager[members.getOrNull(index)]
    }

    fun contains(actor: Actor): Boolean {
        return getIndex(actor.id) != null
    }

    fun getAll(): List<Actor> {
        return members.mapNotNull { ActorManager[it] }
    }

    fun size(): Int {
        return members.size
    }

}

object PartyManager {

    private val playerParty by lazy { Party(ActorManager.player()) }

    operator fun get(actor: Actor): Party {
        val index = playerParty.getIndex(actor.id)
        return if (index == null) { Party(actor) } else { playerParty }
    }

    fun getOrCreate(leader: Actor): Party {
        if (leader != ActorManager.player()) { throw IllegalStateException("Making multiple parties???") }
        return playerParty
    }

}