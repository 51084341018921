package xim.poc

import xim.poc.browser.DatWrapper
import xim.resource.DatId
import xim.resource.DatLink
import xim.resource.InfoResource
import xim.resource.MountDefinition

class Mount(val index: Int, val resource: DatWrapper, val id: ActorId, val owner: ActorId) {

    private val info = DatLink<InfoResource>(DatId.mount)

    fun getInfo(): MountDefinition? {
        if (!resource.isReady()) { return null }
        val mountDef = info.getOrPut { resource.getAsResource().getNullableChildRecursivelyAs(it, InfoResource::class) }
        return mountDef?.mountDefinition
    }

    fun getRiderRotation(): Float {
        return getInfo()?.rotation ?: 0f
    }

}